/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_GarageSKBoxes1.glb -t -r public 
*/

import { useGLTF, useTexture } from "@react-three/drei";
import { useRef } from "react";
import * as THREE from "three";
import { GLTF } from "three-stdlib";
import useSegment from "@/components/hooks/useSegment";

type GLTFResult = GLTF & {
  nodes: {
    Lite_Duty_Box_: THREE.Mesh;
    Lite_Duty_Box_Lid_1: THREE.Mesh;
    Lite_Duty_Box_Lid_2: THREE.Mesh;
    Extra_Large_Heavy_Duty_Box: THREE.Mesh;
    Extra_Large_Heavy_Duty_Lid_1: THREE.Mesh;
    Extra_Large_Heavy_Duty_Lid_2: THREE.Mesh;
    Medium_Heavy_Duty_Box001: THREE.Mesh;
    Medium_Heavy_Duty_Lid_1001: THREE.Mesh;
    Medium_Heavy_Duty_Lid_2001: THREE.Mesh;
  };
  materials: object;
};

// type ActionName =
//   | "Lite Duty Box Lid 1Action"
//   | "Lite Duty Box Lid 2Action"
//   | "Extra Large Heavy Duty Lid 1Action"
//   | "Extra Large Heavy Duty Lid 2Action"
//   | "Medium Heavy Duty Lid 1.001Action"
//   | "Medium Heavy Duty Lid 2.001Action";
// type GLTFActions = Record<ActionName, THREE.AnimationAction>;

// not being used?
// type ContextType = Record<
//   string,
//   React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
// >;

export function WTFN25_SPIN5_GarageSKBoxes1F(
  props: JSX.IntrinsicElements["group"]
) {
  const group = useRef<THREE.Group>();
  // const { nodes, materials, animations } = useGLTF(
  const { nodes } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_GarageSKBoxes1.glb"
  ) as GLTFResult;
  // const { actions } = useAnimations<GLTFActions>(animations, group);

  const GarageSKBoxes1D = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_GarageSKBoxes1D.avif"
  );
  GarageSKBoxes1D.flipY = false;
  GarageSKBoxes1D.colorSpace = THREE.SRGBColorSpace;
  GarageSKBoxes1D.channel = 1;
  const GarageSKBoxes1Mat = new THREE.MeshBasicMaterial({
    map: GarageSKBoxes1D,
  });

  // useSegment("garage-clutter", actions);

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="Lite_Duty_Box_"
          geometry={nodes.Lite_Duty_Box_.geometry}
          material={GarageSKBoxes1Mat}
          position={[-11.099, 0, 4.344]}
          rotation={[0, 0.173, 0]}
        />
        <mesh
          name="Lite_Duty_Box_Lid_1"
          geometry={nodes.Lite_Duty_Box_Lid_1.geometry}
          material={GarageSKBoxes1Mat}
          position={[-10.905, 0.586, 4.15]}
          rotation={[-1.468, 0.018, 0.172]}
        />
        <mesh
          name="Lite_Duty_Box_Lid_2"
          geometry={nodes.Lite_Duty_Box_Lid_2.geometry}
          material={GarageSKBoxes1Mat}
          position={[-10.851, 0.586, 4.461]}
          rotation={[2.209, -0.104, -0.138]}
        />
        <mesh
          name="Extra_Large_Heavy_Duty_Box"
          geometry={nodes.Extra_Large_Heavy_Duty_Box.geometry}
          material={GarageSKBoxes1Mat}
          position={[-9.947, 0, 2.724]}
          rotation={[0, -1.003, 0]}
        />
        <mesh
          name="Extra_Large_Heavy_Duty_Lid_1"
          geometry={nodes.Extra_Large_Heavy_Duty_Lid_1.geometry}
          material={GarageSKBoxes1Mat}
          position={[-10.004, 0.616, 3.021]}
          rotation={[2.109, 0.677, 0.809]}
        />
        <mesh
          name="Extra_Large_Heavy_Duty_Lid_2"
          geometry={nodes.Extra_Large_Heavy_Duty_Lid_2.geometry}
          material={GarageSKBoxes1Mat}
          position={[-9.653, 0.616, 2.798]}
          rotation={[-2.208, 0.75, -0.744]}
        />
        <mesh
          name="Medium_Heavy_Duty_Box001"
          geometry={nodes.Medium_Heavy_Duty_Box001.geometry}
          material={GarageSKBoxes1Mat}
          position={[-10.352, 0, 3.031]}
          rotation={[0, -0.422, 0]}
        />
        <mesh
          name="Medium_Heavy_Duty_Lid_1001"
          geometry={nodes.Medium_Heavy_Duty_Lid_1001.geometry}
          material={GarageSKBoxes1Mat}
          position={[-10.227, 0.35, 3.257]}
          rotation={[2.398, 0.319, 0.281]}
        />
        <mesh
          name="Medium_Heavy_Duty_Lid_2001"
          geometry={nodes.Medium_Heavy_Duty_Lid_2001.geometry}
          material={GarageSKBoxes1Mat}
          position={[-10.1, 0.35, 2.973]}
          rotation={[-1.859, 0.127, -0.403]}
        />
      </group>
    </group>
  );
}
export const WTFN25_SPIN5_GarageSKBoxes1 = () => {
  return <WTFN25_SPIN5_GarageSKBoxes1F></WTFN25_SPIN5_GarageSKBoxes1F>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_GarageSKBoxes1.glb");
