/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_LoungeGlass.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Plane004: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_LoungeGlassF(
  props: JSX.IntrinsicElements["group"]
) {
  const { nodes } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_LoungeGlass.glb"
  ) as GLTFResult;

  const GlassMat = new THREE.MeshStandardMaterial({
    color: "#2B4542",
    roughness: 0,
    transparent: true,
    opacity: 0.3,
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Plane004.geometry}
        material={GlassMat}
        position={[-8.409, 0.45, 1.521]}
        rotation={[0, -Math.PI / 2, 0]}
      />
    </group>
  );
}
export const WTFN25_SPIN5_LoungeGlass = () => {
  return <WTFN25_SPIN5_LoungeGlassF></WTFN25_SPIN5_LoungeGlassF>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_LoungeGlass.glb");
