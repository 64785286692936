import Spin5ScenesManifest from "@/components/sets/spin5/manifest";
/**
 * temporary - this generates a list of all models that are not used in Spin5ScenesManifest
 */

const mapped: string[] = [
  "WTFN25_SPIN5_Ceiling",
  "WTFN25_SPIN5_HallWalls",
  "WTFN25_SPIN5_HamperWorld",
  "WTFN25_SPIN5_KmartDahliaL",
  "WTFN25_SPIN5_KmartLinearVase",
  "WTFN25_SPIN5_KmartMarleTassel",
  "WTFN25_SPIN5_KitchenCupboards",
  "WTFN25_SPIN5_KitchenIsland",
  "WTFN25_SPIN5_KitchenLights",
  "WTFN25_SPIN5_DaikinHub",
  "WTFN25_SPIN5_Dining",
  "WTFN25_SPIN5_DownLights",
  "WTFN25_SPIN5_FloorWood",
  "WTFN25_SPIN5_Lounge",
  "WTFN25_SPIN5_LoungeTrim",
  "WTFN25_SPIN5_LoungeWallFeature",
  "WTFN25_SPIN5_LoungeWallsChange",
  "WTFN25_SPIN5_Paint",
  "WTFN25_SPIN5_PaintRoller",
  "WTFN25_SPIN5_Carpet",
  "WTFN25_SPIN5_MasterDesk",
  "WTFN25_SPIN5_MasterDoors",
  "WTFN25_SPIN5_MasterWalls",
  "WTFN25_SPIN5_KmartBoston",
  "WTFN25_SPIN5_DaikinReturn",
  "WTFN25_SPIN5_GarageDoor",
  "WTFN25_SPIN5_MasterDecor",
  "WTFN25_SPIN5_BedShed1",
  "WTFN25_SPIN5_DaikinAlira",
  "WTFN25_SPIN5_KmartMushroom",
  "WTFN25_SPIN5_SpareWalls",
  "WTFN25_SPIN5_FESampleDorian",
  "WTFN25_SPIN5_FESampleGum",
  "WTFN25_SPIN5_FESampleOak",
  "WTFN25_SPIN5_CarpetFECorinSwatch",
  "WTFN25_SPIN5_CarpetFEOberonSwatch",
  "WTFN25_SPIN5_DaikinPhone",
  "WTFN25_SPIN5_SpareBedroomDecor",
  "WTFN25_SPIN5_SpareDoors",
  "WTFN25_SPIN5_KmartCanvas",
  "WTFN25_SPIN5_KmartDahliaSB",
  "WTFN25_SPIN5_KmartMirror",
  "WTFN25_SPIN5_Garden",
  "WTFN25_SPIN5_Garden2",
  "WTFN25_SPIN5_GardenPlants1",
  "WTFN25_SPIN5_Driveway",
  "WTFN25_SPIN5_FrontYard",
  "WTFN25_SPIN5_Skysphere",
  "WTFN25_SPIN5_VanBase",
  "WTFN25_SPIN5_VanInside",
  "WTFN25_SPIN5_VideoPlane",
  "WTFN25_SPIN5_GardenBackPlane",
  "WTFN25_SPIN5_GardenSetting",
  "WTFN25_SPIN5_LoungeGlass",
];

const usedModels = new Set(
  Spin5ScenesManifest.flatMap((scene) => [
    ...(scene.objects?.map((obj) => obj.name) || []),
    ...(scene.setObjects?.map((obj) => obj.name) || []),
    ...(scene.virtualObjects?.map((obj) => obj.name) || []),
    ...(scene.segments
      ? scene.segments.flatMap(
          (segment) => segment.objects?.map((obj) => obj.name) || []
        )
      : []),
  ])
);

const uniqueMapped = () => {
  const uniques = new Set<string>();
  mapped.forEach((modelName) => {
    uniques.add(modelName);
  });
  return uniques;
};

export const Spin5Set: string[] =
  [...uniqueMapped()].filter((modelName) => {
    // Return only models that are NOT used in Spin5ScenesManifest
    return !usedModels.has(modelName);
  }) || [];
