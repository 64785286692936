import { useAuth } from "@/context/auth.context";
import { useExperienceContext } from "@/context/experience.context";
import { Suspense, lazy } from "react";
import SpinnerCentered from "../SpinnerCentered";
import { SetComponentDictionary } from "./set-dictionary";

interface ContainerProps {
  // userViewsProduct(experienceProduct: ExperienceProduct): Promise<void>;
  // productModalIsOpen: boolean;
  // userOpensMenu(tabName?: "saved" | "products" | undefined): void;
}

/**
 * SetLoader component lazy loads the set component based on the set component name.
 * @returns
 */
const SetLoader: React.FC<ContainerProps> = (
  {
    // userViewsProduct,
    // productModalIsOpen,
    // userOpensMenu,
  }
) => {
  // Context API
  const { set } = useExperienceContext();

  // Set the set component name to lazy load the proper component.
  if (!set || !set.setComponentName)
    return <div>SET IS MISSING A SET COMPONENT NAME.</div>;

  const SetComponent = SetComponentDictionary[set.setComponentName];

  if (!SetComponent)
    return <div>SPECIFIED SET COMPONENTENT DOES NOT EXIST.</div>;

  return (
    <Suspense fallback={<SpinnerCentered />}>
      <SetComponent
      // userViewsProduct={userViewsProduct}
      // productModalIsOpen={productModalIsOpen}
      // userOpensMenu={userOpensMenu}
      // authUser={authUser}
      />
    </Suspense>
  );
};

export default SetLoader;
