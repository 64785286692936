/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_DaikinHub.glb -t -r public 
*/

import * as THREE from "three";

import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    daikinairhub: THREE.Mesh;
  };
  materials: {};
};

export function WTFN25_SPIN5_DaikinHubF(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_DaikinHub.glb"
  ) as GLTFResult;

  const DaikinHubD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_DaikinHubD.avif"
  );
  DaikinHubD.flipY = false;
  DaikinHubD.colorSpace = THREE.SRGBColorSpace;

  const DaikinHubMat = new THREE.MeshBasicMaterial({
    map: DaikinHubD,
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.daikinairhub.geometry}
        material={DaikinHubMat}
        position={[-0.592, 1.3, -0.41]}
      />
    </group>
  );
}

export const WTFN25_SPIN5_DaikinHub = () => {
  return <WTFN25_SPIN5_DaikinHubF></WTFN25_SPIN5_DaikinHubF>;
};

useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_DaikinHub.glb");
