/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_KmartShelf.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    HeavyDutyShelf: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_KmartShelfF(
  props: JSX.IntrinsicElements["group"]
) {
  const { nodes, materials } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_KmartShelf.glb"
  ) as GLTFResult;

  const KmartShelfD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_KmartShelfD.avif"
  );
  KmartShelfD.flipY = false;
  KmartShelfD.colorSpace = THREE.SRGBColorSpace;
  KmartShelfD.channel = 2;
  const KmartShelfMat = new THREE.MeshBasicMaterial({
    map: KmartShelfD,
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.HeavyDutyShelf.geometry}
        material={KmartShelfMat}
        position={[-8.232, 0, 5.008]}
        rotation={[0, -1.571, 0]}
      />
    </group>
  );
}
export const WTFN25_SPIN5_KmartShelf = () => {
  return <WTFN25_SPIN5_KmartShelfF></WTFN25_SPIN5_KmartShelfF>;
};

useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_KmartShelf.glb");
