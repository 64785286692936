/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_SKBoxesGaragePersist.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Archive_Carton_Box002: THREE.Mesh;
    Extra_Large_Heavy_Duty_Box004: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_SKBoxesGaragePersistF(
  props: JSX.IntrinsicElements["group"]
) {
  const { nodes, materials } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_SKBoxesGaragePersist.glb"
  ) as GLTFResult;

  const SKBoxesGaragePersistD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_SKBoxesGaragePersistD.avif"
  );
  SKBoxesGaragePersistD.flipY = false;
  SKBoxesGaragePersistD.colorSpace = THREE.SRGBColorSpace;
  SKBoxesGaragePersistD.channel = 1;
  const SKBoxesGaragePersistMat = new THREE.MeshBasicMaterial({
    map: SKBoxesGaragePersistD,
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Archive_Carton_Box002.geometry}
        material={SKBoxesGaragePersistMat}
        position={[-10.383, 0, 0.511]}
        rotation={[-Math.PI, 1.537, -Math.PI]}
      />
      <mesh
        geometry={nodes.Extra_Large_Heavy_Duty_Box004.geometry}
        material={SKBoxesGaragePersistMat}
        position={[-13.017, 0, 5.102]}
        rotation={[-Math.PI, 0.152, -Math.PI]}
      />
    </group>
  );
}
export const WTFN25_SPIN5_SKBoxesGaragePersist = () => {
  return (
    <WTFN25_SPIN5_SKBoxesGaragePersistF></WTFN25_SPIN5_SKBoxesGaragePersistF>
  );
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_SKBoxesGaragePersist.glb");
