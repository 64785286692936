import useDevMode from "@/components/hooks/useDevMode";
import useStickyLeva from "@/components/hooks/useStickyLeva";
import { useExperienceContext } from "@/context/experience.context";
import useDevModeStore from "@/stores/useDevModeStore";
import useScenesStore from "@/stores/useScenesStore";
import { CameraControls, StatsGl } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { Leva, useControls } from "leva";
import { useEffect, useMemo, useRef } from "react";

interface DevCameras {
  Main: React.RefObject<THREE.PerspectiveCamera>;
  Explore: React.RefObject<THREE.PerspectiveCamera>;
  Birdseye: React.RefObject<THREE.OrthographicCamera>;
}

export function DevOnly({ children }: { children?: React.ReactNode }) {
  const { isDev } = useDevMode();
  if (!isDev) return null;
  return <>{children}</>;
}

export default function DevViewerControls() {
  const { isDev } = useDevMode();

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        right: 0,
        width: "300px",
        height: "200px",
        display: isDev ? "block" : "none",
      }}
    >
      <Leva fill collapsed={false} hidden={!isDev} />
    </div>
  );
}

export function DevCanvasElements() {
  const controlsRef = useRef<CameraControls>(null);
  const { isDev } = useDevMode();

  const {
    // setIsMenuDrawerOpen,
    // handleExperienceVirtualObjectTap,
    // currentScene,
    setCurrentScene,
    // experienceVirtualObjects,
    // realExperienceVirtualObjects,
    // experience,
    // set,
    // canViewExperience,
    // isMobile,
  } = useExperienceContext();
  // const scenes = ScenesDictionary[set.setComponentName];
  const { set: r3fSet, camera } = useThree();
  const sceneContexts = useScenesStore.use.scenes();
  const goToSceneId = useScenesStore.use.goToSceneId();
  const currentScene = useScenesStore.use.currentScene();
  const mainCameraRef = useDevModeStore.use.mainCameraRef();
  const setRef = useDevModeStore.use.setRef();
  const exploreCameraRef = useRef<
    THREE.PerspectiveCamera | THREE.OrthographicCamera | null
  >(null);
  const birdseyeCameraRef = useRef<THREE.Camera | null>(null);

  const cameras = useMemo<DevCameras>(() => {
    return {
      Main: mainCameraRef,
      Explore: exploreCameraRef,
      Birdseye: birdseyeCameraRef,
    } as DevCameras;
  }, [mainCameraRef]);

  const [stickyScene, setStickyScene] = useStickyLeva(
    "scene",
    sceneContexts[0].scene.sceneName
  );

  const [controls] = useControls(() => ({
    currentScene: {
      value: stickyScene || sceneContexts[0].scene.sceneName,
      options: sceneContexts.map(
        (sceneContext) => sceneContext.scene.sceneName
      ),
    },
    currentCamera: {
      value: "Main",
      options: ["Main", "Explore", "Birdseye"],
      render: () => false,
    },
    cameraTransitions: {
      value: false,
    },
    useStickyScene: {
      value: false,
    },
    useWasd: {
      value: true,
      render: () => false,
    },
  }));

  // change scene
  useEffect(() => {
    // set the current scene
    const newScene = sceneContexts.find(
      (sceneContext) => sceneContext.scene.sceneName === controls.currentScene
    ).scene;

    if (controls.useStickyScene) {
      setStickyScene(newScene.sceneName);
    } else {
      setStickyScene("");
    }
    goToSceneId(newScene.id);
    setCurrentScene(newScene);

    if (controls.currentCamera !== "Main") return;

    void controlsRef.current.setPosition(
      newScene.xCameraPosition,
      newScene.yCameraPosition,
      newScene.zCameraPosition,
      controls.cameraTransitions
    );

    void controlsRef.current.setTarget(
      newScene.xTargetPosition,
      newScene.yTargetPosition,
      newScene.zTargetPosition,
      controls.cameraTransitions
    );

    // void controlsRef.current.setOrbitPoint(
    //   newScene.xTargetPosition,
    //   newScene.yTargetPosition,
    //   newScene.zTargetPosition
    // );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    controls.currentScene,
    controls.currentCamera,
    currentScene,
    controls.cameraTransitions,
  ]);

  // change camera
  useEffect(() => {
    const cameraRef = cameras[controls.currentCamera as keyof DevCameras];

    if (!cameraRef?.current) return;
    if (!controlsRef?.current) return;
    if (!setRef?.current) return;

    // r3fSet({ camera: cameraRef.current });

    // void controlsRef.current.fitToBox(setRef.current, true);

    switch (controls.currentCamera) {
      case "Birdseye":
        // void birdseyeCameraRef.current.lookAt(0, 0, 0);
        void controlsRef.current.setTarget(0, 0, 0, controls.cameraTransitions);
        void controlsRef.current.moveTo(0, 1, 0, controls.cameraTransitions);
        // void controlsRef.current.
        break;
      case "Explore":
        void controlsRef.current.fitToBox(
          setRef.current,
          controls.cameraTransitions
        );
        break;
      default:
        break;
    }
  }, [
    cameras,
    controls.cameraTransitions,
    controls.currentCamera,
    r3fSet,
    setRef,
  ]);

  return (
    isDev && (
      <>
        <CameraControls
          ref={controlsRef}
          camera={camera}
          // enabled={controls.currentCamera === "Explore"}
        />
        {/* <PerspectiveCamera
          ref={exploreCameraRef as React.RefObject<THREE.PerspectiveCamera>}
        />
        <OrthographicCamera
          ref={birdseyeCameraRef as React.RefObject<THREE.OrthographicCamera>}
          position={[0, 1, 0]}
          zoom={80}
        /> */}
        <StatsGl className="stats fixed" />
      </>
    )
  );
}
