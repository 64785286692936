/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_SKBoxEzi.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Large_Ezi_Carry_Box_001: THREE.Mesh;
    Large_Ezi_Carry_Box_002: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_SKBoxEziF(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_SKBoxEzi.glb"
  ) as GLTFResult;

  const SKBoxEziD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_SKBoxEziD.avif"
  );
  SKBoxEziD.flipY = false;
  SKBoxEziD.colorSpace = THREE.SRGBColorSpace;
  SKBoxEziD.channel = 1;
  const SKBoxEziMat = new THREE.MeshBasicMaterial({
    map: SKBoxEziD,
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Large_Ezi_Carry_Box_001.geometry}
        material={SKBoxEziMat}
        position={[-4.699, 0, 6.252]}
      />
      <mesh
        geometry={nodes.Large_Ezi_Carry_Box_002.geometry}
        material={SKBoxEziMat}
        position={[-4.2, 0.025, 5.932]}
        rotation={[0, -0.371, 0]}
      />
    </group>
  );
}
export const WTFN25_SPIN5_SKBoxEzi = () => {
  return <WTFN25_SPIN5_SKBoxEziF></WTFN25_SPIN5_SKBoxEziF>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_SKBoxEzi.glb");
