import Scene from "@/components/3d/Scene";
import useStoreBridge from "@/components/hooks/useStoreBridge";
import useDevModeStore from "@/stores/useDevModeStore";
import useScenesStore from "@/stores/useScenesStore";

export default function ScenesManager() {
  useStoreBridge();
  const setRef = useDevModeStore.use.setRef();
  const scenes = useScenesStore.use.scenes();

  return (
    <group ref={setRef}>
      {scenes.map((sc) => (
        <Scene key={sc.scene.id} {...sc} />
      ))}
    </group>
  );
}
