/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_GarageDoor.glb -t -r public 
*/

import * as THREE from "three";

import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Cube096: THREE.Mesh;
    Cube096_1: THREE.Mesh;
    Cube096_2: THREE.Mesh;
  };
  materials: {};
};

export function WTFN25_SPIN5_GarageDoorF(
  props: JSX.IntrinsicElements["group"]
) {
  const { nodes, materials } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_GarageDoor.glb"
  ) as GLTFResult;

  const GarageDoorD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_GarageDoorD.avif"
  );
  GarageDoorD.flipY = false;
  GarageDoorD.colorSpace = THREE.SRGBColorSpace;

  const GarageDoorMat = new THREE.MeshBasicMaterial({
    map: GarageDoorD,
  });

  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Cube096.geometry} material={GarageDoorMat} />
      <mesh geometry={nodes.Cube096_1.geometry} material={GarageDoorMat} />
      <mesh geometry={nodes.Cube096_2.geometry} material={GarageDoorMat} />
    </group>
  );
}
export const WTFN25_SPIN5_GarageDoor = () => {
  return <WTFN25_SPIN5_GarageDoorF></WTFN25_SPIN5_GarageDoorF>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_GarageDoor.glb");
