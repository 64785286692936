/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_StanleyBlower.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Logo: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_StanleyBlowerF(
  props: JSX.IntrinsicElements["group"]
) {
  const { nodes } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_StanleyBlower.glb"
  ) as GLTFResult;

  const StanleyBlowerD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_StanleyBlowerD.avif"
  );
  StanleyBlowerD.flipY = false;
  StanleyBlowerD.colorSpace = THREE.SRGBColorSpace;
  StanleyBlowerD.channel = 1;
  const StanleyBlowerMat = new THREE.MeshBasicMaterial({
    map: StanleyBlowerD,
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Logo.geometry}
        material={StanleyBlowerMat}
        position={[-8.489, 0.947, 2.394]}
        rotation={[Math.PI, -0.717, Math.PI]}
      />
    </group>
  );
}
export const WTFN25_SPIN5_StanleyBlower = () => {
  return <WTFN25_SPIN5_StanleyBlowerF></WTFN25_SPIN5_StanleyBlowerF>;
};

useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_StanleyBlower.glb");
