/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_LoungeWallsChange.glb -t -r public 
*/

import * as THREE from "three";

import useInteractionsStore from "@/stores/useInteractionsStore";
import { useGLTF, useTexture } from "@react-three/drei";
import gsap from "gsap";
import { useControls } from "leva";
import { useEffect, useMemo } from "react";
import { GLTF } from "three-stdlib";
import fragmentShader from "./glsl/fragment.glsl";
import vertexShader from "./glsl/vertex.glsl";

type GLTFResult = GLTF & {
  nodes: {
    Cube023: THREE.Mesh;
  };
  materials: object;
};

export const FEATURE_KEY = "walls";

export function WTFN25_SPIN5_LoungeWallFeature(props: THREE.Group) {
  const events = useInteractionsStore.use.events();

  const { nodes } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_LoungeWallFeature.glb"
  ) as GLTFResult;

  const startTexture = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_FeatureWallBeforeD.avif"
  );

  startTexture.flipY = false;
  startTexture.channel = 1;

  const crispTexture = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_FeatureWallCrispD.avif"
  );

  crispTexture.flipY = false;

  const cloverTexture = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_FeatureWallCloverD.avif"
  );

  cloverTexture.flipY = false;

  const ariaTexture = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_FeatureWallAriaD.avif"
  );

  ariaTexture.flipY = false;

  const wallMaterial = useMemo(() => {
    return new THREE.ShaderMaterial({
      uniforms: {
        uGamma: { value: 2 },
        uStartPower: { value: 1 },
        uCrispPower: { value: 0 },
        uCloverPower: { value: 0 },
        uAriaPower: { value: 0 },
        uStartTexture: { value: startTexture },
        uCrispTexture: { value: crispTexture },
        uCloverTexture: { value: cloverTexture },
        uAriaTexture: { value: ariaTexture },
      },
      vertexShader,
      fragmentShader,
      transparent: true,
      opacity: 1,
    });
  }, [startTexture, crispTexture, cloverTexture, ariaTexture]);

  wallMaterial;

  const controls = useControls({
    wall: {
      value: "start",
      options: ["start", "crisp", "aria", "clover"],
    },
  });

  useEffect(() => {
    const duration = 1;
    const wall = controls.wall;
    gsap.to(wallMaterial.uniforms.uStartPower, {
      value: wall === "start" ? 1 : 0,
      duration,
    });
    gsap.to(wallMaterial.uniforms.uCrispPower, {
      value: wall === "crisp" ? 1 : 0,
      duration,
    });
    gsap.to(wallMaterial.uniforms.uAriaPower, {
      value: wall === "aria" ? 1 : 0,
      duration,
    });
    gsap.to(wallMaterial.uniforms.uCloverPower, {
      value: wall === "clover" ? 1 : 0,
      duration,
    });
  }, [
    controls.wall,
    events,
    wallMaterial.uniforms.uAriaPower,
    wallMaterial.uniforms.uCloverPower,
    wallMaterial.uniforms.uCrispPower,
    wallMaterial.uniforms.uStartPower,
  ]);

  useEffect(() => {
    const event = events.get(FEATURE_KEY);
    if (event?.value) {
      const duration = 1;
      const wall = event?.value;
      gsap.to(wallMaterial.uniforms.uStartPower, {
        value: wall === "start" ? 1 : 0,
        duration,
      });
      gsap.to(wallMaterial.uniforms.uCrispPower, {
        value: wall === "crisp" ? 1 : 0,
        duration,
      });
      gsap.to(wallMaterial.uniforms.uAriaPower, {
        value: wall === "aria" ? 1 : 0,
        duration,
      });
      gsap.to(wallMaterial.uniforms.uCloverPower, {
        value: wall === "clover" ? 1 : 0,
        duration,
      });
    }
  }, [
    events,
    wallMaterial.uniforms.uAriaPower,
    wallMaterial.uniforms.uCloverPower,
    wallMaterial.uniforms.uCrispPower,
    wallMaterial.uniforms.uStartPower,
  ]);

  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Cube023.geometry}>
        <primitive object={wallMaterial} />
      </mesh>
    </group>
  );
}

useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_LoungeWallFeature.glb");

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_LoungeWallFeature.glb -t -r public 
*/

// import * as THREE from "three";

// import { useGLTF, useTexture } from "@react-three/drei";
// import { GLTF } from "three-stdlib";

// type GLTFResult = GLTF & {
//   nodes: {
//     Cube023: THREE.Mesh;
//   };
//   materials: {};
// };

// type ContextType = Record<
//   string,
//   React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
// >;

// export function WTFN25_SPIN5_LoungeWallFeatureF(
//   props: JSX.IntrinsicElements["group"]
// ) {
//   const { nodes } = useGLTF(
//     "/glb/WTFN25/SPIN5/WTFN25_SPIN5_LoungeWallFeature.glb"
//   ) as GLTFResult;

//   const FeatureWallBeforeD = useTexture(
//     "/textures/WTFN25/SPIN5/WTFN25_SPIN5_FeatureWallBeforeD.avif"
//   );
//   FeatureWallBeforeD.flipY = false;
//   FeatureWallBeforeD.colorSpace = THREE.SRGBColorSpace;
//   FeatureWallBeforeD.channel = 1;

//   const FeatureWallBeforeMat = new THREE.MeshBasicMaterial({
//     map: FeatureWallBeforeD,
//   });

//   return (
//     <group {...props} dispose={null}>
//       <mesh geometry={nodes.Cube023.geometry} material={FeatureWallBeforeMat} />
//     </group>
//   );
// }

// export const WTFN25_SPIN5_LoungeWallFeature = () => {
//   return <WTFN25_SPIN5_LoungeWallFeatureF></WTFN25_SPIN5_LoungeWallFeatureF>;
// };

// useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_LoungeWallFeature.glb");
