/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_Garage.glb -t -r public 
*/

import { useGLTF, useTexture } from "@react-three/drei";
import React from "react";
import * as THREE from "three";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Plane028: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_GarageAfterF(
  props: JSX.IntrinsicElements["group"]
) {
  const { nodes, materials } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_Garage.glb"
  ) as GLTFResult;

  const GarageAfterD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_GarageAfterD.avif"
  );
  GarageAfterD.flipY = false;
  GarageAfterD.colorSpace = THREE.SRGBColorSpace;
  GarageAfterD.channel = 2;
  const GarageAfterMat = new THREE.MeshBasicMaterial({
    map: GarageAfterD,
  });

  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Plane028.geometry} material={GarageAfterMat} />
    </group>
  );
}
export const WTFN25_SPIN5_GarageAfter = () => {
  return <WTFN25_SPIN5_GarageAfterF></WTFN25_SPIN5_GarageAfterF>;
};

useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_Garage.glb");
