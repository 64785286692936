// uniform sampler2D uTexture;
uniform sampler2D uOutlinesTexture;
uniform sampler2D uObjectsTexture;
uniform float uGlowAmount;
uniform float uGlowSoftness;
uniform vec3 uGlowColor;
varying vec2 vUv;

void main() {
  vec2 texelSize = vec2(uGlowAmount);
  
  // color of non-outlined objects
  vec4 objectTextureColor = texture2D(uObjectsTexture, vUv); // Sample the original texture
  
  // color of outlined objects
  vec4 outlineTextureColor = texture2D(uOutlinesTexture, vUv); // Sample the original texture
  
  // glow color
  vec4 glowColor = vec4(uGlowColor, 1.0);
  vec4 outlineResultColor = vec4(outlineTextureColor);

  float totalWeight = 0.0;
  float glowStrength = 2.0;

  // Sample in a 5x5 grid for the glow
  for (int x = -5; x <= 5; x++) {
    for (int y = -5; y <= 5; y++) {
      vec2 offset = vec2(float(x), float(y)) * texelSize;
      vec4 ssample = texture2D(uOutlinesTexture, vUv + offset);
      float weight = exp(-float(x * x + y * y) / (2.0 * glowStrength * glowStrength));
      glowColor += ssample * weight;
      totalWeight += weight;
    }
  }

  // Normalize the color and apply the glow
  glowColor.a /= totalWeight;
  glowColor.a = smoothstep(0.1, uGlowSoftness, glowColor.a); // Soft alpha blending

  outlineResultColor = glowColor;
  if (outlineTextureColor.a > 0.1) {
    outlineResultColor = outlineTextureColor;
  }

  // gl_FragColor = objectTextureColor + outlineResultColor;
  // gl_FragColor = objectTextureColor;
  // gl_FragColor = outlineTextureColor;
  // vec4 finalColor = outlineResultColor + objectTextureColor;
  // Ensure the outline is only applied where necessary

  // vec4 finalColor = mix(outlineResultColor, objectTextureColor, objectTextureColor.a);
  vec4 finalColor = outlineResultColor;

  // If the outline exists and is in front, blend it in additively
  if (outlineResultColor.a > 0.1) {
    finalColor.rgb = mix(finalColor.rgb, outlineResultColor.rgb, outlineResultColor.a);
    finalColor.a = max(objectTextureColor.a, outlineResultColor.a); // Preserve alpha
  }
  
  gl_FragColor = vec4(1.0, 0.0, 0.0, 1.0);
  // gl_FragColor = vec4(pow(finalColor.rgb, vec3(1.0 / 2.2)), finalColor.a);
}