import useDevMode from "@/components/hooks/useDevMode";
import { Button } from "@/components/ui/button";
import { Volume, Volume1, Volume2 } from "lucide-react";
import { FC, useCallback, useEffect, useState } from "react";
import { VideoTexture } from "three";

interface Props {
  hostVideoUrl: string;
  welcomeImageUrl: string;
  setHasAcceptedPermissions: (value: boolean) => void;
  setHasPlayedHostVideo: (value: boolean) => void;
  setLoadedVideoTexture: (texture: THREE.VideoTexture) => void;
  setLoadedVideo: (video: HTMLVideoElement) => void;
}

/**
 * WelcomePage component.
 *
 * This component renders a welcome page with a background image and a button to enter.
 * It requests device orientation permissions and loads a video when the button is clicked.
 *
 * Props:
 * - hostVideo: The source URL of the host video.
 * - setHasAcceptedPermissions: Function to update the state indicating if permissions were accepted.
 * - setHasPlayedHostVideo: Function to update the state indicating if the host video has been played.
 * - setLoadedVideo: Function to update the state with the loaded video element.
 * - setLoadedVideoTexture: Function to update the state with the loaded video texture.
 *
 * @param {Object} props - The component props.
 * @param {string} props.hostVideoUrl - The source URL of the host video.
 * @param {string} props.welcomeImageUrl - The source URL of the welcome image background.
 * @param {Function} props.setHasAcceptedPermissions - Function to update the state indicating if permissions were accepted.
 * @param {Function} props.setHasPlayedHostVideo - Function to update the state indicating if the host video has been played.
 * @param {Function} props.setLoadedVideo - Function to update the state with the loaded video element.
 * @param {Function} props.setLoadedVideoTexture - Function to update the state with the loaded video texture.
 *
 * @returns {JSX.Element} The rendered WelcomePage component.
 */
const WelcomePage: FC<Props> = ({
  hostVideoUrl,
  welcomeImageUrl,
  setHasAcceptedPermissions,
  setHasPlayedHostVideo,
  setLoadedVideo,
  setLoadedVideoTexture,
}: Props) => {
  const { isDev, isDesktop } = useDevMode();

  /**
   * Requests permission for device orientation events if required.
   *
   * This function checks if the `DeviceOrientationEvent.requestPermission` method exists.
   * If it does, it requests permission and sets the state to indicate whether the permission
   * was granted. If the method does not exist, it assumes no permissions are needed and sets
   * the state accordingly.
   *
   * @returns {void}
   */
  const requestDevicePermission = useCallback(() => {
    if (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
      typeof (DeviceOrientationEvent as any).requestPermission === "function"
    ) {
      // Request permission for device orientation events
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any
      (DeviceOrientationEvent as any)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        .requestPermission()
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        .then((state: string) => {
          if (state === "granted") {
            setHasAcceptedPermissions(true);
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        .catch(console.error);
    } else {
      // No permissions needed
      if (!isDev && !isDesktop) setHasAcceptedPermissions(true);
    }
  }, [isDesktop, isDev, setHasAcceptedPermissions]);

  /**
   * Loads a video element, sets its properties, and creates a VideoTexture from it.
   *
   * @returns {HTMLVideoElement} The created video element.
   *
   * The function performs the following steps:
   * 1. Creates a video element and sets its source, looping, muted, autoplay, and playsInline properties.
   * 2. Adds an event listener to set a state when the video ends.
   * 3. Loads the video.
   * 4. Creates a VideoTexture from the video element and updates its properties.
   * 5. Sets the video and texture in the state.
   * 6. Requests device permissions.
   */
  const loadVideo = useCallback(() => {
    if (hostVideoUrl) {
      // Create a video element
      const videoElement = document.createElement("video");
      videoElement.src = hostVideoUrl;
      videoElement.crossOrigin = "anonymous"; // Ensure cross-origin is handled if needed
      videoElement.loop = false;
      videoElement.muted = false;
      videoElement.autoplay = false;
      videoElement.playsInline = true;

      videoElement.onended = () => {
        setHasPlayedHostVideo(true);
      };

      // NOTE: re-evaluate this later - allow the experience to progress if host video fails to load
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      videoElement.onerror = (e) => {
        setHasPlayedHostVideo(true);
      };

      videoElement.load();

      const texture = new VideoTexture(videoElement);
      texture.needsUpdate = true;
      texture.colorSpace = "srgb";

      setLoadedVideo(videoElement);
      setLoadedVideoTexture(texture);
    }

    return;
  }, [
    hostVideoUrl,
    setHasPlayedHostVideo,
    setLoadedVideo,
    setLoadedVideoTexture,
  ]);

  useEffect(() => {
    // Load the video when the component mounts
    loadVideo();
  }, [loadVideo]);

  const onEnterClick = useCallback(() => {
    if (isDev || isDesktop) {
      setHasAcceptedPermissions(true);
    } else {
      requestDevicePermission();
    }
  }, [isDesktop, isDev, loadVideo, setHasAcceptedPermissions]);

  return (
    <div
      style={{
        position: "absolute",
        width: "100vw",
        height: "100dvh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f4f5f8",
        zIndex: "999",
      }}
    >
      <div
        style={{
          backgroundImage: `url("${welcomeImageUrl}")`,
          // backgroundImage: 'url("' + welcomeImageUrl + '")',
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          padding: "30px",
          // borderRadius: "10px",
          marginInline: "40px",
          position: "fixed",
          bottom: "0",
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          className="text-primary-foreground bg-primary m-3 text-3xl bottom-16 absolute"
          onClick={onEnterClick}
        >
          Tap to Enter
        </Button>
        <div
          className="bg-primary-foreground"
          style={{
            width: "100%",
            opacity: "0.7",
            position: "absolute",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            // marginTop: "40px",
            fontWeight: "bold",
            fontFamily: "Noto Sans",
            // top: "61.5%",
            bottom: "0vh",
          }}
        >
          <h2 className="text-lg text-primary flex items-center gap-2 animate-flash">
            <Volume2 />
            Turn Your Volume Up
          </h2>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
