/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_KmartShell.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    ClamShellLamp: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_KmartShellF(
  props: JSX.IntrinsicElements["group"]
) {
  const { nodes, materials } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_KmartShell.glb"
  ) as GLTFResult;

  const KmartShellD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_KmartShellD.avif"
  );
  KmartShellD.flipY = false;
  KmartShellD.colorSpace = THREE.SRGBColorSpace;

  const KmartShellMat = new THREE.MeshBasicMaterial({
    map: KmartShellD,
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.ClamShellLamp.geometry}
        material={KmartShellMat}
        position={[-1.93, 0.553, 3.169]}
        rotation={[0, -0.953, 0]}
      />
    </group>
  );
}
export const WTFN25_SPIN5_KmartShell = () => {
  return <WTFN25_SPIN5_KmartShellF></WTFN25_SPIN5_KmartShellF>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_KmartShell.glb");
