/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_SpareDoors.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Plane074: THREE.Mesh;
    Plane074_1: THREE.Mesh;
    Plane074_2: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_SpareDoorsF(
  props: JSX.IntrinsicElements["group"]
) {
  const { nodes, materials } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_SpareDoors.glb"
  ) as GLTFResult;

  const SpareDoorsD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_SpareDoorsD.avif"
  );
  SpareDoorsD.flipY = false;
  SpareDoorsD.colorSpace = THREE.SRGBColorSpace;

  const SpareDoorsMat = new THREE.MeshBasicMaterial({
    map: SpareDoorsD,
  });
  const GlassMat = new THREE.MeshStandardMaterial({
    color: "black",
    roughness: 0,
    transparent: true,
    opacity: 0.2,
  });

  return (
    <group {...props} dispose={null}>
      <group
        position={[-2.498, 0, 6.626]}
        rotation={[0, 0, -Math.PI]}
        scale={-1}
      >
        <mesh geometry={nodes.Plane074.geometry} material={SpareDoorsMat} />
        <mesh geometry={nodes.Plane074_1.geometry} material={SpareDoorsMat} />
        <mesh geometry={nodes.Plane074_2.geometry} material={GlassMat} />
      </group>
    </group>
  );
}
export const WTFN25_SPIN5_SpareDoors = () => {
  return <WTFN25_SPIN5_SpareDoorsF></WTFN25_SPIN5_SpareDoorsF>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_SpareDoors.glb");
