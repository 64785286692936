/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_StanleyBagPlanes.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useAnimations, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Plane: THREE.Mesh;
    Plane009: THREE.Mesh;
    Plane013: THREE.Mesh;
    Plane015: THREE.Mesh;
    Plane021: THREE.Mesh;
    Plane024: THREE.Mesh;
    Plane023: THREE.Mesh;
  };
  materials: {};
};

type ActionName =
  | "PlaneAction"
  | "PlaneAction.001"
  | "PlaneAction.002"
  | "PlaneAction.003"
  | "PlaneAction.004"
  | "PlaneAction.006"
  | "PlaneAction.007";
type GLTFActions = Record<ActionName, THREE.AnimationAction>;

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_StanleyBagPlanesF(
  props: JSX.IntrinsicElements["group"]
) {
  const group = useRef<THREE.Group>();
  const { nodes, materials, animations } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_StanleyBagPlanes.glb"
  ) as GLTFResult;
  const { actions } = useAnimations(animations, group);

  const StanleyChargerPlane = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_StanleyChargerPlane.avif"
  );
  StanleyChargerPlane.flipY = false;
  StanleyChargerPlane.colorSpace = THREE.SRGBColorSpace;

  const StanleyChargerMat = new THREE.MeshBasicMaterial({
    map: StanleyChargerPlane,
    transparent: true,
  });

  const StanleyDriverPlane = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_StanleyDriverPlane.avif"
  );
  StanleyDriverPlane.flipY = false;
  StanleyDriverPlane.colorSpace = THREE.SRGBColorSpace;

  const StanleyDriverMat = new THREE.MeshBasicMaterial({
    map: StanleyDriverPlane,
    transparent: true,
  });

  const StanleyBatteryPlane = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_StanleyBatteryPlane.avif"
  );
  StanleyBatteryPlane.flipY = false;
  StanleyBatteryPlane.colorSpace = THREE.SRGBColorSpace;

  const StanleyBatteryMat = new THREE.MeshBasicMaterial({
    map: StanleyBatteryPlane,
    transparent: true,
  });

  const StanleyDrillPlane = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_StanleyDrillPlane.avif"
  );
  StanleyDrillPlane.flipY = false;
  StanleyDrillPlane.colorSpace = THREE.SRGBColorSpace;

  const StanleyDrillMat = new THREE.MeshBasicMaterial({
    map: StanleyDrillPlane,
    transparent: true,
  });

  const StanleyCirSawPlane = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_StanleyCirSawPlane.avif"
  );
  StanleyCirSawPlane.flipY = false;
  StanleyCirSawPlane.colorSpace = THREE.SRGBColorSpace;

  const StanleyCirSawMat = new THREE.MeshBasicMaterial({
    map: StanleyCirSawPlane,
    transparent: true,
  });

  const StanleyGrinderPlane = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_StanleyGrinderPlane.avif"
  );
  StanleyGrinderPlane.flipY = false;
  StanleyGrinderPlane.colorSpace = THREE.SRGBColorSpace;

  const StanleyGrinderMat = new THREE.MeshBasicMaterial({
    map: StanleyGrinderPlane,
    transparent: true,
  });

  return (
    <group {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="Plane"
          geometry={nodes.Plane.geometry}
          material={StanleyDrillMat}
          position={[-8.372, 1.486, 3.508]}
          rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          name="Plane009"
          geometry={nodes.Plane009.geometry}
          material={StanleyCirSawMat}
          position={[-8.372, 1.486, 3.508]}
          rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          name="Plane013"
          geometry={nodes.Plane013.geometry}
          material={StanleyBatteryMat}
          position={[-8.372, 1.486, 3.508]}
          rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          name="Plane015"
          geometry={nodes.Plane015.geometry}
          material={StanleyDriverMat}
          position={[-8.372, 1.486, 3.508]}
          rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          name="Plane021"
          geometry={nodes.Plane021.geometry}
          material={StanleyGrinderMat}
          position={[-8.372, 1.486, 3.508]}
          rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          name="Plane024"
          geometry={nodes.Plane024.geometry}
          material={StanleyChargerMat}
          position={[-8.372, 1.486, 3.508]}
          rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          name="Plane023"
          geometry={nodes.Plane023.geometry}
          material={StanleyBatteryMat}
          position={[-8.372, 1.486, 3.508]}
          rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        />
      </group>
    </group>
  );
}
export const WTFN25_SPIN5_StanleyBagPlanes = () => {
  return <WTFN25_SPIN5_StanleyBagPlanesF></WTFN25_SPIN5_StanleyBagPlanesF>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_StanleyBagPlanes.glb");
