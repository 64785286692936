varying vec2 vUv;
uniform sampler2D uTexture;
uniform float uGamma;

void main() {
  vec4 videoColour = texture2D(uTexture, vUv); // Sample the original texture
  if (videoColour.g > 0.2 && videoColour.r < videoColour.g && videoColour.b < videoColour.g) 
    videoColour.a = 0.0;

  // gl_FragColor = videoColour;
  gl_FragColor = vec4(pow(videoColour.rgb, vec3(uGamma)), videoColour.a);
}