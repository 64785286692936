/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_SRSKBefore.glb -t -r public 
*/

import * as THREE from "three";
import { useRef } from "react";
import { useGLTF, useAnimations, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import useSegment from "@/components/hooks/useSegment";

type GLTFResult = GLTF & {
  nodes: {
    Large_Ezi_Carry_Box_003: THREE.Mesh;
    Large_Ezi_Carry_Box_Lid_1001: THREE.Mesh;
    Large_Ezi_Carry_Box_Lid_2001: THREE.Mesh;
    Large_Ezi_Carry_Box_004: THREE.Mesh;
    Large_Ezi_Carry_Box_Lid_1002: THREE.Mesh;
    Large_Ezi_Carry_Box_Lid_2002: THREE.Mesh;
  };
  materials: object;
};

type ActionName =
  | "Large Ezi Carry Box Lid 1.001Action"
  | "Large Ezi Carry Box Lid 2.001Action"
  | "Large Ezi Carry Box Lid 1.002Action"
  | "Large Ezi Carry Box Lid 2.002Action";
type GLTFActions = Record<ActionName, THREE.AnimationAction>;

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_SRSKBoxBeforeFrontF(
  props: JSX.IntrinsicElements["group"]
) {
  const group = useRef<THREE.Group>();
  const { nodes, animations } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_SRSKBefore.glb"
  ) as GLTFResult;
  const { actions } = useAnimations(animations, group);

  const SRSKBeforeD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_SRSKBeforeD.avif"
  );
  SRSKBeforeD.flipY = false;
  SRSKBeforeD.colorSpace = THREE.SRGBColorSpace;
  SRSKBeforeD.channel = 1;

  const SRSKBeforeMat = new THREE.MeshBasicMaterial({
    map: SRSKBeforeD,
  });

  const onClick = () => {
    Object.values(actions).forEach((action) => {
      if (action) {
        action.reset();
        action.setLoop(THREE.LoopOnce, 1);
        action.clampWhenFinished = true;
        action.play();
      }
    });
  };

  return (
    <group {...props} dispose={null} onClick={onClick} ref={group}>
      <group name="Scene">
        <mesh
          name="Large_Ezi_Carry_Box_003"
          geometry={nodes.Large_Ezi_Carry_Box_003.geometry}
          material={SRSKBeforeMat}
          position={[-3.497, 0, 3.132]}
          rotation={[0, 0.241, 0]}
        />
        <mesh
          name="Large_Ezi_Carry_Box_Lid_1001"
          geometry={nodes.Large_Ezi_Carry_Box_Lid_1001.geometry}
          material={SRSKBeforeMat}
          position={[-3.175, 0.335, 3.245]}
          rotation={[2.058, -0.115, -0.213]}
        />
        <mesh
          name="Large_Ezi_Carry_Box_Lid_2001"
          geometry={nodes.Large_Ezi_Carry_Box_Lid_2001.geometry}
          material={SRSKBeforeMat}
          position={[-3.264, 0.335, 2.884]}
          rotation={[-1.676, -0.026, 0.24]}
        />
      </group>
    </group>
  );
}
export const WTFN25_SPIN5_SRSKBoxBeforeFront = () => {
  return <WTFN25_SPIN5_SRSKBoxBeforeFrontF></WTFN25_SPIN5_SRSKBoxBeforeFrontF>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_SRSKBefore.glb");
