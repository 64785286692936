/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_BDVac4.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    VacuumHead003: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_BDVac4F(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_BDVac4.glb"
  ) as GLTFResult;

  const BDVac4D = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_BDVac4D.avif"
  );
  BDVac4D.flipY = false;
  BDVac4D.colorSpace = THREE.SRGBColorSpace;
  BDVac4D.channel = 1;
  const BDVac4Mat = new THREE.MeshBasicMaterial({
    map: BDVac4D,
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.VacuumHead003.geometry}
        material={BDVac4Mat}
        position={[3.263, 0, 1.618]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={1.5}
      />
    </group>
  );
}
export const WTFN25_SPIN5_BDVac4 = () => {
  return <WTFN25_SPIN5_BDVac4F></WTFN25_SPIN5_BDVac4F>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_BDVac4.glb");
