import createSelectors from "@/stores/createSelectors";
import { create } from "zustand";
import { combine } from "zustand/middleware";

export interface InteractionEvent {
  value: string;
  data?: object;
}

const initialState = {
  events: new Map<string, InteractionEvent>(),
  clear: () => null,
};

const interactionsStore = create(
  combine(initialState, (set) => ({
    addEvent: (key: string, interactionEvent: InteractionEvent) =>
      set((state) => {
        const eventsMap = new Map(state.events); // Clone the current Map
        eventsMap.set(key, interactionEvent); // Replace or add event
        return { events: eventsMap };
      }),
    clear: () =>
      set(() => ({
        ...initialState,
        events: new Map<string, InteractionEvent>(),
      })),
  }))
);

const useInteractionsStore = createSelectors(interactionsStore);

export default useInteractionsStore;
