/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_KmartLinearVase.glb -t -r public 
*/

import * as THREE from "three";

import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Vase: THREE.Mesh;
  };
  materials: {};
};

export function WTFN25_SPIN5_KmartLinearVaseF(
  props: JSX.IntrinsicElements["group"]
) {
  const { nodes, materials } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_KmartLinearVase.glb"
  ) as GLTFResult;

  const KmartLinearD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_KmartLinearVaseD.avif"
  );
  KmartLinearD.flipY = false;
  KmartLinearD.colorSpace = THREE.SRGBColorSpace;
  KmartLinearD.channel = 1;

  const KmartLinearMat = new THREE.MeshBasicMaterial({
    map: KmartLinearD,
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Vase.geometry}
        material={KmartLinearMat}
        position={[-1.929, 0.767, -3.624]}
        rotation={[0, -1.249, 0]}
      />
    </group>
  );
}

export const WTFN25_SPIN5_KmartLinearVase = () => {
  return <WTFN25_SPIN5_KmartLinearVaseF></WTFN25_SPIN5_KmartLinearVaseF>;
};

useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_KmartLinearVase.glb");
