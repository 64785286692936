/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_LoungeTrim.glb -t -r public 
*/

import * as THREE from "three";

import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    MainWalls: THREE.Mesh;
  };
  materials: {};
};

export function WTFN25_SPIN5_LoungeTrimF(
  props: JSX.IntrinsicElements["group"]
) {
  const { nodes } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_LoungeTrim.glb"
  ) as GLTFResult;

  const LoungeTrimD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_LoungeTrimD.avif"
  );
  LoungeTrimD.flipY = false;
  LoungeTrimD.colorSpace = THREE.SRGBColorSpace;
  LoungeTrimD.channel = 0;

  const LoungeTrimMat = new THREE.MeshBasicMaterial({
    map: LoungeTrimD,
  });

  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.MainWalls.geometry} material={LoungeTrimMat} />
    </group>
  );
}

export const WTFN25_SPIN5_LoungeTrim = () => {
  return <WTFN25_SPIN5_LoungeTrimF></WTFN25_SPIN5_LoungeTrimF>;
};

useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_LoungeTrim.glb");
