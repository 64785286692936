/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_VanDoor.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useAnimations, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Body_interior_Body_Interior_0001: THREE.Mesh;
  };
  materials: {};
};

type ActionName = "Body_interior_Body Interior_0.001";
type GLTFActions = Record<ActionName, THREE.AnimationAction>;

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_VanDoorF(props: JSX.IntrinsicElements["group"]) {
  const group = useRef<THREE.Group>();
  const { nodes, materials, animations } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_VanDoor.glb"
  ) as GLTFResult;
  const { actions } = useAnimations(animations, group);

  const VanDoorD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_VanDoorD.avif"
  );
  VanDoorD.flipY = false;
  VanDoorD.colorSpace = THREE.SRGBColorSpace;
  VanDoorD.channel = 2;
  const VanDoorMat = new THREE.MeshBasicMaterial({
    map: VanDoorD,
  });

  return (
    <group {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="Body_interior_Body_Interior_0001"
          geometry={nodes.Body_interior_Body_Interior_0001.geometry}
          material={VanDoorMat}
          position={[-17.595, 1.373, 2.872]}
          rotation={[0.086, -0.713, 0.119]}
        />
      </group>
    </group>
  );
}
export const WTFN25_SPIN5_VanDoor = () => {
  return <WTFN25_SPIN5_VanDoorF></WTFN25_SPIN5_VanDoorF>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_VanDoor.glb");
