import { Scene } from "@/models/scene";

export const spaceInvadersScenes: Scene[] = [
  {
    id: "1",
    sceneName: "Host Intro",
    objectPositions: [],
    videoPositions: [{ id: "1", positionName: "Wall TV", selected: null }],
    xTargetPosition: -2.3,
    yTargetPosition: 1.2,
    zTargetPosition: 4.1,
    xCameraPosition: -0.35,
    yCameraPosition: 1.6,
    zCameraPosition: 4.9,
  },
  {
    id: "2",
    sceneName: "Hero Shoe 1",
    objectPositions: [],
    xTargetPosition: 0,
    yTargetPosition: 1.65,
    zTargetPosition: 1.5,
    xCameraPosition: 1.2,
    yCameraPosition: 1.35,
    zCameraPosition: 4,
  },
  {
    id: "3",
    objectPositions: [],
    sceneName: "Hero Shoe 2",
    xTargetPosition: 0,
    yTargetPosition: 1.65,
    zTargetPosition: 1.5,
    xCameraPosition: -2.2,
    yCameraPosition: 1.35,
    zCameraPosition: 1.1,
  },
  {
    id: "4",
    objectPositions: [
      {
        id: "1",
        positionName: "1",
        selected: "1",
        virtualObjects: [
          {
            id: "1",
            virtualObjectName: "Test Product A",
            virtualObjectComponentName: "Donut",
            positionId: "1",
            sceneId: "4",
          },
        ],
        // TODO: currently the position coordinates are not used
        xPosition: 0,
        yPosition: 0,
        zPosition: 0,
      },
      {
        id: "2",
        positionName: "2",
        selected: "2",
        virtualObjects: [
          {
            id: "2",
            virtualObjectName: "Test Product B",
            virtualObjectComponentName: "Cone",
            positionId: "2",
            sceneId: "4",
          },
        ],
        // TODO: currently the position coordinates are not used
        xPosition: 0,
        yPosition: 0,
        zPosition: 0,
      },
      {
        id: "3",
        positionName: "3",
        selected: "3",
        virtualObjects: [
          {
            id: "3",
            virtualObjectName: "Test Product C",
            virtualObjectComponentName: "RedCube",
            positionId: "3",
            sceneId: "4",
          },
          {
            id: "4",
            virtualObjectName: "Test Product D",
            virtualObjectComponentName: "BlueCube",
            positionId: "3",
            sceneId: "4",
          },
        ],
        // TODO: currently the position coordinates are not used
        xPosition: 0,
        yPosition: 0,
        zPosition: 0,
      },
    ],
    sceneName: "Shoe Rack",
    xTargetPosition: 2.2,
    yTargetPosition: 1.65,
    zTargetPosition: -2.65,
    xCameraPosition: 0.8,
    yCameraPosition: 1.5,
    zCameraPosition: -2.3,
  },
  {
    id: "5",
    objectPositions: [],
    sceneName: "Hype Real",
    xTargetPosition: -2,
    yTargetPosition: 1.65,
    zTargetPosition: -5.5,
    xCameraPosition: 0.1,
    yCameraPosition: 1.4,
    zCameraPosition: -5.1,
  },

  {
    id: "6",
    objectPositions: [],
    sceneName: "Courtyard",
    xTargetPosition: -1.5,
    yTargetPosition: 0.7,
    zTargetPosition: -12,
    xCameraPosition: 0.3,
    yCameraPosition: 1.65,
    zCameraPosition: -7.5,
  },
];
