import TestCanvas from "@/components/3d/TestCanvas";
import VirtualObject from "@/components/3d/VirtualObject";
import VirtualObjectHud from "@/components/3d/VirtualObjectHud";

import * as Models from "@/components/sets/spin5/models";
// const Models = await import("@/components/sets/spin5/models");

import { CameraControls, Center, StatsGl } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { useEffect } from "react";

const Controls = () => {
  const { camera } = useThree();

  useEffect(() => {
    camera.position.set(0, 0, 5);
  }, []);

  return <CameraControls />;
};

export default function CanvasPage() {
  return (
    <>
      <TestCanvas>
        <Controls />
        {/* <VirtualObject scale={[2, 2, 2]} position={[-2, 0, 0]}>
          <Center>
            <WTFN25_SPIN5_LoungeF />
          </Center>
        </VirtualObject> */}
        <Center>
          {/* <Models.WTFN25_SPIN5_SRPlanesBefore />
          <Models.WTFN25_SPIN5_SRSKBoxBeforeBack /> */}
          {/* <Models.WTFN25_SPIN5_SRBedBefore /> */}
          {/* <Models.WTFN25_SPIN5_SRSKBoxBeforeFront /> */}
          {/* <Models.WTFN25_SPIN5_SRBeforeObj /> */}
          <Models.WTFN25_SPIN5_BedShedOslo />
          <Models.WTFN25_SPIN5_SKBoxEzi />
          <Models.WTFN25_SPIN5_SpareDoors />
          <Models.WTFN25_SPIN5_SpareWalls />
          <Models.WTFN25_SPIN5_Garden2 />
          {/* <Models.WTFN25_SPIN5_Skysphere /> */}
          <Models.WTFN25_SPIN5_SKBoxesGarageEnd />
          <Models.WTFN25_SPIN5_VanDoor />
          <Models.WTFN25_SPIN5_VanBase />
          <Models.WTFN25_SPIN5_VanInside />
          <Models.WTFN25_SPIN5_FloorWood />
          <Models.WTFN25_SPIN5_GarageAfter />
          <Models.WTFN25_SPIN5_GarageAfterStatic />
          <Models.WTFN25_SPIN5_SpareDownLights />
          <Models.WTFN25_SPIN5_StanleySaw />
          <Models.WTFN25_SPIN5_StanleySander />
          <Models.WTFN25_SPIN5_StanleyLift />
          <Models.WTFN25_SPIN5_KmartShell />
          <Models.WTFN25_SPIN5_StanleyBlower />
          <Models.WTFN25_SPIN5_SpareBedroomDecor />
          <Models.WTFN25_SPIN5_SKBoxesGarageEnd />
          <Models.WTFN25_SPIN5_BDSteamMop />
          <Models.WTFN25_SPIN5_BDVac1 />
          <Models.WTFN25_SPIN5_BDVac2 />
          <Models.WTFN25_SPIN5_BDVac3 />
          <Models.WTFN25_SPIN5_BDVac4 />
          <Models.WTFN25_SPIN5_BedShed1 />
          <Models.WTFN25_SPIN5_Carpet />
          <Models.WTFN25_SPIN5_Ceiling />
          <Models.WTFN25_SPIN5_DaikinAlira />
          <Models.WTFN25_SPIN5_DaikinHub />
          <Models.WTFN25_SPIN5_DaikinPhone />
          <Models.WTFN25_SPIN5_DaikinPurifier />
          <Models.WTFN25_SPIN5_DaikinReturn />
          <Models.WTFN25_SPIN5_Dining />
          <Models.WTFN25_SPIN5_Driveway />
          <Models.WTFN25_SPIN5_FrontYard />
          <Models.WTFN25_SPIN5_GarageDoor />
          <Models.WTFN25_SPIN5_Garden />
          <Models.WTFN25_SPIN5_GardenPlants1 />
          <Models.WTFN25_SPIN5_HallWalls />
          <Models.WTFN25_SPIN5_KitchenCupboards />
          <Models.WTFN25_SPIN5_KitchenIsland />
          <Models.WTFN25_SPIN5_KitchenLights />
          <Models.WTFN25_SPIN5_KmartBoston />
          <Models.WTFN25_SPIN5_KmartCanvas />
          <Models.WTFN25_SPIN5_KmartDahliaL />
          <Models.WTFN25_SPIN5_KmartDahliaSB />
          <Models.WTFN25_SPIN5_KmartLinearVase />
          <Models.WTFN25_SPIN5_KmartMarleTassel />
          <Models.WTFN25_SPIN5_KmartMirror />
          <Models.WTFN25_SPIN5_KmartMushroom />
          <Models.WTFN25_SPIN5_KmartShelf />
          <Models.WTFN25_SPIN5_KmartTub />
          <Models.WTFN25_SPIN5_Lounge />
          <Models.WTFN25_SPIN5_LoungeTrim />
          <Models.WTFN25_SPIN5_LoungeWallFeature />
          <Models.WTFN25_SPIN5_LoungeWallsChange />
          <Models.WTFN25_SPIN5_MasterDecor />
          <Models.WTFN25_SPIN5_MasterDesk />
          <Models.WTFN25_SPIN5_MasterDoors />
          <Models.WTFN25_SPIN5_MasterDownLights />
          <Models.WTFN25_SPIN5_MasterWalls />
          <Models.WTFN25_SPIN5_Paint />
          <Models.WTFN25_SPIN5_PaintRoller />
          <Models.WTFN25_SPIN5_Ceiling />
          <Models.WTFN25_SPIN5_SpareBedroomTV />
          <Models.WTFN25_SPIN5_SKBoxKitchen />
          <Models.WTFN25_SPIN5_SKBoxArchiveAfter />
          <Models.WTFN25_SPIN5_SKBoxHeavyAfter />
          <Models.WTFN25_SPIN5_SKBoxLargeHeavyAfter />
          <Models.WTFN25_SPIN5_SKBoxLiteAfter />
          <Models.WTFN25_SPIN5_HamperWorld />
          <Models.WTFN25_SPIN5_VideoPlane />
          <Models.WTFN25_SPIN5_SKBoxesGaragePersist />
          <Models.WTFN25_SPIN5_KmartCarvedBowl />
          <Models.WTFN25_SPIN5_TaubmansAria />
          <Models.WTFN25_SPIN5_TaubmansClover />
          <Models.WTFN25_SPIN5_TaubmansCrisp />
          <Models.WTFN25_SPIN5_SKBoxesDining />
          <Models.WTFN25_SPIN5_CarpetFECorinSwatch />
          <Models.WTFN25_SPIN5_CarpetFEOberonSwatch />
          <Models.WTFN25_SPIN5_FESampleGum />
          <Models.WTFN25_SPIN5_FESampleDorian />
          <Models.WTFN25_SPIN5_FESampleOak />
          <Models.WTFN25_SPIN5_StanleyBag />
          <Models.WTFN25_SPIN5_KitchenSpill2 />
          <Models.WTFN25_SPIN5_GardenSetting />
          <Models.WTFN25_SPIN5_GardenBackPlane />
          <Models.WTFN25_SPIN5_LoungeGlass />
          <Models.WTFN25_SPIN5_StanleyBagPlanes />
          <Models.WTFN25_SPIN5_SRBeforeObj />
          <Models.WTFN25_SPIN5_SRSKBoxBeforeFront />

          {/* <Models.WTFN25_SPIN5_GarageBeforeStatic /> */}
          <VirtualObject>
            <group />
          </VirtualObject>
        </Center>
        {/* <VirtualObject scale={[2, 2, 2]} position={[2, 0, -2]}>
          <Center>

          </Center>
        </VirtualObject> */}
        {/* <VirtualObject scale={[2, 2, 2]} position={[1, 0, 0]}>
          <Center>
            <WTFN25_SPIN5_SteamMopF floating={false} />
          </Center>
        </VirtualObject> */}
        {/* <VirtualObject position={[2, 0, 0]}>
          <Center>
            <WTFN25_SPIN5_PaintRollerF />
          </Center>
        </VirtualObject> */}
        {/* <VirtualObject position={[3, 0, 0]} rotation={[0, Math.PI, 0]}>
          <Center>
            <WTFN25_SPIN5_MasterDeskF />
          </Center>
        </VirtualObject> */}
        <VirtualObjectHud />
        <StatsGl className="stats fixed" />
      </TestCanvas>
    </>
  );
}
