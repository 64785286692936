/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_SKBoxLargeHeavyAfter.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Extra_Large_Heavy_Duty_Box003: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_SKBoxLargeHeavyAfterF(
  props: JSX.IntrinsicElements["group"]
) {
  const { nodes, materials } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_SKBoxLargeHeavyAfter.glb"
  ) as GLTFResult;

  const SKBoxesAfterD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_SKBoxesAfterD.avif"
  );
  SKBoxesAfterD.flipY = false;
  SKBoxesAfterD.colorSpace = THREE.SRGBColorSpace;
  SKBoxesAfterD.channel = 1;
  const SKBoxesAfterMat = new THREE.MeshBasicMaterial({
    map: SKBoxesAfterD,
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Extra_Large_Heavy_Duty_Box003.geometry}
        material={SKBoxesAfterMat}
        position={[-9.947, 0, 2.724]}
        rotation={[0, -1.003, 0]}
      />
    </group>
  );
}
export const WTFN25_SPIN5_SKBoxLargeHeavyAfter = () => {
  return (
    <WTFN25_SPIN5_SKBoxLargeHeavyAfterF></WTFN25_SPIN5_SKBoxLargeHeavyAfterF>
  );
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_SKBoxLargeHeavyAfter.glb");
