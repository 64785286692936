/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-inner-declarations */
import ViewerCanvas from "@/components/3d/ViewerCanvas";
import useDevMode from "@/components/hooks/useDevMode";
import { LazyDynamicModels } from "@/components/sets/set-dictionary";
import { ModelMap } from "@/components/sets/shared/ModelMapping";
import * as Models from "@/components/sets/spin5/models"; // Import all models
import { Spin5Set } from "@/components/sets/spin5/models/setObjects";
import { useExperienceContext } from "@/context/experience.context";
import { Experience } from "@/models/experience";
import { Scene } from "@/models/scene";
import { Set } from "@/models/set";
import { Video } from "@/models/video";
import { VideoElementWrapper } from "@/pages/manager/Panel/Brand/experience/editor/3d/VideoElementWrapper";
import ScenesManager from "@/pages/Viewer/viewer/3d/ScenesManager";
import { ViewerDynamicModelWrapper } from "@/pages/Viewer/viewer/3d/ViewerDynamicModelWrapper";
import VideoService from "@/services/video.service";
import { Edit } from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";
import { firstValueFrom } from "rxjs";
import CameraRig from "./3d/CameraRig";
import { useInteractionContext } from "./context/InteractionContext";
// const Models = await import("@/components/sets/spin5/models");

type ModelKeys = keyof typeof Models;

interface ViewerViewProps {
  set: Set;
  currentScene: Scene;
  experience: Experience;
  scenes: Scene[];
  currentCameraPosition: THREE.Vector3;
  currentTargetPosition: THREE.Vector3;
  setHasExperienceLoaded: (hasExperienceLoaded: boolean) => void;
  handleExperienceVirtualObjectTap: (obj: string) => void;
}

export const ViewerView = ({
  experience,
  set,
  scenes,
  currentScene,
  setHasExperienceLoaded,
  handleExperienceVirtualObjectTap,
}: ViewerViewProps) => {
  useDevMode();
  const { realExperienceVirtualObjects } = useExperienceContext();
  const { setCurrentlySelected } = useInteractionContext();

  const [staticModelMapping, setStaticModelMapping] = useState<
    Record<string, ModelMap>
  >({});
  const [dynamicModelMapping, setDynamicModelMapping] = useState<
    Record<string, ModelMap>
  >({});

  const [videoWall, setVideoWall] = useState<Video | null>(null);

  /**
   * Fetch the video wall video content
   */
  useEffect(() => {
    if (experience?.videoId) {
      async function getData() {
        const video: Video = await firstValueFrom(
          VideoService.getOne(experience.videoId)
        );
        setVideoWall(video);
      }
      getData();
    }
  }, [experience.videoId]);

  useEffect(() => {
    // LazyStaticModels(set.setComponentName).then((mapping) => {
    // TODO: replace this to load from Models
    // setStaticModelMapping(mapping.default);
    // });

    LazyDynamicModels(set.setComponentName).then((mapping) => {
      setDynamicModelMapping(mapping.default);
    });
  }, [set]);

  const renderStaticModels = useCallback(() => {
    return Object.entries(staticModelMapping ?? []).map(([id, object]) => {
      if (object.model) {
        if (object.isVideoElement) {
          return (
            <VideoElementWrapper key={id} videoURL={videoWall?.videoStreamUrl}>
              <group key={id}>{object.model}</group>
            </VideoElementWrapper>
          );
        }

        return <group key={id}>{object.model}</group>;
      }
    });
  }, [staticModelMapping, videoWall?.videoStreamUrl]);

  const renderDynamicModels = useCallback(() => {
    return (
      scenes &&
      scenes.map(
        (scene) =>
          scene.objectPositions &&
          scene.objectPositions.map(
            (pos) =>
              pos.virtualObjects &&
              pos.virtualObjects.map((virtualObj) => {
                const experienceVirtualObject =
                  realExperienceVirtualObjects.find(
                    (experienceVirtualObj) =>
                      experienceVirtualObj.virtualObjectId === virtualObj.id
                  );

                if (
                  !experienceVirtualObject ||
                  (experienceVirtualObject &&
                    experienceVirtualObject.positionId !== pos.id) ||
                  !virtualObj.virtualObjectComponentName ||
                  !dynamicModelMapping
                ) {
                  return null;
                }

                return (
                  experienceVirtualObject &&
                  virtualObj.virtualObjectComponentName &&
                  dynamicModelMapping && (
                    <ViewerDynamicModelWrapper
                      key={virtualObj.id}
                      obj={virtualObj}
                      currentSceneId={currentScene.id}
                      onClick={() => {
                        handleExperienceVirtualObjectTap(
                          experienceVirtualObject.id
                        );
                        setCurrentlySelected(experienceVirtualObject.id);
                      }}
                    >
                      {dynamicModelMapping[
                        virtualObj.virtualObjectComponentName
                      ] && (
                        <group key={virtualObj.id}>
                          {React.cloneElement(
                            dynamicModelMapping[
                              virtualObj.virtualObjectComponentName
                            ].model,
                            {
                              position: [
                                pos.xPosition,
                                pos.yPosition,
                                pos.zPosition,
                              ],
                            }
                          )}
                        </group>
                      )}
                    </ViewerDynamicModelWrapper>
                  )
                );
              })
          )
      )
    );
  }, [
    currentScene.id,
    dynamicModelMapping,
    handleExperienceVirtualObjectTap,
    realExperienceVirtualObjects,
    scenes,
    setCurrentlySelected,
  ]);

  const renderSetModels = useCallback(() => {
    return Spin5Set.map((name, index) => {
      const Component = Models[name as ModelKeys];
      const key = `${name}-${index}`;
      if (Component) {
        return <Component key={key} />;
      } else {
        console.error(`Component not found for ${name}`);
        return null;
      }
    });
  }, []);

  return (
    // <div
    //   style={{
    //     height: "100dvh",
    //     width: "100dvw",
    //     position: "relative",
    //   }}
    // >
    <ViewerCanvas>
      {/* Camera components */}
      <CameraRig
        setHasExperienceLoaded={setHasExperienceLoaded}
        currentScene={currentScene}
      />

      {/* Static models */}
      {false && renderStaticModels()}
      {renderSetModels()}
      {/* Dynamic models */}
      {false && renderDynamicModels()}

      {/* New Scene Manager */}
      <ScenesManager />
    </ViewerCanvas>
    // </div>
  );
};

interface EditorViewTabProps {
  isProductEditorOpen: boolean;
  setIsProductEditorOpen: (isProductEditorOpen: boolean) => void;
}

export const EditorViewTab = ({
  isProductEditorOpen,
  setIsProductEditorOpen,
}: EditorViewTabProps) => {
  return (
    <div
      onClick={() => setIsProductEditorOpen(!isProductEditorOpen)}
      className="text-white select-none self-center m-4 flex flex-row cursor-pointer"
    >
      <Edit className="mr-2" />
      <p>Edit Products / Assets</p>
    </div>
  );
};
