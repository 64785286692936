import { useSearchParams } from "react-router-dom";

const useStickyLeva = (key: string, defaultValue: string) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const stickyValue = searchParams.get(key) || defaultValue;

  const setStickyValue = (value: string) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  return [stickyValue, setStickyValue] as const;
};

export default useStickyLeva;
