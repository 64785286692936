import VirtualObject from "@/components/3d/VirtualObject";
import * as Models from "@/components/sets/spin5/models"; // Import all models
// const Models = await import("@/components/sets/spin5/models");

import { Bounds, Center, PresentationControls } from "@react-three/drei";
import { useControls } from "leva";
import { useCallback } from "react";

const modelsPrefix = "WTFN25_SPIN5_"; // Prefix for all models
const modelsRegex = new RegExp(`^${modelsPrefix}`); // Create regex dynamically
const modelKeys = Object.keys(Models).map((key) => {
  return key.replace(modelsRegex, ""); // Removes 'A' if it's at the start
}); // Get model names dynamically

// const interpolateFunc = (t: number) => 1 - Math.exp(-5 * t) + 0.007 * t; // Matches the default Bounds behavior

export default function ModelsViewer() {
  const controls = useControls({
    model: {
      value: modelKeys[0], // Default model
      options: modelKeys, // Populate dropdown with model names
    },
    margin: {
      value: 1.2,
      min: 0.1,
      max: 2,
    },
    outline: {
      value: false,
    },
  });

  const ModelComponent = useCallback(() => {
    const Component = Models[
      `${modelsPrefix}${controls.model}`
    ] as unknown as React.FC;
    if (!Component || typeof Component !== "function") return null;
    return <Component />;
  }, [controls.model]);

  return (
    <Bounds
      key={`${controls.model}-${controls.margin}`}
      fit
      clip
      observe
      margin={controls.margin}
      maxDuration={1}
    >
      <PresentationControls
        key={controls.model}
        global
        speed={2}
        cursor
        azimuth={[-Infinity, Infinity]}
        polar={[-Infinity, Infinity]}
      >
        <Center>
          <VirtualObject highlight={controls.outline}>
            <ModelComponent />
          </VirtualObject>
        </Center>
      </PresentationControls>
    </Bounds>
  );
}
