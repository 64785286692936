/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_Driveway.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Plane006: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_DrivewayF(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_Driveway.glb"
  ) as GLTFResult;

  const DrivewayD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_DrivewayD.avif"
  );
  DrivewayD.flipY = false;
  DrivewayD.colorSpace = THREE.SRGBColorSpace;
  DrivewayD.channel = 2;
  const DrivewayMat = new THREE.MeshBasicMaterial({
    map: DrivewayD,
  });

  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Plane006.geometry} material={DrivewayMat} />
    </group>
  );
}
export const WTFN25_SPIN5_Driveway = () => {
  return <WTFN25_SPIN5_DrivewayF></WTFN25_SPIN5_DrivewayF>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_Driveway.glb");
