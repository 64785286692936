/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_StanleyLift.glb -t -r public 
*/

import { useGLTF, useTexture } from "@react-three/drei";
import * as THREE from "three";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    TradeLiftTwinPack001: THREE.Mesh;
  };
  materials: object;
};

// type ContextType = Record<
//   string,
//   React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
// >;

export function WTFN25_SPIN5_StanleyLiftF(
  props: JSX.IntrinsicElements["group"]
) {
  const { nodes } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_StanleyLift.glb"
  ) as GLTFResult;

  const StanleyLiftD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_StanleyLiftD.avif"
  );
  StanleyLiftD.flipY = false;
  StanleyLiftD.colorSpace = THREE.SRGBColorSpace;
  StanleyLiftD.channel = 1;
  const StanleyLiftMat = new THREE.MeshBasicMaterial({
    map: StanleyLiftD,
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.TradeLiftTwinPack001.geometry}
        material={StanleyLiftMat}
        position={[-8.199, 1.03, 2.803]}
        rotation={[Math.PI, 0.383, 1.445]}
      />
    </group>
  );
}
export const WTFN25_SPIN5_StanleyLift = () => {
  return <WTFN25_SPIN5_StanleyLiftF></WTFN25_SPIN5_StanleyLiftF>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_StanleyLift.glb");
