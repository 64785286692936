/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_MasterDoors.glb -t -r public 
*/

import * as THREE from "three";

import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Plane073: THREE.Mesh;
    Plane073_1: THREE.Mesh;
    Plane073_2: THREE.Mesh;
  };
  materials: {};
};

export function WTFN25_SPIN5_MasterDoorsF(
  props: JSX.IntrinsicElements["group"]
) {
  const { nodes } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_MasterDoors.glb"
  ) as GLTFResult;

  const MasterDoorsD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_MasterDoorsD.avif"
  );
  MasterDoorsD.flipY = false;
  MasterDoorsD.colorSpace = THREE.SRGBColorSpace;

  const MasterDoorsMat = new THREE.MeshBasicMaterial({
    map: MasterDoorsD,
  });

  const GlassMat = new THREE.MeshStandardMaterial({
    color: "#2B4542",
    roughness: 0,
    transparent: true,
    opacity: 0.3,
  });

  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Plane073.geometry} material={MasterDoorsMat} />
      <mesh geometry={nodes.Plane073_1.geometry} material={MasterDoorsMat} />
      <mesh geometry={nodes.Plane073_2.geometry} material={GlassMat} />
    </group>
  );
}

export const WTFN25_SPIN5_MasterDoors = () => {
  return <WTFN25_SPIN5_MasterDoorsF></WTFN25_SPIN5_MasterDoorsF>;
};

useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_MasterDoors.glb");
