import { useMemo } from "react";
import { ShaderMaterial, VideoTexture } from "three";
import fragmentShader from "./glsl/fragment.glsl";
import vertexShader from "./glsl/vertex.glsl";

interface AlphaVideoPlane {
  videoTexture: VideoTexture;
  gamma?: number;
}

export default function AlphaVideoMaterial({
  videoTexture,
  gamma = 1.8,
}: AlphaVideoPlane) {
  const rotoMaterial = useMemo(() => {
    return new ShaderMaterial({
      uniforms: {
        uTexture: { value: videoTexture },
        uGamma: { value: gamma },
      },
      vertexShader,
      fragmentShader,
      transparent: true,
      opacity: 1,
    });
  }, [gamma, videoTexture]);

  return <primitive object={rotoMaterial} />;
}
