/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_SRBeforeObj.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useAnimations, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Plane018: THREE.Mesh;
    Plane019: THREE.Mesh;
    Pillow_01: THREE.Mesh;
  };
  materials: {};
};

type ActionName = "Plane.018Action" | "Plane.019Action" | "Pillow 01Action";
type GLTFActions = Record<ActionName, THREE.AnimationAction>;

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_SRBeforeObjF(
  props: JSX.IntrinsicElements["group"]
) {
  const group = useRef<THREE.Group>();
  const { nodes, materials, animations } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_SRBeforeObj.glb"
  ) as GLTFResult;
  const { actions } = useAnimations(animations, group);

  const SRBeforeObjD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_SRBeforeObjD.avif"
  );
  SRBeforeObjD.flipY = false;
  SRBeforeObjD.colorSpace = THREE.SRGBColorSpace;
  SRBeforeObjD.channel = 1;
  const SRBeforeObjMat = new THREE.MeshBasicMaterial({
    map: SRBeforeObjD,
  });

  return (
    <group {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="Plane018"
          geometry={nodes.Plane018.geometry}
          material={SRBeforeObjMat}
          position={[-3.926, 0.042, 4.433]}
          rotation={[-0.679, -0.344, -0.11]}
        />
        <mesh
          name="Plane019"
          geometry={nodes.Plane019.geometry}
          material={SRBeforeObjMat}
          position={[-4.287, 0.118, 4.429]}
          rotation={[-1.405, 0.135, 0.338]}
          scale={-1}
        />
        <mesh
          name="Pillow_01"
          geometry={nodes.Pillow_01.geometry}
          material={SRBeforeObjMat}
          position={[-3.535, 0.757, 3.871]}
          rotation={[2.551, -1.174, 2.635]}
        />
      </group>
    </group>
  );
}
export const WTFN25_SPIN5_SRBeforeObj = () => {
  return <WTFN25_SPIN5_SRBeforeObjF></WTFN25_SPIN5_SRBeforeObjF>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_SRBeforeObj.glb");
