/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_SRSKBefore.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useAnimations, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Large_Ezi_Carry_Box_003: THREE.Mesh;
    Large_Ezi_Carry_Box_Lid_1001: THREE.Mesh;
    Large_Ezi_Carry_Box_Lid_2001: THREE.Mesh;
    Large_Ezi_Carry_Box_004: THREE.Mesh;
    Large_Ezi_Carry_Box_Lid_1002: THREE.Mesh;
    Large_Ezi_Carry_Box_Lid_2002: THREE.Mesh;
  };
  materials: {};
};

type ActionName =
  | "Large Ezi Carry Box Lid 1.001Action"
  | "Large Ezi Carry Box Lid 2.001Action"
  | "Large Ezi Carry Box Lid 1.002Action"
  | "Large Ezi Carry Box Lid 2.002Action";
type GLTFActions = Record<ActionName, THREE.AnimationAction>;

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_SRSKBoxBeforeBackF(
  props: JSX.IntrinsicElements["group"]
) {
  const group = useRef<THREE.Group>();
  const { nodes, materials, animations } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_SRSKBefore.glb"
  ) as GLTFResult;
  const { actions } = useAnimations(animations, group);

  const SRSKBeforeD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_SRSKBeforeD.avif"
  );
  SRSKBeforeD.flipY = false;
  SRSKBeforeD.colorSpace = THREE.SRGBColorSpace;
  SRSKBeforeD.channel = 1;

  const SRSKBeforeMat = new THREE.MeshBasicMaterial({
    map: SRSKBeforeD,
  });

  return (
    <group {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="Large_Ezi_Carry_Box_004"
          geometry={nodes.Large_Ezi_Carry_Box_004.geometry}
          material={SRSKBeforeMat}
          position={[-3.479, 0, 5.832]}
          rotation={[0, 0.241, 0]}
        />
        <mesh
          name="Large_Ezi_Carry_Box_Lid_1002"
          geometry={nodes.Large_Ezi_Carry_Box_Lid_1002.geometry}
          material={SRSKBeforeMat}
          position={[-3.157, 0.335, 5.944]}
          rotation={[1.81, -0.058, -0.234]}
        />
        <mesh
          name="Large_Ezi_Carry_Box_Lid_2002"
          geometry={nodes.Large_Ezi_Carry_Box_Lid_2002.geometry}
          material={SRSKBeforeMat}
          position={[-3.246, 0.335, 5.583]}
          rotation={[-2.016, -0.106, 0.217]}
        />
      </group>
    </group>
  );
}
export const WTFN25_SPIN5_SRSKBoxBeforeBack = () => {
  return <WTFN25_SPIN5_SRSKBoxBeforeBackF></WTFN25_SPIN5_SRSKBoxBeforeBackF>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_SRSKBefore.glb");
