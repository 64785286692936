/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_GardenBackPlane.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Plane007: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_GardenBackPlaneF(
  props: JSX.IntrinsicElements["group"]
) {
  const { nodes, materials } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_GardenBackPlane.glb"
  ) as GLTFResult;

  const GardenBackPlane = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_GardenBackPlane.avif"
  );
  GardenBackPlane.flipY = false;
  GardenBackPlane.colorSpace = THREE.SRGBColorSpace;

  const GardenBackPlaneMat = new THREE.MeshBasicMaterial({
    map: GardenBackPlane,
    transparent: true,
  });

  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Plane007.geometry} material={GardenBackPlaneMat} />
    </group>
  );
}
export const WTFN25_SPIN5_GardenBackPlane = () => {
  return <WTFN25_SPIN5_GardenBackPlaneF></WTFN25_SPIN5_GardenBackPlaneF>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_GardenBackPlane.glb");
