/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_SKBoxesDining.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Medium_Heavy_Duty_Box: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_SKBoxesDiningF(
  props: JSX.IntrinsicElements["group"]
) {
  const { nodes, materials } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_SKBoxesDining.glb"
  ) as GLTFResult;

  const SKBoxesDiningD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_SKBoxesDiningD.avif"
  );
  SKBoxesDiningD.flipY = false;
  SKBoxesDiningD.colorSpace = THREE.SRGBColorSpace;
  SKBoxesDiningD.channel = 1;
  const SKBoxesDiningMat = new THREE.MeshBasicMaterial({
    map: SKBoxesDiningD,
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Medium_Heavy_Duty_Box.geometry}
        material={SKBoxesDiningMat}
        position={[-0.572, 0, -0.74]}
        rotation={[0, 0.438, 0]}
      />
    </group>
  );
}
export const WTFN25_SPIN5_SKBoxesDining = () => {
  return <WTFN25_SPIN5_SKBoxesDiningF></WTFN25_SPIN5_SKBoxesDiningF>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_SKBoxesDining.glb");
