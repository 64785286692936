import createSelectors from "@/stores/createSelectors";
import { createRef, RefObject } from "react";
import { Camera, Group } from "three";
import { create } from "zustand";
import { combine } from "zustand/middleware";

const initialState = {
  isDevMode: false,
  setDevMode: (isDevMode: boolean) => isDevMode,
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  setRef: createRef() as RefObject<Group> | null,
  activeCameraRef: null as RefObject<Camera> | null,
  mainCameraRef: null as RefObject<Camera> | null,
  clear: () => null,
};

const objectsStore = create(
  combine(initialState, (set) => ({
    setDevMode: (isDevMode: boolean) => set(() => ({ isDevMode })),
    setActiveCameraRef: (activeCameraRef: RefObject<Camera>) =>
      set(() => ({ activeCameraRef })),
    setMainCameraRef: (mainCameraRef: RefObject<Camera>) =>
      set(() => ({ mainCameraRef })),
    clear: () => set(() => ({ ...initialState })),
  }))
);

const useDevModeStore = createSelectors(objectsStore);

export default useDevModeStore;
