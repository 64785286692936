/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_FloorWood.glb -t -r public 
*/

import useInteractionsStore from "@/stores/useInteractionsStore";
import { useGLTF, useTexture } from "@react-three/drei";
import { useMemo } from "react";
import * as THREE from "three";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Plane068: THREE.Mesh;
  };
  materials: unknown;
};

export const FLOOR_KEY = "floor";

export function WTFN25_SPIN5_FloorWood({
  // materialIndex = 0,
  ...props
}: THREE.Group) {
  const events = useInteractionsStore.use.events();

  const { nodes } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_FloorWood.glb"
  ) as GLTFResult;

  // Load all textures
  const FEStartD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_FEStartD.avif"
  );
  const FEGumD = useTexture("/textures/WTFN25/SPIN5/WTFN25_SPIN5_FEGumD.avif");
  const FEDorianD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_FEDorianD.avif"
  );
  const FEOakD = useTexture("/textures/WTFN25/SPIN5/WTFN25_SPIN5_FEOakD.avif");

  // Ensure correct settings for textures
  [FEStartD, FEGumD, FEDorianD, FEOakD].forEach((texture) => {
    texture.flipY = false;
    texture.colorSpace = THREE.SRGBColorSpace;
    texture.channel = 1;
  });

  const floorMaterials = useMemo(() => {
    return new Map([
      ["default", FEStartD],
      ["gum", FEGumD],
      ["dorian", FEDorianD],
      ["oak", FEOakD],
    ]);
  }, [FEDorianD, FEGumD, FEOakD, FEStartD]);

  // Dynamically select material based on `materialIndex`
  const selectedTexture = useMemo(() => {
    const event = events.get(FLOOR_KEY);
    if (event?.value) {
      return floorMaterials.get(event.value) ?? floorMaterials.get("default");
    }

    return floorMaterials.get("default"); // Fallback texture
  }, [floorMaterials, events]);

  const FEWoodFloorMat = useMemo(
    () => new THREE.MeshBasicMaterial({ map: selectedTexture }),
    [selectedTexture]
  );

  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Plane068.geometry} material={FEWoodFloorMat} />
    </group>
  );
}

useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_FloorWood.glb");
