import createSelectors from "@/stores/createSelectors";
import * as THREE from "three";
import { create } from "zustand";
import { combine } from "zustand/middleware";

export interface VirtualSceneObject {
  id: string;
  object: THREE.Group;
  ref: React.MutableRefObject<THREE.Group>;
  outline?: boolean;
}

const initialState = {
  virtualObjects: [] as VirtualSceneObject[],
  renderTarget: null,
  outlinesTarget: null,
  objectsTarget: null,
  registerObject: (object: VirtualSceneObject) => object,
  unRegisterObject: (id: string) => id,
  setRenderTarget: (renderTarget: THREE.WebGLRenderTarget<THREE.Texture>) =>
    renderTarget,
  setOutlinesTarget: (renderTarget: THREE.WebGLRenderTarget<THREE.Texture>) =>
    renderTarget,
  setObjectsTarget: (renderTarget: THREE.WebGLRenderTarget<THREE.Texture>) =>
    renderTarget,
  clear: () => null,
};

const objectsStore = create(
  combine(initialState, (set) => ({
    registerObject: (virtualObject: VirtualSceneObject) =>
      set((state) => ({
        virtualObjects: [...state.virtualObjects, virtualObject],
      })),
    unRegisterObject: (id: string) =>
      set((state) => ({
        virtualObjects: state.virtualObjects.filter((obj) => obj.id !== id),
      })),
    setRenderTarget: (renderTarget: THREE.WebGLRenderTarget<THREE.Texture>) =>
      set(() => ({ renderTarget })),
    setOutlinesTarget: (
      outlinesTarget: THREE.WebGLRenderTarget<THREE.Texture>
    ) => set(() => ({ outlinesTarget })),
    setObjectsTarget: (objectsTarget: THREE.WebGLRenderTarget<THREE.Texture>) =>
      set(() => ({ objectsTarget })),
    clear: () =>
      set(() => ({
        virtualObjects: initialState.virtualObjects,
      })),
  }))
);

const useObjectsStore = createSelectors(objectsStore);

export default useObjectsStore;
