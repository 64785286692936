/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_KitchenSpill2.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    puddle1: THREE.Mesh;
    puddle2: THREE.Mesh;
    puddle1001: THREE.Mesh;
    puddle1002: THREE.Mesh;
    puddle1003: THREE.Mesh;
    puddle1004: THREE.Mesh;
    puddle1005: THREE.Mesh;
    puddle1006: THREE.Mesh;
    puddle2001: THREE.Mesh;
    puddle2002: THREE.Mesh;
    puddle2003: THREE.Mesh;
    puddle2004: THREE.Mesh;
  };
  materials: {};
};

type ActionName =
  | "puddle1Action"
  | "puddle2Action"
  | "puddle1.001Action"
  | "puddle1.002Action"
  | "puddle1.003Action"
  | "puddle1.004Action"
  | "puddle1.005Action"
  | "puddle1.006Action"
  | "puddle2.001Action"
  | "puddle2.002Action"
  | "puddle2.003Action"
  | "puddle2.004Action";
type GLTFActions = Record<ActionName, THREE.AnimationAction>;

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_KitchenSpill2F(
  props: JSX.IntrinsicElements["group"]
) {
  const group = useRef<THREE.Group>();
  const { nodes, materials, animations } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_KitchenSpill2.glb"
  ) as GLTFResult;
  const { actions } = useAnimations(animations, group);

  const KitchenSpillMat = new THREE.MeshStandardMaterial({
    color: "red",
    roughness: 0,
    transparent: true,
    opacity: 0.5,
  });

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="puddle1"
          geometry={nodes.puddle1.geometry}
          material={KitchenSpillMat}
          position={[2.32, 0.001, -1.84]}
          rotation={[0, -0.499, 0]}
          scale={0.792}
        />
        <mesh
          name="puddle2"
          geometry={nodes.puddle2.geometry}
          material={KitchenSpillMat}
          position={[2.32, 0.001, -1.84]}
          rotation={[0, -0.499, 0]}
          scale={0.792}
        />
        <mesh
          name="puddle1001"
          geometry={nodes.puddle1001.geometry}
          material={KitchenSpillMat}
          position={[2.32, 0.001, -1.84]}
          rotation={[0, -0.499, 0]}
          scale={0.792}
        />
        <mesh
          name="puddle1002"
          geometry={nodes.puddle1002.geometry}
          material={KitchenSpillMat}
          position={[2.32, 0.001, -1.84]}
          rotation={[0, -0.499, 0]}
          scale={0.792}
        />
        <mesh
          name="puddle1003"
          geometry={nodes.puddle1003.geometry}
          material={KitchenSpillMat}
          position={[2.32, 0.001, -1.84]}
          rotation={[0, -0.499, 0]}
          scale={0.792}
        />
        <mesh
          name="puddle1004"
          geometry={nodes.puddle1004.geometry}
          material={KitchenSpillMat}
          position={[2.32, 0.001, -1.84]}
          rotation={[0, -0.499, 0]}
          scale={0.792}
        />
        <mesh
          name="puddle1005"
          geometry={nodes.puddle1005.geometry}
          material={KitchenSpillMat}
          position={[2.32, 0.001, -1.84]}
          rotation={[0, -0.499, 0]}
          scale={0.792}
        />
        <mesh
          name="puddle1006"
          geometry={nodes.puddle1006.geometry}
          material={KitchenSpillMat}
          position={[2.32, 0.001, -1.84]}
          rotation={[0, -0.499, 0]}
          scale={0.792}
        />
        <mesh
          name="puddle2001"
          geometry={nodes.puddle2001.geometry}
          material={KitchenSpillMat}
          position={[2.32, 0.001, -1.84]}
          rotation={[0, -0.499, 0]}
          scale={0.792}
        />
        <mesh
          name="puddle2002"
          geometry={nodes.puddle2002.geometry}
          material={KitchenSpillMat}
          position={[2.32, 0.001, -1.84]}
          rotation={[0, -0.499, 0]}
          scale={0.792}
        />
        <mesh
          name="puddle2003"
          geometry={nodes.puddle2003.geometry}
          material={KitchenSpillMat}
          position={[2.32, 0.001, -1.84]}
          rotation={[0, -0.499, 0]}
          scale={0.792}
        />
        <mesh
          name="puddle2004"
          geometry={nodes.puddle2004.geometry}
          material={KitchenSpillMat}
          position={[2.32, 0.001, -1.84]}
          rotation={[0, -0.499, 0]}
          scale={0.792}
        />
      </group>
    </group>
  );
}
export const WTFN25_SPIN5_KitchenSpill2 = () => {
  return <WTFN25_SPIN5_KitchenSpill2F></WTFN25_SPIN5_KitchenSpill2F>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_KitchenSpill2.glb");
