import React, { useEffect, useRef, useState } from "react";
import {
  VideoTexture,
  MeshBasicMaterial,
  Mesh,
  Object3D,
  SRGBColorSpace,
} from "three";

export const VideoElementWrapper = ({ videoURL, children }) => {
  const [hasAttachedVideoTexture, setHasAttachedVideoTexture] =
    useState<boolean>(false);

  const videoRef = useRef<HTMLVideoElement>();
  const videoTextureRef = useRef<VideoTexture>();
  const childObjectRef = useRef<Object3D | null>(null);

  /**
   * Attach video texture whenever the child ref is established
   */
  const setChildRef = (node: Object3D | null) => {
    childObjectRef.current = node;
    if (node && !hasAttachedVideoTexture) {
      attachVideoTexture(node);
    }
  };

  /**
   * Attach video texture to child object
   */
  function attachVideoTexture(object: Object3D) {
    // Create video element if it doesn't exist
    if (!videoRef.current) {
      const videoEl = document.createElement("video");
      videoEl.crossOrigin = "anonymous";
      videoEl.loop = true;
      videoEl.muted = true;
      videoEl.autoplay = true;
      videoEl.playsInline = true;
      videoRef.current = videoEl;
    }
    const video = videoRef.current;

    // Assign src (fallback to local video)
    if (!videoURL) {
      console.warn("Video playback defaulting to fallback.");
    }
    video.src = videoURL ?? "/videos/NBHype.mp4";

    // Clean up old texture
    if (videoTextureRef.current) {
      videoTextureRef.current.dispose();
    }

    // Create a new VideoTexture
    const vidTexture = new VideoTexture(video);
    vidTexture.flipY = false;
    vidTexture.colorSpace = SRGBColorSpace;
    vidTexture.channel = 1;
    videoTextureRef.current = vidTexture;

    // Traverse the child and replace the texture
    object.traverse((child) => {
      if ((child as Mesh).isMesh) {
        const mesh = child as Mesh;
        const material = mesh.material;
        const materials = Array.isArray(material) ? material : [material];

        materials.forEach((mat: MeshBasicMaterial) => {
          if (mat.map !== undefined) {
            mat.map = vidTexture;
            mat.needsUpdate = true;
          }
        });
      }
    });

    // Try playing
    video.load();
    video
      .play()
      .then(() => {
        setHasAttachedVideoTexture(true);
      })
      .catch((err) => {
        // NOTE: we are ignoring this abort error because of browser behaviour inconsistencies
        if (err.name === "AbortError") {
          return;
        }

        console.warn("Video playback error:", err);
      });
  }

  // If videoURL changes at runtime, re-attach the texture
  useEffect(() => {
    if (childObjectRef.current && !hasAttachedVideoTexture) {
      attachVideoTexture(childObjectRef.current);
    }
  }, [videoURL]);

  // Pass a ref callback to set the latest ref
  return React.cloneElement(children, { ref: setChildRef });
};
