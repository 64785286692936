import {
  DocumentData,
  Query,
  QueryConstraint,
  collection,
  doc,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../firebase/firebase";
import { collectionData, docData } from "rxfire/firestore";
import { Observable, firstValueFrom, map, of } from "rxjs";
import DataTransformationService from "./data-transformation.service";
import { ExperienceVirtualObject } from "@/models/experience-virtual-object";
import ProductService from "./product.service";
import { Product } from "@/models/product";

function generateId() {
  return doc(collection(db, "experienceVirtualObjects")).id;
}

function getOne(id: string): Observable<ExperienceVirtualObject> {
  if (!id) return of(undefined);
  const docRef = doc(db, `experienceVirtualObjects/${id}`);
  return docData(docRef).pipe(
    map((firestoreDocument) =>
      DataTransformationService.convertTimestampsToIsoString(firestoreDocument)
    )
  );
}

function getAllByExperience(
  tenantId: string,
  brandId: string,
  experienceId: string
): Observable<ExperienceVirtualObject[]> {
  const collectionRef = collection(db, "experienceVirtualObjects");

  const queryConstraint: QueryConstraint[] = [
    where("tenantId", "==", tenantId),
    where("brandId", "==", brandId),
    where("experienceId", "==", experienceId),
    where("deleted", "==", false),
  ];

  const collectionQuery: Query<DocumentData> = query(
    collectionRef,
    ...queryConstraint
  );

  return collectionData(collectionQuery).pipe(
    map((collection) => {
      //convert timestamps to Date
      collection = collection.map((firestoreDocument) =>
        DataTransformationService.convertTimestampsToIsoString(
          firestoreDocument
        )
      );
      return collection;
    })
  );
}

function getAllByExperienceSetScenePosition(
  tenantId: string,
  brandId: string,
  experienceId: string,
  setId: string,
  sceneId: string,
  positionId: string
): Observable<ExperienceVirtualObject[]> {
  const collectionRef = collection(db, "experienceVirtualObjects");

  const queryConstraint: QueryConstraint[] = [
    where("tenantId", "==", tenantId),
    where("brandId", "==", brandId),
    where("experienceId", "==", experienceId),
    where("setId", "==", setId),
    where("sceneId", "==", sceneId),
    where("positionId", "==", positionId),
    where("deleted", "==", false),
  ];

  const collectionQuery: Query<DocumentData> = query(
    collectionRef,
    ...queryConstraint
  );

  return collectionData(collectionQuery).pipe(
    map((collection) => {
      //convert timestamps to Date
      collection = collection.map((firestoreDocument) =>
        DataTransformationService.convertTimestampsToIsoString(
          firestoreDocument
        )
      );
      return collection;
    })
  );
}

/**
 * Removes any experienceVirtualObjects that are tied to the experience and share the same setId, sceneId, and positionId. Then saves the new experienceVirtualObject.
 * @param experienceVirtualObject
 * @param isNew
 * @returns
 */
async function saveOne(
  experienceVirtualObject: ExperienceVirtualObject,
  isNew: boolean
): Promise<any> {
  try {
    // get the product if the experienceVirtualObject has a productId
    const product: Product = experienceVirtualObject.productId
      ? await firstValueFrom(
          ProductService.getOne(experienceVirtualObject.productId)
        )
      : undefined;

    // set the product properties on the experienceVirtualObject
    if (product) {
      experienceVirtualObject.productName = product.productName;
      experienceVirtualObject.productType = product.productType;
      experienceVirtualObject.productDescription = product.productDescription;
      experienceVirtualObject.productImageUrl = product.productImageUrl;
    } else {
      experienceVirtualObject.productName = null;
      experienceVirtualObject.productType = null;
      experienceVirtualObject.productDescription = null;
      experienceVirtualObject.productImageUrl = null;
    }

    // handle user info and edit dates
    if (isNew) {
      experienceVirtualObject.createdDate = serverTimestamp();
      experienceVirtualObject.createdByUserAccountId = auth.currentUser?.uid;
    } else {
      experienceVirtualObject.updatedDate = serverTimestamp();
      experienceVirtualObject.updatedByUserAccountId = auth.currentUser?.uid;
    }

    // set deleted to false if it is undefined
    if (experienceVirtualObject.deleted == undefined)
      experienceVirtualObject.deleted = false;

    // convert dates to firestore timestamps
    experienceVirtualObject =
      DataTransformationService.convertDatesToFirestoreTimestamp(
        experienceVirtualObject
      );

    // Get all experienceVirtualObjects that share the same setId, sceneId, and positionId
    // const experienceVirtualObjectsToDelete: ExperienceVirtualObject[] =
    //   await firstValueFrom(
    //     getAllByExperienceSetScenePosition(
    //       experienceVirtualObject.tenantId,
    //       experienceVirtualObject.brandId,
    //       experienceVirtualObject.experienceId,
    //       experienceVirtualObject.setId,
    //       experienceVirtualObject.sceneId,
    //       experienceVirtualObject.positionId
    //     )
    //   );

    // Delete all experienceVirtualObjects that share the same setId, sceneId, and positionId. This is because we only want one experienceVirtualObject to be tied to the experience's set, scene and position at a time.
    // for (const experienceVirtualObjectToDelete of experienceVirtualObjectsToDelete) {
    //   await deleteOne(experienceVirtualObjectToDelete.id);
    // }

    // save the experienceVirtualObject
    let docRef = doc(
      db,
      `experienceVirtualObjects/${experienceVirtualObject.id}`
    );
    await setDoc(docRef, experienceVirtualObject, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

async function deleteOne(experienceVirtualObjectId: string): Promise<any> {
  try {
    let docRef = doc(
      db,
      `experienceVirtualObjects/${experienceVirtualObjectId}`
    );
    const documentToDelete = {
      deleted: true,
      deletedDate: serverTimestamp(),
      updatedDate: serverTimestamp(),
      updatedByUserAccountId: auth.currentUser.uid,
    };
    await setDoc(docRef, documentToDelete, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

const ExperienceVirtualObjectService = {
  generateId,
  getOne,
  saveOne,
  deleteOne,
  getAllByExperience,
  getAllByExperienceSetScenePosition,
};

export default ExperienceVirtualObjectService;
