varying vec2 vUv;

uniform float uStartPower;
uniform float uCrispPower;
uniform float uCloverPower;
uniform float uAriaPower;
uniform sampler2D uStartTexture;
uniform sampler2D uCrispTexture;
uniform sampler2D uCloverTexture;
uniform sampler2D uAriaTexture;
uniform float uGamma;

void main() {
  vec4 startColour = texture2D(uStartTexture, vUv); // Sample the original texture
  vec4 crispColour = texture2D(uCrispTexture, vUv); // Sample the original texture
  vec4 cloverColour = texture2D(uCloverTexture, vUv); // Sample the original texture
  vec4 ariaColour = texture2D(uAriaTexture, vUv); // Sample the original texture

  vec4 wallColour = startColour * uStartPower + 
             crispColour * uCrispPower + 
             cloverColour * uCloverPower + 
             ariaColour * uAriaPower;

  // gl_FragColor = wallColour;
  gl_FragColor = vec4(pow(wallColour.rgb, vec3(uGamma)), wallColour.a);
}