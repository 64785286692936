/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_VanBase.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Body_interior_Body_Interior_0: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_VanBaseF(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_VanBase.glb"
  ) as GLTFResult;

  const VanBaseD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_VanBaseD.avif"
  );
  VanBaseD.flipY = false;
  VanBaseD.colorSpace = THREE.SRGBColorSpace;
  VanBaseD.channel = 2;
  const VanBaseMat = new THREE.MeshBasicMaterial({
    map: VanBaseD,
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Body_interior_Body_Interior_0.geometry}
        material={VanBaseMat}
        position={[-23.033, 0.034, 4.717]}
        rotation={[0.28, -1.382, 0.286]}
        scale={0.01}
      />
    </group>
  );
}
export const WTFN25_SPIN5_VanBase = () => {
  return <WTFN25_SPIN5_VanBaseF></WTFN25_SPIN5_VanBaseF>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_VanBase.glb");
