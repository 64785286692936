/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_PaintRoller.glb -t -r public 
*/

import { WALL_KEY } from "@/components/sets/spin5/models/WTFN25_SPIN5_LoungeWallsChange";
import useInteractionsStore from "@/stores/useInteractionsStore";
import { useAnimations, useGLTF, useTexture } from "@react-three/drei";
import { useEffect, useRef } from "react";
import * as THREE from "three";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Cylinder007: THREE.Mesh;
    Cylinder007_1: THREE.Mesh;
    Cylinder007_2: THREE.Mesh;
  };
  materials: {};
};

type ActionName = "paintrollerAction.001";
type GLTFActions = Record<ActionName, THREE.AnimationAction>;

export function WTFN25_SPIN5_PaintRollerF(
  props: JSX.IntrinsicElements["group"]
) {
  const group = useRef<THREE.Group>();
  const { nodes, animations } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_PaintRoller.glb"
  ) as GLTFResult;
  // const { actions } = useAnimations<GLTFActions>(animations, group); TODO: TEMP TYPESCRIP FIX
  const { actions } = useAnimations(animations, group);

  const PaintRoller = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_PaintRoller.avif"
  );
  PaintRoller.flipY = false;
  PaintRoller.colorSpace = THREE.SRGBColorSpace;

  const PaintRollerMat = new THREE.MeshBasicMaterial({
    map: PaintRoller,
  });

  const PaintRollerSilver = new THREE.MeshStandardMaterial({
    metalness: 1,
    roughness: 0.1,
  });

  const PaintRollerBlack = new THREE.MeshStandardMaterial({
    color: "black",
    roughness: 0.2,
  });

  const events = useInteractionsStore.use.events();

  useEffect(() => {
    const event = events.get(WALL_KEY);
    if (event?.value) {
      // if you want to do something every time...just do it

      // play animation?

      // if you want something different per value, use a switch
      const action = event.value;
      switch (action) {
        case "aria":
          // play animation?
          break;
        case "clover":
          break;
        case "crisp":
          break;
        default:
          break;
      }
    }
  }, [events]);

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="paintroller" position={[-4.689, 0, -0.673]}>
          <mesh
            name="Cylinder007"
            geometry={nodes.Cylinder007.geometry}
            material={PaintRollerMat}
          />
          <mesh
            name="Cylinder007_1"
            geometry={nodes.Cylinder007_1.geometry}
            material={PaintRollerBlack}
          />
          <mesh
            name="Cylinder007_2"
            geometry={nodes.Cylinder007_2.geometry}
            material={PaintRollerSilver}
          />
        </group>
      </group>
    </group>
  );
}

export const WTFN25_SPIN5_PaintRoller = () => {
  return <WTFN25_SPIN5_PaintRollerF></WTFN25_SPIN5_PaintRollerF>;
};

useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_PaintRoller.glb");
