/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_GardenSetting.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Cylinder001: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_GardenSettingF(
  props: JSX.IntrinsicElements["group"]
) {
  const { nodes } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_GardenSetting.glb"
  ) as GLTFResult;

  const GardenSettingD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_GardenSettingD.avif"
  );
  GardenSettingD.flipY = false;
  GardenSettingD.colorSpace = THREE.SRGBColorSpace;
  GardenSettingD.channel = 1;
  const GardenSettingMat = new THREE.MeshBasicMaterial({
    map: GardenSettingD,
    side: THREE.DoubleSide,
  });

  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Cylinder001.geometry} material={GardenSettingMat} />
    </group>
  );
}
export const WTFN25_SPIN5_GardenSetting = () => {
  return <WTFN25_SPIN5_GardenSettingF></WTFN25_SPIN5_GardenSettingF>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_GardenSetting.glb");
