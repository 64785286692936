/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_SKBoxesGarageEnd.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useAnimations, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Large_Ezi_Carry_Box_: THREE.Mesh;
    Archive_Carton_Box: THREE.Mesh;
  };
  materials: object;
};

type ActionName = "Large Ezi Carry Box " | "Archive Carton Box";
type GLTFActions = Record<ActionName, THREE.AnimationAction>;

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_SKBoxesGarageEndF(
  props: JSX.IntrinsicElements["group"]
) {
  const group = useRef<THREE.Group>();
  const { nodes, materials, animations } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_SKBoxesGarageEnd.glb"
  ) as GLTFResult;
  const { actions } = useAnimations(animations, group);

  const SKBoxesGarageEndD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_SKBoxesGarageEndD.avif"
  );
  SKBoxesGarageEndD.flipY = false;
  SKBoxesGarageEndD.colorSpace = THREE.SRGBColorSpace;
  SKBoxesGarageEndD.channel = 1;
  const SKBoxesGarageEndMat = new THREE.MeshBasicMaterial({
    map: SKBoxesGarageEndD,
  });

  const onClick = () => {
    Object.values(actions).forEach((action) => {
      if (action) {
        action.reset();
        action.setLoop(THREE.LoopOnce, 1);
        action.clampWhenFinished = true;
        action.play();
      }
    });
  };

  return (
    <group {...props} dispose={null} onClick={onClick} ref={group}>
      <group name="Scene">
        <mesh
          name="Large_Ezi_Carry_Box_"
          geometry={nodes.Large_Ezi_Carry_Box_.geometry}
          material={SKBoxesGarageEndMat}
          position={[-15.34, 0, 4.647]}
          rotation={[0, -1.139, 0]}
        />
        <mesh
          name="Archive_Carton_Box"
          geometry={nodes.Archive_Carton_Box.geometry}
          material={SKBoxesGarageEndMat}
          position={[-15.355, 0.335, 4.634]}
        />
      </group>
    </group>
  );
}
export const WTFN25_SPIN5_SKBoxesGarageEnd = () => {
  return <WTFN25_SPIN5_SKBoxesGarageEndF></WTFN25_SPIN5_SKBoxesGarageEndF>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_SKBoxesGarageEnd.glb");
