import TestCanvas from "@/components/3d/TestCanvas";
import ModelsViewer from "@/pages/DevPage/models/Viewer";
import { StatsGl } from "@react-three/drei";

export default function ModelsPage() {
  return (
    <TestCanvas>
      <ModelsViewer />
      <StatsGl className="stats fixed" />
    </TestCanvas>
  );
}
