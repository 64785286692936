/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_KmartDahlia.glb -t -r public 
*/

import * as THREE from "three";

import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    DahliaRug001: THREE.Mesh;
  };
  materials: {};
};

export function WTFN25_SPIN5_KmartDahliaLF(
  props: JSX.IntrinsicElements["group"]
) {
  const { nodes } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_KmartDahliaL.glb"
  ) as GLTFResult;

  const KmartDahliaD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_KmartDahliaLD.avif"
  );
  KmartDahliaD.flipY = false;
  KmartDahliaD.colorSpace = THREE.SRGBColorSpace;
  KmartDahliaD.channel = 1;
  const KmartDahliaMat = new THREE.MeshBasicMaterial({
    map: KmartDahliaD,
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.DahliaRug001.geometry}
        material={KmartDahliaMat}
        position={[-5.566, 0, -4.125]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
    </group>
  );
}

export const WTFN25_SPIN5_KmartDahliaL = () => {
  return <WTFN25_SPIN5_KmartDahliaLF></WTFN25_SPIN5_KmartDahliaLF>;
};

useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_KmartDahliaL.glb");
