import useDevMode from "@/components/hooks/useDevMode";
import { useExperienceContext } from "@/context/experience.context";
import useScenesStore from "@/stores/useScenesStore";
import { useThree } from "@react-three/fiber";
import { useEffect } from "react";

export default function useStoreBridge() {
  const { camera } = useThree();
  const { isDesktop } = useDevMode();
  const goToSceneIdZUS = useScenesStore.use.goToSceneId();
  const currentSceneIdZUS = useScenesStore.use.currentSceneId();
  const { currentScene: currentSceneCTX } = useExperienceContext();

  // setCurrentSceneCTX;

  useEffect(() => {
    if (!currentSceneCTX) return;

    if (currentSceneIdZUS !== currentSceneCTX.id) {
      goToSceneIdZUS(currentSceneCTX.id);
    }

    if (isDesktop)
      camera.lookAt(
        currentSceneCTX.xTargetPosition,
        currentSceneCTX.yTargetPosition,
        currentSceneCTX.zTargetPosition
      );
  }, [currentSceneCTX, isDesktop, camera, currentSceneIdZUS, goToSceneIdZUS]);

  return null;
}
