/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_SpareBedroomDecor.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";
type GLTFResult = GLTF & {
  nodes: {
    OsloBedsideTable: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_SpareBedroomDecorF(
  props: JSX.IntrinsicElements["group"]
) {
  const { nodes, materials } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_SpareBedroomDecor.glb"
  ) as GLTFResult;

  const SpareBedroomDecorD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_SpareBedroomDecorD.avif"
  );
  SpareBedroomDecorD.flipY = false;
  SpareBedroomDecorD.colorSpace = THREE.SRGBColorSpace;
  SpareBedroomDecorD.channel = 1;
  const SpareBedroomDecorMat = new THREE.MeshBasicMaterial({
    map: SpareBedroomDecorD,
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.OsloBedsideTable.geometry}
        material={SpareBedroomDecorMat}
        position={[-1.874, 0, 3.039]}
        rotation={[0, -Math.PI / 2, 0]}
      />
    </group>
  );
}

export const WTFN25_SPIN5_SpareBedroomDecor = () => {
  return <WTFN25_SPIN5_SpareBedroomDecorF></WTFN25_SPIN5_SpareBedroomDecorF>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_SpareBedroomDecor.glb");
