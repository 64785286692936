/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_FESampleGum.glb -t -r public 
*/

import { FLOOR_KEY } from "@/components/sets/spin5/models/scenes/lounge/setObjects/WTFN25_SPIN5_FloorWood";
import useInteractionsStore from "@/stores/useInteractionsStore";
import { useGLTF, useTexture } from "@react-three/drei";
import * as THREE from "three";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    FloorSample1001: THREE.Mesh;
    SampleStand1001: THREE.Mesh;
  };
  materials: object;
};

export function WTFN25_SPIN5_FESampleGum({ ...props }: THREE.Group) {
  const addEvent = useInteractionsStore.use.addEvent();

  const { nodes } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_FESampleGum.glb"
  ) as GLTFResult;

  const FESamplesD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_FESamplesD.avif"
  );
  FESamplesD.flipY = false;
  FESamplesD.colorSpace = THREE.SRGBColorSpace;
  FESamplesD.channel = 1;
  const FESamplesMat = new THREE.MeshBasicMaterial({
    map: FESamplesD,
    side: THREE.DoubleSide,
  });

  return (
    <group
      {...props}
      onClick={() => {
        addEvent(FLOOR_KEY, { value: "gum" });
      }}
      dispose={null}
    >
      <mesh
        geometry={nodes.FloorSample1001.geometry}
        material={FESamplesMat}
        position={[-4.692, 0.767, -1.74]}
        rotation={[0, 0.218, 1.204]}
      />
      <mesh
        geometry={nodes.SampleStand1001.geometry}
        material={FESamplesMat}
        position={[-4.699, 0.77, -1.738]}
        rotation={[0, 0.218, 0]}
      />
    </group>
  );
}

useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_FESampleGum.glb");
