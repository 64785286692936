import { useExperienceContext } from "@/context/experience.context";
import { SceneObject } from "@/models/scene";
import useObjectsStore from "@/stores/useObjectsStore";
import useSegmentStore, { SegementState } from "@/stores/useSegmentStore";
import { GroupProps } from "@react-three/fiber";
import { Select } from "@react-three/postprocessing";
import { forwardRef, useCallback, useEffect, useMemo, useRef } from "react";
import { Group } from "three";

interface VirtualObjectProps extends GroupProps, SceneObject {}

/**
 * A component that renders a virtual 3D object in a scene with optional highlighting and interaction capabilities.
 *
 * @component
 * @param {Object} props - The component props
 * @param {React.ReactNode} props.children - The child elements to render within the virtual object
 * @param {boolean} [props.highlight=false] - Whether to highlight/outline the object
 * @param {string|null} [props.experienceVirtualObjectId=null] - ID for experience virtual object interaction
 * @param {React.Ref<Group>} ref - Forwarded ref to access the outer group element
 *
 * @remarks
 * This component:
 * - Registers/unregisters itself with the objects store
 * - Handles object highlighting based on segment state
 * - Manages click interactions for experience virtual objects
 * - Uses React-Three-Fiber's Group component for 3D object grouping
 *
 * @example
 * ```tsx
 * <VirtualObject highlight={true} experienceVirtualObjectId="obj-1">
 *   <mesh>
 *     <boxGeometry />
 *     <meshStandardMaterial />
 *   </mesh>
 * </VirtualObject>
 * ```
 */
const VirtualObject = forwardRef<Group, VirtualObjectProps>(
  (
    {
      children,
      highlight = false,
      experienceVirtualObjectId: evoId = null,
      ...props
    },
    ref
  ) => {
    const registerObject = useObjectsStore.use.registerObject();
    const unRegisterObject = useObjectsStore.use.unRegisterObject();
    const segmentState = useSegmentStore.use.segmentState();
    const objectRef = useRef<Group>(null);
    const experience = useExperienceContext();

    const evoClick = useCallback(() => {
      experience?.handleExperienceVirtualObjectTap?.(evoId);
    }, [experience, evoId]);

    useEffect(() => {
      if (!objectRef?.current) return;
      if (!registerObject) return;
      const id = objectRef.current.uuid;
      registerObject({
        id,
        object: objectRef.current,
        ref: objectRef,
        outline: highlight,
      });

      return () => {
        unRegisterObject(id);
      };
    }, [highlight, registerObject, unRegisterObject]);

    const enableOutline = useMemo(
      () => highlight && segmentState == SegementState.ACTIVE,
      [highlight, segmentState]
    );

    const clickProps = useMemo(
      () => (evoId ? { onClick: evoClick } : {}),
      [evoClick, evoId]
    );

    return (
      <Select enabled={enableOutline}>
        <group ref={ref} {...clickProps} {...props}>
          <group ref={objectRef}>{children}</group>
        </group>
      </Select>
    );
  }
);

VirtualObject.displayName = "VirtualObject";

export default VirtualObject;
