import { Scene } from "@/models/scene";
import { VirtualObject } from "@/models/virtual-object";

const generateVirtualObjectOptions = (
  sceneId: string,
  positionId: string
): VirtualObject[] => {
  const virtualObjs = [
    {
      id: `${sceneId}-${positionId}-1`,
      virtualObjectName: "NBProductFF880",
      virtualObjectComponentName: "NBProductFF880",
      positionId: positionId,
      sceneId: sceneId,
      userFriendlyName: "Fresh Foam X 880v14",
      thumbnailPath: "https://picsum.photos/200",
    },
    {
      id: `${sceneId}-${positionId}-2`,
      virtualObjectName: "NBProductFFM",
      virtualObjectComponentName: "NBProductFFM",
      positionId: positionId,
      sceneId: sceneId,
      userFriendlyName: "Fresh Foam X More v5",
      thumbnailPath: "https://picsum.photos/200",
    },
    {
      id: `${sceneId}-${positionId}-3`,
      virtualObjectName: "NBProductFFB",
      virtualObjectComponentName: "NBProductFFB",
      positionId: positionId,
      sceneId: sceneId,
      userFriendlyName: "Fresh Foam X Balos",
      thumbnailPath: "https://picsum.photos/200",
    },
    {
      id: `${sceneId}-${positionId}-4`,
      virtualObjectName: "NBProductFCR",
      virtualObjectComponentName: "NBProductFCR",
      positionId: positionId,
      sceneId: sceneId,
      userFriendlyName: "FuelCell Rebel v4",
      thumbnailPath: "https://picsum.photos/200",
    },
    {
      id: `${sceneId}-${positionId}-5`,
      virtualObjectName: "NBProductFCSCT",
      virtualObjectComponentName: "NBProductFCSCT",
      positionId: positionId,
      sceneId: sceneId,
      userFriendlyName: "FuelCell SuperComp Trainer",
      thumbnailPath: "https://picsum.photos/200",
    },
    {
      id: `${sceneId}-${positionId}-6`,
      virtualObjectName: "NBProductFCSCE",
      virtualObjectComponentName: "NBProductFCSCE",
      positionId: positionId,
      sceneId: sceneId,
      userFriendlyName: "FuelCell SuperComp Elite v4",
      thumbnailPath: "https://picsum.photos/200",
    },
    {
      id: `${sceneId}-${positionId}-7`,
      virtualObjectName: "NBProductNYCM",
      virtualObjectComponentName: "NBProductNYCM",
      positionId: positionId,
      sceneId: sceneId,
    },
  ];

  virtualObjs.forEach((obj) => {
    if (!obj.userFriendlyName) {
      obj.userFriendlyName = obj.virtualObjectName;
    }

    if (obj.thumbnailPath) {
      obj.thumbnailPath = `/thumbnails/${obj.virtualObjectName}.webp`;
    }
  });

  return virtualObjs;
};

export const nbScenes: Scene[] = [
  {
    id: "1",
    sceneName: "Host Intro",
    objectPositions: [],
    videoPositions: [{ id: "1", positionName: "Wall TV", selected: null }],
    xTargetPosition: -2.3,
    yTargetPosition: 1.2,
    zTargetPosition: 4.1,
    xCameraPosition: -0.35,
    yCameraPosition: 1.6,
    zCameraPosition: 4.9,
  },
  {
    id: "2",
    sceneName: "Hero Shoe 1",
    objectPositions: [],
    xTargetPosition: 0,
    yTargetPosition: 1.65,
    zTargetPosition: 1.5,
    xCameraPosition: 1.2,
    yCameraPosition: 1.35,
    zCameraPosition: 4,
  },
  {
    id: "3",
    objectPositions: [],
    sceneName: "Hero Shoe 2",
    xTargetPosition: 0,
    yTargetPosition: 1.65,
    zTargetPosition: 1.5,
    xCameraPosition: -2.2,
    yCameraPosition: 1.35,
    zCameraPosition: 1.1,
  },
  {
    id: "4",
    objectPositions: [
      {
        id: "1",
        positionName: "1",
        selected: "1",
        virtualObjects: generateVirtualObjectOptions("4", "1"),
        xPosition: 1.17,
        yPosition: 0.9,
        zPosition: -1.65,
      },
      {
        id: "2",
        positionName: "2",
        selected: "2",
        virtualObjects: generateVirtualObjectOptions("4", "2"),
        xPosition: 1.17,
        yPosition: 0.9,
        zPosition: -1.05,
      },
      {
        id: "3",
        positionName: "3",
        selected: "3",
        virtualObjects: generateVirtualObjectOptions("4", "3"),
        xPosition: 1.17,
        yPosition: 0.7,
        zPosition: -0.9,
      },
      {
        id: "4",
        positionName: "4",
        selected: "4",
        virtualObjects: generateVirtualObjectOptions("4", "4"),
        xPosition: 1.17,
        yPosition: 0.5,
        zPosition: -0.95,
      },
      {
        id: "5",
        positionName: "5",
        selected: "5",
        virtualObjects: generateVirtualObjectOptions("4", "5"),
        xPosition: 1.17,
        yPosition: 0.5,
        zPosition: -1.75,
      },
      {
        id: "6",
        positionName: "6",
        selected: "6",
        virtualObjects: generateVirtualObjectOptions("4", "6"),
        xPosition: 1.17,
        yPosition: 0.7,
        zPosition: -1.8,
      },
    ],
    sceneName: "Shoe Rack",
    xTargetPosition: 2.2,
    yTargetPosition: 1.65,
    zTargetPosition: -2.65,
    xCameraPosition: 0.8,
    yCameraPosition: 1.5,
    zCameraPosition: -2.3,
  },
  {
    id: "5",
    objectPositions: [],
    sceneName: "Hype Real",
    xTargetPosition: -2,
    yTargetPosition: 1.65,
    zTargetPosition: -5.5,
    xCameraPosition: 0.1,
    yCameraPosition: 1.4,
    zCameraPosition: -5.1,
  },

  {
    id: "6",
    objectPositions: [],
    sceneName: "Courtyard",
    xTargetPosition: -1.5,
    yTargetPosition: 0.7,
    zTargetPosition: -12,
    xCameraPosition: 0.3,
    yCameraPosition: 1.65,
    zCameraPosition: -7.5,
  },
];
