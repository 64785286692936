/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_BDVac2.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Outer: THREE.Mesh;
    Tube: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_BDVac2F(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_BDVac2.glb"
  ) as GLTFResult;

  const BDVac2D = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_BDVac2D.avif"
  );
  BDVac2D.flipY = false;
  BDVac2D.colorSpace = THREE.SRGBColorSpace;
  BDVac2D.channel = 1;
  const BDVac2Mat = new THREE.MeshBasicMaterial({
    map: BDVac2D,
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Outer.geometry}
        material={BDVac2Mat}
        position={[3.258, 0.9, -4.059]}
        rotation={[Math.PI, -1.382, Math.PI]}
        scale={0.449}
      />
      <mesh
        geometry={nodes.Tube.geometry}
        material={BDVac2Mat}
        position={[3.258, 0.9, -4.059]}
        rotation={[Math.PI, -1.382, Math.PI]}
        scale={0.449}
      />
    </group>
  );
}
export const WTFN25_SPIN5_BDVac2 = () => {
  return <WTFN25_SPIN5_BDVac2F></WTFN25_SPIN5_BDVac2F>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_BDVac2.glb");
