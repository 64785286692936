/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_TaubmansClover.glb -t -r public 
*/

import { WALL_KEY } from "@/components/sets/spin5/models/WTFN25_SPIN5_LoungeWallsChange";
import { FEATURE_KEY } from "@/components/sets/spin5/models/WTFN25_SPIN5_LoungeWallsChange/WTFN25_SPIN5_LoungeWallFeature";
import useInteractionsStore from "@/stores/useInteractionsStore";
import { useGLTF, useTexture } from "@react-three/drei";
// import React from "react";
import * as THREE from "three";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    PaintTinClover: THREE.Mesh;
  };
  materials: object;
};

// type ContextType = Record<
//   string,
//   React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
// >;

export function WTFN25_SPIN5_TaubmansCloverF(
  props: JSX.IntrinsicElements["group"]
) {
  const addEvent = useInteractionsStore.use.addEvent();
  const { nodes } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_TaubmansClover.glb"
  ) as GLTFResult;

  const PaintLabel = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_PaintLabel.avif"
  );
  PaintLabel.flipY = false;
  PaintLabel.colorSpace = THREE.SRGBColorSpace;

  const PaintLabelMat = new THREE.MeshStandardMaterial({
    map: PaintLabel,
    roughness: 0.5,
  });

  return (
    <group
      {...props}
      dispose={null}
      onClick={() => {
        // onMaterialChange(2);
        addEvent(WALL_KEY, { value: "clover" });
        addEvent(FEATURE_KEY, { value: "clover" });
      }}
    >
      <mesh
        geometry={nodes.PaintTinClover.geometry}
        material={PaintLabelMat}
        position={[-4.844, 0.758, -1.115]}
        rotation={[-Math.PI, 1.331, -Math.PI]}
        scale={1.461}
      />
    </group>
  );
}

export const WTFN25_SPIN5_TaubmansClover = () => {
  return <WTFN25_SPIN5_TaubmansCloverF></WTFN25_SPIN5_TaubmansCloverF>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_TaubmansClover.glb");
