/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_SpareDownLights.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Cylinder004: THREE.Mesh;
    Cylinder004_1: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_SpareDownLightsF(
  props: JSX.IntrinsicElements["group"]
) {
  const { nodes } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_SpareDownLights.glb"
  ) as GLTFResult;

  const DownLightBezelMat = new THREE.MeshStandardMaterial({
    color: "#ccb19c",
    roughness: 0.6,
  });

  const DownLighEmissiveMat = new THREE.MeshBasicMaterial({
    color: "white",
  });

  return (
    <group {...props} dispose={null}>
      <group position={[-3.237, 2.694, 3.145]}>
        <mesh
          geometry={nodes.Cylinder004.geometry}
          material={DownLighEmissiveMat}
        />
        <mesh
          geometry={nodes.Cylinder004_1.geometry}
          material={DownLightBezelMat}
        />
      </group>
    </group>
  );
}
export const WTFN25_SPIN5_SpareDownLights = () => {
  return <WTFN25_SPIN5_SpareDownLightsF></WTFN25_SPIN5_SpareDownLightsF>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_SpareDownLights.glb");
