/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/NBVideoPlane.glb -t -r public 
*/

import AlphaVideoMaterial from "@/components/3d/AlphaVideoMaterial";
import { useVideoContext } from "@/pages/Viewer/viewer/context/VideoContext";
import { useGLTF } from "@react-three/drei";
import { useEffect } from "react";
import * as THREE from "three";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Plane005: THREE.Mesh;
  };
  materials: object;
};

export function WTFN25_SPIN5_VideoPlaneF(
  props: JSX.IntrinsicElements["group"]
) {
  const { nodes } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_VideoPlane.glb"
  ) as GLTFResult;
  const { video, videoTexture, hasExperienceLoaded, hasPlayedHostVideo } =
    useVideoContext();

  useEffect(() => {
    if (hasExperienceLoaded && video && !hasPlayedHostVideo) {
      video.play().catch((e) => {
        console.log("error with video playback:", e);
      });
      if (videoTexture) {
        videoTexture.flipY = false;
        videoTexture.colorSpace = THREE.SRGBColorSpace;
      }
    }
  }, [hasExperienceLoaded, hasPlayedHostVideo, video, videoTexture]);

  // const WTFN25_SPIN5_HostMaskA = useTexture(
  //   "/videos/WTFN25_SPIN5_HostMaskA.avif"
  // );
  // WTFN25_SPIN5_HostMaskA.flipY = false;
  // WTFN25_SPIN5_HostMaskA.colorSpace = THREE.SRGBColorSpace;

  return (
    hasExperienceLoaded &&
    !hasPlayedHostVideo && (
      <group {...props} dispose={null}>
        {/* <Plane args={[0.2, 0.2]} position={[0, 0, 0]}>
          <meshBasicMaterial color="red" transparent wireframe />
        </Plane> */}
        <mesh
          geometry={nodes.Plane005.geometry}
          position={[-10.86, 0, 0.434]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={[0.576, 1.922, 0.961]}
        >
          {/* <meshBasicMaterial
            // map={videoTexture}
            color={0xff0000}
            transparent
            // alphaMap={WTFN25_SPIN5_HostMaskA}
          >
          </meshBasicMaterial> */}
          <AlphaVideoMaterial videoTexture={videoTexture} gamma={1.8} />
        </mesh>
      </group>
    )
  );
}
export const WTFN25_SPIN5_VideoPlane = () => {
  return <WTFN25_SPIN5_VideoPlaneF></WTFN25_SPIN5_VideoPlaneF>;
};
useGLTF.preload("/glb/WTFN25_SPIN5_VideoPlane.glb");
