import { Button } from "@/components/ui/button";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerPortal,
} from "@/components/ui/drawer";
import { useAuth } from "@/context/auth.context";
import { useExperienceContext } from "@/context/experience.context";
import { Product } from "@/models/product";
import { ProductVariant } from "@/models/product-variant";
import { Store } from "@/models/store";
import GoogleAnalyticsService from "@/services/googleAnalytics.service";
import ProductVariantService from "@/services/product-variant.service";
import ProductService from "@/services/product.service";
import StoreService from "@/services/store.service";
import UserAccountActionService from "@/services/userAccountAction.service";
import { DialogTitle } from "@radix-ui/react-dialog";
import { FC, useEffect, useState } from "react";
import { HiOutlineX } from "react-icons/hi";
import { firstValueFrom } from "rxjs";
import { ExperienceVirtualObject } from "@/models/experience-virtual-object";
import ProductInformation from "./ProductInformation";
import { ScrollArea } from "../../../components/ui/scroll-area";

const ProductDrawer: FC<{
  isOpen: boolean;
  setIsOpen: (status: boolean) => void;
  experienceVirtualObject: ExperienceVirtualObject;
}> = ({ isOpen, setIsOpen, experienceVirtualObject }) => {
  // REFERENCE:
  const [snap, setSnap] = useState<number | string | null>(0.6);

  const { authUser } = useAuth();
  const { experience, placement, cartProductVariants, listProductVariants } =
    useExperienceContext();

  const [product, setProduct] = useState<Product>();
  const [productVariants, setProductVariants] = useState<ProductVariant[]>();
  const [store, setStore] = useState<Store>();
  const [loggedAnalytics, setLoggedAnaltyics] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      if (isOpen && experienceVirtualObject?.productId) {
        // Get product
        const relatedProduct: Product = await firstValueFrom(
          ProductService.getOne(experienceVirtualObject.productId)
        );

        const relatedStore: Store = await firstValueFrom(
          StoreService.getOne(relatedProduct.storeId)
        );

        const relatedProductVariants: ProductVariant[] = await firstValueFrom(
          ProductVariantService.getAllByProduct(
            relatedProduct.tenantId,
            relatedProduct.brandId,
            experienceVirtualObject.productId
          )
        );

        setProduct(relatedProduct);
        setProductVariants(relatedProductVariants);
        setStore(relatedStore);
      } else {
        setProduct(null);
        setProductVariants(null);
      }

      return;
    };

    getData().catch((err) => {
      alert(err);
      throw err;
    });
  }, [isOpen, experienceVirtualObject, cartProductVariants]);

  /**
   * Log view_item event in analytics
   */
  useEffect(() => {
    const sendData = async () => {
      if (
        isOpen &&
        experience &&
        placement &&
        authUser &&
        store &&
        product &&
        productVariants &&
        !loggedAnalytics
      ) {
        GoogleAnalyticsService.logItemInteraction(
          "view_item",
          experience,
          placement.id,
          store,
          experienceVirtualObject,
          product,
          productVariants[0]
        );
        await UserAccountActionService.logItemInteraction(
          "view_item",
          experience,
          placement.id,
          store,
          experienceVirtualObject,
          product,
          productVariants[0],
          authUser
        );
        setLoggedAnaltyics(true);
      }
      return;
    };

    sendData().catch((err) => {
      alert(err);
      throw err;
    });

    if (!isOpen && loggedAnalytics) {
      setLoggedAnaltyics(false);
    }
  }, [
    isOpen,
    experience,
    placement,
    authUser,
    product,
    store,
    productVariants,
    loggedAnalytics,
  ]);

  return (
    <>
      <Drawer
        open={isOpen}
        onOpenChange={setIsOpen}
        snapPoints={[0.6, 1]}
        activeSnapPoint={snap}
        setActiveSnapPoint={setSnap}
        handleOnly={false}
      >
        <DrawerPortal>
          <DrawerContent className="fixed flex flex-col bg-white border border-gray-200 border-b-none rounded-t-[10px] bottom-0 left-0 right-0 h-full max-h-[95%] mx-[-1px]">
            {/* Close Modal Button */}
            <div className="absolute -top-3 right-3 z-10">
              <Button
                size="icon"
                onClick={() => setIsOpen(false)}
                variant="default"
              >
                <HiOutlineX className="w-6 h-6" />
              </Button>
            </div>

            <DrawerHeader>
              <DialogTitle className="text-primary">
                {product?.productName}
              </DialogTitle>
              <DrawerDescription>
                {product?.productDescription}
              </DrawerDescription>
            </DrawerHeader>
            <ScrollArea className="h-screen">
              {product && (
                <>
                  <ProductInformation
                    productData={product}
                    experienceVirtualObject={experienceVirtualObject}
                    allowProductRemoval={false}
                  ></ProductInformation>
                </>
              )}
              <div className="h-40" />
            </ScrollArea>
          </DrawerContent>
        </DrawerPortal>
      </Drawer>
    </>
  );
};

export default ProductDrawer;
