import { useThree } from "@react-three/fiber";
import { PerspectiveCamera } from "three";

const useCameraFunctions = () => {
  const { camera, size } = useThree();

  const scaleFromZ = (z: number) => {
    if (!(camera instanceof PerspectiveCamera))
      return { width: 0, height: 0, scale: 0 };

    const distance = Math.abs(camera.position.z - z);
    const fovInRadians = (camera.fov * Math.PI) / 180;

    // Visible height, adjusted for zoom
    const height = (2 * Math.tan(fovInRadians / 2) * distance) / camera.zoom;

    // Visible width
    const width = height * (size.width / size.height);

    // Scale adjustment
    const scale = width / size.width;

    return { width, height, scale };
  };

  const scaleFromDistance = (distance: number) => {
    if (!(camera instanceof PerspectiveCamera))
      return { width: 0, height: 0, scale: 0 };

    // const distance = Math.abs(camera.position.z - z);
    const fovInRadians = (camera.fov * Math.PI) / 180;

    // Visible height, adjusted for zoom
    const height = (2 * Math.tan(fovInRadians / 2) * distance) / camera.zoom;

    // Visible width
    const width = height * (size.width / size.height);

    // Scale adjustment
    const scale = width / size.width;

    return { width, height, scale };
  };

  return { scaleFromZ, scaleFromDistance };
};

export default useCameraFunctions;
