/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_BedShedOslo.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    BedFrame: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_BedShedOsloF(
  props: JSX.IntrinsicElements["group"]
) {
  const { nodes, materials } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_BedShedOslo.glb"
  ) as GLTFResult;

  const BedShedOsloD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_BedShedOsloD.avif"
  );
  BedShedOsloD.flipY = false;
  BedShedOsloD.colorSpace = THREE.SRGBColorSpace;
  BedShedOsloD.channel = 2;
  const BedShedOsloMat = new THREE.MeshBasicMaterial({
    map: BedShedOsloD,
  });
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.BedFrame.geometry}
        material={BedShedOsloMat}
        position={[-2.703, 0, 4.228]}
        rotation={[0, -Math.PI / 2, 0]}
      />
    </group>
  );
}

export const WTFN25_SPIN5_BedShedOslo = () => {
  return <WTFN25_SPIN5_BedShedOsloF></WTFN25_SPIN5_BedShedOsloF>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_BedShedOslo.glb");
