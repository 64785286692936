import CanvasPage from "@/pages/DevPage/canvas";
import HostVideoTest from "@/pages/DevPage/host";
import ModelsPage from "@/pages/DevPage/models";
import { useMemo } from "react";
import { Outlet, useParams } from "react-router-dom";

const devPages = {
  models: ModelsPage,
  canvas: CanvasPage,
  host: HostVideoTest,
};

export default function DevPage() {
  const { slug } = useParams<{ slug: string }>();

  const Page = useMemo(() => {
    const devPage = devPages[slug] as unknown as React.FC;
    return devPage || (() => <div>Page not found</div>);
  }, [slug]);

  return (
    <div>
      <Page />
      <Outlet /> {/* This will render the nested pages */}
    </div>
  );
}
