import AlphaVideoPlane from "@/components/3d/AlphaVideoPlane";
import TestCanvas from "@/components/3d/TestCanvas";

// const Models = await import("@/components/sets/spin5/models");

import { CameraControls, Center, Plane, StatsGl } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { useEffect, useState } from "react";

const Controls = () => {
  const { camera } = useThree();

  useEffect(() => {
    camera.position.set(0, 0, 5);
  }, []);

  return <CameraControls />;
};

export default function CanvasPage() {
  const [hasClicked, setHasClicked] = useState(false);
  return (
    <>
      <TestCanvas>
        <Controls />
        <Center onClick={() => setHasClicked(true)}>
          {!hasClicked && <Plane args={[1, 1]} />}
          {hasClicked && <AlphaVideoPlane demo autoStart />}
        </Center>
        <StatsGl className="stats fixed" />
      </TestCanvas>
    </>
  );
}
