import Segment from "@/components/3d/Segment";
import VirtualObject from "@/components/3d/VirtualObject";
import * as Models from "@/components/sets/spin5/models";
import { SceneObject } from "@/models/scene";
import useScenesStore, { SceneContext } from "@/stores/useScenesStore";
import { useEffect, useMemo } from "react";

type ModelKeys = keyof typeof Models;

const Objects = ({ objects }: { objects: SceneObject[] }) => {
  return objects?.map((object, index) => {
    const Component = Models[object.name as ModelKeys];

    const name = `${object?.componentName || object?.name}`;

    if (!Component) {
      console.error("Component not found for", object.name);
    }

    const key = `${name}-${index}`;

    return (
      <VirtualObject key={key} {...object}>
        <Component />
      </VirtualObject>
    );
  });
};

export default function Scene({
  scene: { objects, segments },
  ...sceneContext
}: SceneContext) {
  // const reset = useSegmentStore.use.reset();
  const currentScene = useScenesStore.use.currentScene();
  const currentSegmentIndex = useScenesStore.use.currentSegmentIndex();

  const isCurrentScene = useMemo(() => {
    return currentScene?.index === sceneContext.index;
  }, [currentScene?.index, sceneContext.index]);

  useEffect(() => {
    // reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSegmentIndex]);

  const activeSegement = useMemo(() => {
    const currentSeg = segments[currentSegmentIndex];
    return isCurrentScene ? currentSeg : null;
  }, [isCurrentScene, currentSegmentIndex, segments]);

  return (
    <group>
      {/* render scene objects */}
      <Objects objects={objects} />
      {/* render segments */}
      {isCurrentScene && <Segment {...activeSegement} />}
    </group>
  );
}
