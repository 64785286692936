/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_Dining.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    DiningSettingBase: THREE.Mesh;
  };
  materials: {};
};

export function WTFN25_SPIN5_DiningF(props: JSX.IntrinsicElements["group"]) {
  const { nodes } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_Dining.glb"
  ) as GLTFResult;

  const DiningD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_DiningD.avif"
  );
  DiningD.flipY = false;
  DiningD.colorSpace = THREE.SRGBColorSpace;
  DiningD.channel = 0;
  const DiningMat = new THREE.MeshBasicMaterial({
    map: DiningD,
    side: THREE.DoubleSide,
  });

  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.DiningSettingBase.geometry} material={DiningMat} />
    </group>
  );
}
export const WTFN25_SPIN5_Dining = () => {
  return <WTFN25_SPIN5_DiningF></WTFN25_SPIN5_DiningF>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_Dining.glb");
