import useDevModeStore from "@/stores/useDevModeStore";
import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

let hasLogged = false;

const useDevMode = () => {
  const setDevMode = useDevModeStore.use.setDevMode();
  const [searchParams] = useSearchParams();
  const isDev = useMemo(
    () => searchParams.has("dev") || searchParams.get("dev") === "true",
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const isDesktop = useMemo(
    () => searchParams.has("desktop") || searchParams.get("desktop") === "true",
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (hasLogged) return;
    if (isDev || isDesktop) {
      console.groupCollapsed("== Modes ==");
      if (isDev) console.log("You are in DEV mode");
      if (isDesktop) console.log("You are in DESKTOP mode");
      console.groupEnd();
      hasLogged = true;
    }
    setDevMode(isDev);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isDev, isDesktop };
};

export default useDevMode;
