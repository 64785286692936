/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_LoungeWallsChange.glb -t -r public 
*/

import * as THREE from "three";

import useInteractionsStore from "@/stores/useInteractionsStore";
import { useGLTF, useTexture } from "@react-three/drei";
import gsap from "gsap";
import { useControls } from "leva";
import { useEffect, useMemo } from "react";
import { GLTF } from "three-stdlib";
import fragmentShader from "./glsl/fragment.glsl";
import vertexShader from "./glsl/vertex.glsl";

type GLTFResult = GLTF & {
  nodes: {
    WallsColourChange: THREE.Mesh;
  };
  materials: object;
};

export const WALL_KEY = "walls";

export function WTFN25_SPIN5_LoungeWallsChange(props: THREE.Group) {
  const events = useInteractionsStore.use.events();

  const { nodes } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_LoungeWallsChange.glb"
  ) as GLTFResult;

  // LOUNGE WALLS TAUBMANS CRISP WHITE
  const startTexture = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_LoungeWallsCrispD.avif"
  );

  startTexture.flipY = false;

  const crispTexture = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_LoungeWallsCrispD.avif"
  );

  crispTexture.flipY = false;

  const cloverTexture = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_LoungeWallsCloverD.avif"
  );

  cloverTexture.flipY = false;

  const ariaTexture = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_LoungeWallsAriaD.avif"
  );

  ariaTexture.flipY = false;

  const wallMaterial = useMemo(() => {
    return new THREE.ShaderMaterial({
      uniforms: {
        uGamma: { value: 2 },
        uStartPower: { value: 1 },
        uCrispPower: { value: 0 },
        uCloverPower: { value: 0 },
        uAriaPower: { value: 0 },
        uStartTexture: { value: startTexture },
        uCrispTexture: { value: crispTexture },
        uCloverTexture: { value: cloverTexture },
        uAriaTexture: { value: ariaTexture },
      },
      vertexShader,
      fragmentShader,
      transparent: true,
      opacity: 1,
    });
  }, [startTexture, crispTexture, cloverTexture, ariaTexture]);

  wallMaterial;

  const controls = useControls({
    wall: {
      value: "start",
      options: ["start", "crisp", "aria", "clover"],
    },
  });

  useEffect(() => {
    const duration = 1;
    const wall = controls.wall;
    gsap.to(wallMaterial.uniforms.uStartPower, {
      value: wall === "start" ? 1 : 0,
      duration,
    });
    gsap.to(wallMaterial.uniforms.uCrispPower, {
      value: wall === "crisp" ? 1 : 0,
      duration,
    });
    gsap.to(wallMaterial.uniforms.uAriaPower, {
      value: wall === "aria" ? 1 : 0,
      duration,
    });
    gsap.to(wallMaterial.uniforms.uCloverPower, {
      value: wall === "clover" ? 1 : 0,
      duration,
    });
  }, [
    controls.wall,
    events,
    wallMaterial.uniforms.uAriaPower,
    wallMaterial.uniforms.uCloverPower,
    wallMaterial.uniforms.uCrispPower,
    wallMaterial.uniforms.uStartPower,
  ]);

  useEffect(() => {
    const event = events.get(WALL_KEY);
    if (event?.value) {
      const duration = 1;
      const wall = event?.value;
      gsap.to(wallMaterial.uniforms.uStartPower, {
        value: wall === "start" ? 1 : 0,
        duration,
      });
      gsap.to(wallMaterial.uniforms.uCrispPower, {
        value: wall === "crisp" ? 1 : 0,
        duration,
      });
      gsap.to(wallMaterial.uniforms.uAriaPower, {
        value: wall === "aria" ? 1 : 0,
        duration,
      });
      gsap.to(wallMaterial.uniforms.uCloverPower, {
        value: wall === "clover" ? 1 : 0,
        duration,
      });
    }
  }, [
    events,
    wallMaterial.uniforms.uAriaPower,
    wallMaterial.uniforms.uCloverPower,
    wallMaterial.uniforms.uCrispPower,
    wallMaterial.uniforms.uStartPower,
  ]);

  // LoungeWallsCrispD.flipY = false;
  // LoungeWallsCrispD.colorSpace = THREE.SRGBColorSpace;
  // LoungeWallsCrispD.channel = 0;

  // const LoungeWallsCrispMat = new THREE.MeshBasicMaterial({
  //   map: LoungeWallsCrispD,
  // });

  // // LOUNGE WALLS TAUBMANS ARIA IVORY
  // LoungeWallsAriaD.flipY = false;
  // LoungeWallsAriaD.colorSpace = THREE.SRGBColorSpace;
  // LoungeWallsAriaD.channel = 0;

  // const LoungeWallsAriaMat = new THREE.MeshBasicMaterial({
  //   map: LoungeWallsAriaD,
  // });

  // // LOUNGE WALLS TAUBMANS WHITE CLOVER
  // const LoungeWallsCloverD = useTexture(
  //   "/textures/WTFN25/SPIN5/WTFN25_SPIN5_LoungeWallsCloverD.avif"
  // );

  // LoungeWallsCloverD.flipY = false;
  // LoungeWallsCloverD.colorSpace = THREE.SRGBColorSpace;
  // LoungeWallsCloverD.channel = 0;

  // const LoungeWallsCloverMat = new THREE.MeshBasicMaterial({
  //   map: LoungeWallsCloverD,
  // });

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.WallsColourChange.geometry}
        // material={LoungeWallsCrispMat}
      >
        <primitive object={wallMaterial} />
      </mesh>
    </group>
  );
}

useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_LoungeWallsChange.glb");
