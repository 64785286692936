/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_MasterDecor.glb -t -r public 
*/

import * as THREE from "three";

import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    CooperBedside001: THREE.Mesh;
  };
  materials: {};
};

export function WTFN25_SPIN5_MasterDecorF(
  props: JSX.IntrinsicElements["group"]
) {
  const { nodes, materials } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_MasterDecor.glb"
  ) as GLTFResult;

  const MasterDecorD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_MasterDecorD.avif"
  );
  MasterDecorD.flipY = false;
  MasterDecorD.colorSpace = THREE.SRGBColorSpace;
  MasterDecorD.channel = 2;
  const MasterDecorMat = new THREE.MeshBasicMaterial({
    map: MasterDecorD,
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.CooperBedside001.geometry}
        material={MasterDecorMat}
      />
    </group>
  );
}

export const WTFN25_SPIN5_MasterDecor = () => {
  return <WTFN25_SPIN5_MasterDecorF></WTFN25_SPIN5_MasterDecorF>;
};

useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_MasterDecor.glb");
