import "regenerator-runtime/runtime"; // used by react-speech-recognition
import { Button } from "@/components/ui/button";
import { AudioLinesIcon, Mic } from "lucide-react";
import { FC, useState, useRef } from "react";
import { useExperienceContext } from "@/context/experience.context";
import ChatMessageService from "@/services/chat-message.service";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { FaSpinner } from "react-icons/fa6";
import { set } from "date-fns";

interface VoiceAiChatButtonProps {
  isAudioTranscriptionOnServer?: boolean;
}

const VoiceAiChatButton: FC<VoiceAiChatButtonProps> = ({
  isAudioTranscriptionOnServer = false,
}) => {
  // states for server-side audio transcription
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const audioChunks = useRef<Blob[]>([]);
  const [isRecording, setIsRecording] = useState(false);

  // states for client-side audio transcription
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
  } = useSpeechRecognition({
    commands: [],
  });

  // shared states
  const { experience } = useExperienceContext();
  const [isIntroPlayed, setIsIntroPlayed] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [isGettingAudio, setIsGettingAudio] = useState(false);
  const { aiSessionId, setAiSessionId } = useExperienceContext();

  const requestMicrophonePermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      return true;
    } catch (err) {
      console.error("Microphone permission denied:", err);
      return false;
    }
  };

  const convertBlobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = (reader.result as string).split(",")[1];
        resolve(base64String);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const startAudioRecording = async () => {
    try {
      setIsRecording(true);
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder.current = new MediaRecorder(stream);
      audioChunks.current = [];

      mediaRecorder.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.current.push(event.data);
        }
      };

      mediaRecorder.current.start();
    } catch (err) {
      setIsRecording(false);
      console.error("Error starting audio recording:", err);
      throw err;
    }
  };

  const stopAudioRecording = async (): Promise<{
    base64: string;
    mimeType: string;
    sampleRateHertz: number;
  }> => {
    return new Promise((resolve, reject) => {
      if (!mediaRecorder.current) {
        setIsRecording(false);
        reject(new Error("No media recorder available"));
        return;
      }

      mediaRecorder.current.onstop = async () => {
        try {
          setIsRecording(false);
          const audioBlob = new Blob(audioChunks.current, {
            type: mediaRecorder.current?.mimeType || "audio/webm",
          });
          const base64 = await convertBlobToBase64(audioBlob);
          const mimeType =
            mediaRecorder.current?.mimeType.split(";")[0] || "audio/webm"; // remove codecs from mimeType
          const sampleRateHertz =
            mediaRecorder.current?.stream.getAudioTracks()[0].getSettings()
              .sampleRate || 16000; // Default to 16000 if not available
          resolve({
            base64,
            mimeType,
            sampleRateHertz,
          });
        } catch (err) {
          reject(err);
        }
      };

      mediaRecorder.current.stop();
      mediaRecorder.current.stream.getTracks().forEach((track) => track.stop());
    });
  };

  const startVoiceRecording = async () => {
    try {
      if (!isIntroPlayed) {
        await getIntro();
        setIsIntroPlayed(true);
      }

      const hasPermission = await requestMicrophonePermission();
      if (!hasPermission) {
        alert("Microphone permission is required to use voice chat");
        return;
      }

      if (isAudioTranscriptionOnServer) {
        await startAudioRecording();
      } else {
        await SpeechRecognition.startListening({
          continuous: true,
        });
      }
    } catch (err) {
      alert(err.message);
      console.error(err);
    }
  };

  const stopVoiceRecording = async () => {
    try {
      if (isAudioTranscriptionOnServer) {
        const audioData = await stopAudioRecording();
        await submitChatMessageWithAudio(audioData);
      } else {
        await SpeechRecognition.stopListening();
        if (transcript) {
          await submitChatMessageWithText(transcript);
          resetTranscript();
        }
      }
    } catch (err) {
      setIsGettingAudio(false);
      alert(err.message);
      console.error(err);
    }
  };

  async function submitChatMessageWithAudio(audioMessage: {
    base64: string;
    mimeType: string;
    sampleRateHertz: number;
  }) {
    try {
      setIsGettingAudio(true);
      const response = await ChatMessageService.saveOneWithAudio(
        experience.brandId,
        experience.id,
        aiSessionId,
        audioMessage
      );
      setIsGettingAudio(false);
      if (response.aiSessionId) setAiSessionId(response.aiSessionId);
      if (response.audioResponse) {
        await playAudioResponse(response.audioResponse);
      }
    } catch (err) {
      alert(err.message);
      throw err;
    }
  }

  async function submitChatMessageWithText(textMessage: string) {
    try {
      setIsGettingAudio(true);
      const response = await ChatMessageService.saveOneWithText(
        experience.brandId,
        experience.id,
        aiSessionId,
        textMessage
      );
      setIsGettingAudio(false);
      if (response.aiSessionId) setAiSessionId(response.aiSessionId);
      if (response.audioResponse) {
        await playAudioResponse(response.audioResponse);
      }
    } catch (err) {
      alert(err.message);
      setIsAudioPlaying(false);
      setIsGettingAudio(false);
      throw err;
    }
  }

  /**
   * Play Base64 encoded audio response from the server
   * @param audioResponse Base64 encoded audio response from the server
   */
  async function playAudioResponse(audioResponse: string) {
    try {
      setIsAudioPlaying(true);
      const audioSrc = `data:audio/mp3;base64,${audioResponse}`;
      const audio = new Audio(audioSrc);
      await new Promise((resolve, reject) => {
        audio.addEventListener("ended", resolve);
        audio.addEventListener("error", reject);
        audio.play().catch(reject);
      });
      setIsAudioPlaying(false);
    } catch (err) {
      setIsAudioPlaying(false);
      throw err;
    }
  }

  /**
   * Get the introduction audio to let the user now the AI is listening and ready to respond
   */
  async function getIntro() {
    try {
      setIsGettingAudio(true);
      const response = await ChatMessageService.getIntroduction(
        experience.brandId,
        experience.id
      );
      setIsGettingAudio(false);
      if (response.audioResponse) {
        setIsAudioPlaying(true);
        const audioSrc = `data:audio/mp3;base64,${response.audioResponse}`;
        const audio = new Audio(audioSrc);

        // setCurrentAudio(audio);
        await new Promise((resolve, reject) => {
          audio.addEventListener("ended", resolve);
          audio.addEventListener("error", reject);
          audio.play().catch(reject);
        });
        setIsAudioPlaying(false);
      }
    } catch (err) {
      alert(err.message);
      throw err;
    }
  }

  // Check browser support and microphone access
  if (!isAudioTranscriptionOnServer && !browserSupportsSpeechRecognition) {
    return null;
  }

  // Check microphone access
  if (!isAudioTranscriptionOnServer && !isMicrophoneAvailable) {
    return (
      <Button
        type="button"
        variant="default"
        size="default"
        className="flex-none fixed bottom-1 left-1/2 transform -translate-x-1/2 z-10 pointer-events-auto w-[80%]"
        disabled
      >
        <Mic className="w-5 h-5 mr-2" /> Microphone access needed
      </Button>
    );
  }

  return (
    <Button
      type="button"
      variant={listening || isRecording ? "outline" : "default"}
      size="default"
      className={`flex-none fixed bottom-1 left-1/2 transform -translate-x-1/2 z-10 pointer-events-auto w-[80%]`}
      onClick={
        isAudioPlaying || isGettingAudio
          ? undefined
          : listening || isRecording
          ? stopVoiceRecording
          : startVoiceRecording
      }
      disabled={isAudioPlaying || isGettingAudio}
    >
      {(() => {
        switch (true) {
          case isGettingAudio:
            return (
              <>
                <FaSpinner className="w-5 h-5 mr-2 animate-spin" />
                Thinking...
              </>
            );
          case isAudioPlaying:
            return (
              <>
                <AudioLinesIcon className="w-5 h-5 mr-2" /> Speaking...
              </>
            );
          case listening || isRecording:
            return (
              <>
                <div className="w-5 h-5 mr-2 rounded-full bg-red-600 animate-flash" />{" "}
                Listening. Tap when complete.
              </>
            );
          default:
            return (
              <>
                <Mic className="w-5 h-5 mr-2" /> Ask a question
              </>
            );
        }
      })()}
    </Button>
  );
};

export default VoiceAiChatButton;
