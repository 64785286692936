/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_SpareBedroomTV.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useTexture, useVideoTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { useInteractionContext } from "@/pages/Viewer/viewer/context/InteractionContext";

type GLTFResult = GLTF & {
  nodes: {
    Cube024: THREE.Mesh;
    Cube024_1: THREE.Mesh;
    Cube024_2: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_SpareBedroomTVF(
  props: JSX.IntrinsicElements["group"]
) {
  const { nodes, materials } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_SpareBedroomTV.glb"
  ) as GLTFResult;

  const SpareBedroomTVD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_SpareBedroomTVD.avif"
  );
  SpareBedroomTVD.flipY = false;
  SpareBedroomTVD.colorSpace = THREE.SRGBColorSpace;

  const SpareBedroomTVMat = new THREE.MeshBasicMaterial({
    map: SpareBedroomTVD,
  });
  const { currentSceneId } = useInteractionContext();

  const SpareBedroomTVScreenMat = new THREE.MeshBasicMaterial({
    // map: trailer || new THREE.Color("black"),
    color: "black",
  });

  return (
    <group {...props} dispose={null}>
      <group position={[-5.61, 0.875, 4.186]}>
        <mesh geometry={nodes.Cube024.geometry} material={SpareBedroomTVMat} />
        <mesh
          geometry={nodes.Cube024_1.geometry}
          material={SpareBedroomTVMat}
        />
        <mesh
          geometry={nodes.Cube024_2.geometry}
          material={SpareBedroomTVScreenMat}
        />
      </group>
    </group>
  );
}

export const WTFN25_SPIN5_SpareBedroomTV = () => {
  return <WTFN25_SPIN5_SpareBedroomTVF></WTFN25_SPIN5_SpareBedroomTVF>;
};

useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_SpareBedroomTV.glb");
