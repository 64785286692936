/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_DaikinAlira.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useAnimations, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    NR115_3003: THREE.Mesh;
    NR115_3003_1: THREE.Mesh;
    NR115_3003_2: THREE.Mesh;
    daikinalinax004: THREE.Mesh;
    daikinalinax005: THREE.Mesh;
    Blackout: THREE.Mesh;
  };
  materials: {};
};

type ActionName = "daikinalinax.001Action.001" | "daikinalinax.002Action.001";
type GLTFActions = Record<ActionName, THREE.AnimationAction>;

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_DaikinAliraF(
  props: JSX.IntrinsicElements["group"]
) {
  const group = useRef<THREE.Group>();
  const { nodes, materials, animations } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_DaikinAlira.glb"
  ) as GLTFResult;
  // const { actions } = useAnimations<GLTFActions>(animations, group); TODO: TEMP TYPESCRIP FIX
  const { actions } = useAnimations(animations, group);

  const DaikinAliraD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_DaikinAliraD.avif"
  );
  DaikinAliraD.flipY = false;
  DaikinAliraD.colorSpace = THREE.SRGBColorSpace;
  DaikinAliraD.channel = 1;

  const DaikinAliraMat = new THREE.MeshBasicMaterial({
    map: DaikinAliraD,
  });

  const Blackout = new THREE.MeshBasicMaterial({
    color: "black",
  });

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group
          name="daikinalinax003"
          position={[-0.337, 0.223, 8.222]}
          rotation={[0, Math.PI / 2, 0]}
        >
          <mesh
            name="NR115_3003"
            geometry={nodes.NR115_3003.geometry}
            material={DaikinAliraMat}
          />
          <mesh
            name="NR115_3003_1"
            geometry={nodes.NR115_3003_1.geometry}
            material={DaikinAliraMat}
          />
          <mesh
            name="NR115_3003_2"
            geometry={nodes.NR115_3003_2.geometry}
            material={DaikinAliraMat}
          />
          <mesh
            name="daikinalinax004"
            geometry={nodes.daikinalinax004.geometry}
            material={DaikinAliraMat}
            position={[1.896, 2.095, 1.62]}
          />
          <mesh
            name="daikinalinax005"
            geometry={nodes.daikinalinax005.geometry}
            material={DaikinAliraMat}
            position={[1.852, 2.071, 1.62]}
          />
        </group>
        <mesh
          name="Blackout"
          geometry={nodes.Blackout.geometry}
          material={Blackout}
          position={[1.284, 2.324, 6.336]}
          rotation={[0.621, 0, 0]}
          scale={0.069}
        />
      </group>
    </group>
  );
}

export const WTFN25_SPIN5_DaikinAlira = () => {
  return <WTFN25_SPIN5_DaikinAliraF></WTFN25_SPIN5_DaikinAliraF>;
};

useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_DaikinAlira.glb");
