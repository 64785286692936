/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_Paint.glb -t -r public 
*/

import * as THREE from "three";
import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    paintdropsheet: THREE.Mesh;
  };
  materials: {};
};

export function WTFN25_SPIN5_PaintF(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_Paint.glb"
  ) as GLTFResult;

  const PaintD = useTexture("/textures/WTFN25/SPIN5/WTFN25_SPIN5_PaintD.avif");
  PaintD.flipY = false;
  PaintD.colorSpace = THREE.SRGBColorSpace;
  PaintD.channel = 1;

  const PaintMat = new THREE.MeshBasicMaterial({
    map: PaintD,
  });

  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.paintdropsheet.geometry} material={PaintMat} />
    </group>
  );
}
export const WTFN25_SPIN5_Paint = () => {
  return <WTFN25_SPIN5_PaintF></WTFN25_SPIN5_PaintF>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_Paint.glb");
