/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_TaubmansCrisp.glb -t -r public 
*/

import { WALL_KEY } from "@/components/sets/spin5/models/WTFN25_SPIN5_LoungeWallsChange";
import useInteractionsStore from "@/stores/useInteractionsStore";
import { useGLTF, useTexture } from "@react-three/drei";
import React from "react";
import * as THREE from "three";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    PaintTinCrisp: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_TaubmansCrispF(
  props: JSX.IntrinsicElements["group"]
) {
  const addEvent = useInteractionsStore.use.addEvent();
  const { nodes, materials } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_TaubmansCrisp.glb"
  ) as GLTFResult;

  const PaintLabel = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_PaintLabel.avif"
  );
  PaintLabel.flipY = false;
  PaintLabel.colorSpace = THREE.SRGBColorSpace;

  const PaintLabelMat = new THREE.MeshStandardMaterial({
    map: PaintLabel,
    roughness: 0.5,
  });

  return (
    <group
      {...props}
      dispose={null}
      onClick={() => {
        // onMaterialChange(2);
        addEvent(WALL_KEY, { value: "crisp" });
      }}
    >
      <mesh
        geometry={nodes.PaintTinCrisp.geometry}
        material={PaintLabelMat}
        position={[-4.761, 0.758, -1.293]}
        rotation={[0, 1.442, 0]}
        scale={1.461}
      />
    </group>
  );
}

export const WTFN25_SPIN5_TaubmansCrisp = () => {
  return <WTFN25_SPIN5_TaubmansCrispF></WTFN25_SPIN5_TaubmansCrispF>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_TaubmansCrisp.glb");
