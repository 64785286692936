/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// JY: Sorry - no time to troubleshoot this right now. I'll come back to it later.

import Spin5ScenesManifest from "@/components/sets/spin5/manifest";
import { Scene as IScene, SceneObject } from "@/models/scene";
import createSelectors from "@/stores/createSelectors";
import { create } from "zustand";
import { combine } from "zustand/middleware";

interface ScenesStoreState {
  scenes: SceneContext[];
  currentScene: SceneContext | null;
}

export enum SceneState {
  LOADING = "LOADING",
  READY = "READY",
  ACTIVE = "ACTIVE",
  PLAYING = "PLAYING",
  COMPLETE = "COMPLETE",
}

export interface SceneContextScene extends IScene {
  currentSegment: number;
  objects: SceneObject[];
}

export interface SceneContext {
  index: number;
  scene: SceneContextScene;
  state: SceneState;
  ready: () => void;
  active: () => void;
  play: () => void;
  complete: () => void;
}

const createSceneContext = (
  scene: IScene,
  index: number,
  set: (fn: (state: ScenesStoreState) => Partial<ScenesStoreState>) => void
): SceneContext => {
  const ojects = scene.objects || [];
  const setObjects = scene.setObjects || [];
  const virtualObjects = scene.virtualObjects || [];

  const sceneContext: SceneContextScene = {
    ...scene,
    objects: [...ojects, ...setObjects, ...virtualObjects],
    segments: scene.segments || [],
    currentSegment: 0,
  };

  return {
    // TODO: rename this sceneContext
    scene: sceneContext,
    index,
    state: SceneState.LOADING,
    ready: () =>
      set((state: ScenesStoreState) =>
        updateSceneState(state, index, SceneState.READY)
      ),
    active: () =>
      set((state: ScenesStoreState) =>
        updateSceneState(state, index, SceneState.ACTIVE)
      ),
    play: () =>
      set((state: ScenesStoreState) =>
        updateSceneState(state, index, SceneState.PLAYING)
      ),
    complete: () =>
      set((state: ScenesStoreState) =>
        updateSceneState(state, index, SceneState.COMPLETE)
      ),
  };
};

const updateSceneState = (
  state: ScenesStoreState,
  index: number,
  newState: SceneState
) => ({
  scenes: state.scenes.map((scene: SceneContext, i: number) =>
    i === index ? { ...scene, state: newState } : scene
  ),
  currentScene:
    state.currentScene && state.currentScene.index === index
      ? { ...state.currentScene, state: newState }
      : state.currentScene,
});

/**
 * A Zustand store for managing scene navigation and state.
 *
 * @typedef {Object} ScenesStore
 * @property {SceneContext[]} scenes - Array of all available scene contexts
 * @property {SceneContext | null} currentScene - Currently active scene context
 * @property {() => void} next - Advances to the next scene if available
 * @property {() => void} previous - Returns to the previous scene if available
 * @property {(id: string) => void} goToSceneId - Navigates to a specific scene by ID
 * @property {(index: number) => void} goToSceneIndex - Navigates to a specific scene by index
 *
 * The store initializes scenes from Spin5Scenes and provides navigation methods.
 * Index bounds are automatically handled for safety.
 * If an invalid scene ID or index is requested, the current scene remains unchanged.
 */
const scenesStore = create(
  combine({ scenes: [], currentScene: null, currentSegmentIndex: 0 }, (set) => {
    const initializedScenes: SceneContext[] = Spin5ScenesManifest.map(
      (scene, index) => createSceneContext(scene, index, set)
    );

    return {
      scenes: initializedScenes,
      currentScene: initializedScenes[0], // Could be null initially
      currentSceneId: initializedScenes[0].scene.id, // Could be null initially
      currentSegmentIndex: 0,
      nextSegment: () =>
        set((state) => {
          const totalSceneSegements = state.currentScene.scene.segments.length;
          return {
            currentSegmentIndex: Math.min(
              state.currentSegmentIndex + 1,
              totalSceneSegements - 1
            ),
          };
        }),
      next: () =>
        set((state) => {
          const currentIndex = state.currentScene?.index; // Use optional chaining
          if (currentIndex == null || currentIndex === state.scenes.length - 1)
            return {};
          return { currentScene: state.scenes[currentIndex + 1] };
        }),
      previous: () =>
        set((state) => {
          const currentIndex = state.currentScene.index;
          if (currentIndex === 0) return;
          return { currentScene: state.scenes[currentIndex - 1] };
        }),
      goToSceneId: (id: string) =>
        set((state) => {
          const newScene = state.scenes.find((scene) => scene.scene.id === id);
          if (!newScene) return;
          return { currentScene: newScene, currentSegmentIndex: 0 };
        }),
      goToSceneIndex: (index: number) =>
        set((state) => {
          const newIndex = Math.max(
            0,
            Math.min(index, state.scenes.length - 1)
          );
          if (newIndex === state.currentScene.index) return;
          return {
            currentScene: state.scenes[newIndex],
            currentSegmentIndex: 0,
          };
        }),
    };
  })
);

const useScenesStore = createSelectors(scenesStore);

export default useScenesStore;
