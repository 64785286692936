import VirtualObject from "@/components/3d/VirtualObject";
import { ExperienceVirtualObject } from "@/models/experience-virtual-object";
import { useInteractionContext } from "@/pages/Viewer/viewer/context/InteractionContext";
import { ThreeEvent } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";
import { Box3, Vector3 } from "three";

export interface ViewerDynamicModelWrapperProps {
  children: React.ReactNode;
  obj: ExperienceVirtualObject;
  currentSceneId: string;
  onClick: (e: ThreeEvent<MouseEvent>) => void;
}

export const ViewerDynamicModelWrapper = ({
  children,
  obj,
  currentSceneId,
  onClick,
}: ViewerDynamicModelWrapperProps) => {
  const [hovered, setHovered] = useState<boolean>(false);

  const [clicked, setClicked] = useState<boolean>(false);
  const [indicatorPosition, setIndicatorPosition] = useState<Vector3 | null>(
    null
  );

  const { interactableProducts, currentlySelected, setCurrentlySelected } =
    useInteractionContext();

  const groupRef = useRef<THREE.Group>(null);

  useEffect(() => {
    setClicked(currentlySelected === obj.id);
  }, [currentlySelected]);

  useEffect(() => {
    if (clicked && groupRef.current) {
      const box = new Box3().setFromObject(groupRef.current);
      const center = new Vector3();
      box.getCenter(center);
      setIndicatorPosition(
        center.add(new Vector3(0, box.max.y - center.y + 0.5, 0))
      );
    } else {
      setIndicatorPosition(null);
    }
  }, [clicked]);

  const handleClick = (e: ThreeEvent<MouseEvent>) => {
    setClicked(!clicked);
    onClick(e);
  };

  useEffect(() => {
    document.body.style.cursor = hovered ? "pointer" : "auto";
  }, [hovered]);

  return (
    <group
      ref={groupRef}
      onPointerEnter={() => setHovered(true)}
      onPointerLeave={() => setHovered(false)}
      onClick={(e) => currentSceneId === obj.sceneId && handleClick(e)}
    >
      <VirtualObject>{children}</VirtualObject>
    </group>
  );
};
