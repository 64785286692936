/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_BedShed1.glb -t -r public 
*/

import * as THREE from "three";

import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Legs002: THREE.Mesh;
  };
  materials: {};
};

export function WTFN25_SPIN5_BedShed1F(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_BedShed1.glb"
  ) as GLTFResult;

  const BedShed1D = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_BedShed1D.avif"
  );
  BedShed1D.flipY = false;
  BedShed1D.colorSpace = THREE.SRGBColorSpace;
  BedShed1D.channel = 1;

  const BedShed1Mat = new THREE.MeshBasicMaterial({
    map: BedShed1D,
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Legs002.geometry}
        material={BedShed1Mat}
        position={[2.307, 0, 3.478]}
      />
    </group>
  );
}

export const WTFN25_SPIN5_BedShed1 = () => {
  return <WTFN25_SPIN5_BedShed1F></WTFN25_SPIN5_BedShed1F>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_BedShed1.glb");
