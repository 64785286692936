/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_GarageClutterObj.glb -t -r public 
*/

import useSegment from "@/components/hooks/useSegment";
import { useAnimations, useGLTF, useTexture } from "@react-three/drei";
import { useRef } from "react";
import * as THREE from "three";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    bag1_garbagebags_0: THREE.Mesh;
    bag2_garbagebags_0: THREE.Mesh;
    Cylinder009: THREE.Mesh;
    Bunny_Soft_Toy001: THREE.Mesh;
  };
  materials: object;
};

// type ActionName =
//   | "bag1_garbagebags_0Action"
//   | "bag2_garbagebags_0Action"
//   | "Cylinder.009Action"
//   | "Bunny Soft Toy.001Action";
// type GLTFActions = Record<ActionName, THREE.AnimationAction>;

// type ContextType = Record<
//   string,
//   React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
// >;

export function WTFN25_SPIN5_GarageClutterObjF(
  props: JSX.IntrinsicElements["group"]
) {
  const group = useRef<THREE.Group>();
  const { nodes, animations } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_GarageClutterObj.glb"
  ) as GLTFResult;
  // const { actions } = useAnimations<GLTFActions>(animations, group);

  const GarageClutterObjD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_GarageClutterObjD.avif"
  );
  GarageClutterObjD.flipY = false;
  GarageClutterObjD.colorSpace = THREE.SRGBColorSpace;
  GarageClutterObjD.channel = 1;
  const GarageClutterObjMat = new THREE.MeshBasicMaterial({
    map: GarageClutterObjD,
  });

  const { actions } = useAnimations(animations, group);
  const { playSegment } = useSegment("garage-clutter", actions);

  const onClick = () => {
    playSegment();
  };

  return (
    <group ref={group} {...props} dispose={null} onClick={onClick}>
      <group name="Scene">
        <mesh
          name="bag1_garbagebags_0"
          geometry={nodes.bag1_garbagebags_0.geometry}
          material={GarageClutterObjMat}
          position={[-9.769, 0.011, 3.55]}
        />
        <mesh
          name="bag2_garbagebags_0"
          geometry={nodes.bag2_garbagebags_0.geometry}
          material={GarageClutterObjMat}
          position={[-10.395, 0.011, 4.261]}
        />
        <mesh
          name="Cylinder009"
          geometry={nodes.Cylinder009.geometry}
          material={GarageClutterObjMat}
          position={[-10.647, 0.678, 4.983]}
          rotation={[1.478, 0.019, 3.03]}
        />
        {/* <Select enabled> */}
        <mesh
          name="Bunny_Soft_Toy001"
          geometry={nodes.Bunny_Soft_Toy001.geometry}
          material={GarageClutterObjMat}
          position={[-10, 0, 3.82]}
          rotation={[Math.PI, -1.258, Math.PI]}
        />
        {/* </Select> */}
      </group>
    </group>
  );
}
export const WTFN25_SPIN5_GarageClutterObj = () => {
  return <WTFN25_SPIN5_GarageClutterObjF />;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_GarageClutterObj.glb");
