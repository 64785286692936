/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_SKBoxLiteAfter.glb -t -r public 
*/

import useSegment from "@/components/hooks/useSegment";
import { useGLTF, useTexture } from "@react-three/drei";
import * as THREE from "three";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Lite_Duty_Box_After: THREE.Mesh;
  };
  materials: object;
};

// type ContextType = Record<
//   string,
//   React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
// >;

export function WTFN25_SPIN5_SKBoxLiteAfterF(
  props: JSX.IntrinsicElements["group"]
) {
  const { nodes } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_SKBoxLiteAfter.glb"
  ) as GLTFResult;

  const SKBoxesAfterD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_SKBoxesAfterD.avif"
  );
  SKBoxesAfterD.flipY = false;
  SKBoxesAfterD.colorSpace = THREE.SRGBColorSpace;
  SKBoxesAfterD.channel = 1;
  const SKBoxesAfterMat = new THREE.MeshBasicMaterial({
    map: SKBoxesAfterD,
  });

  const { playSegment } = useSegment();

  const onClick = () => {
    playSegment();
  };

  return (
    <group {...props} dispose={null} onClick={onClick}>
      <mesh
        geometry={nodes.Lite_Duty_Box_After.geometry}
        material={SKBoxesAfterMat}
        position={[-11.099, 0, 4.344]}
        rotation={[0, 0.173, 0]}
      />
    </group>
  );
}
export const WTFN25_SPIN5_SKBoxLiteAfter = () => {
  return <WTFN25_SPIN5_SKBoxLiteAfterF></WTFN25_SPIN5_SKBoxLiteAfterF>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_SKBoxLiteAfter.glb");
