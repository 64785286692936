import createSelectors from "@/stores/createSelectors";
import * as THREE from "three";
import { create } from "zustand";
import { combine } from "zustand/middleware";

export type GlbActions = Record<string, THREE.AnimationAction>;

export interface ActionGroup {
  id: string;
  actions: GlbActions;
}

export enum SegementState {
  ACTIVE = "ACTIVE",
  PLAYING = "PLAYING",
  COMPLETE = "COMPLETE",
}

const initialState = {
  segmentState: SegementState.ACTIVE,
  segmentActions: [] as ActionGroup[],
  setSegmentState: (segmentState: SegementState) => segmentState,
  addSegmentActions: (group: ActionGroup) => group,
  removeSegmentActions: (id: string) => id,
  hasClicked: false,
  reset: () => null,
};

const objectsStore = create(
  combine(initialState, (set) => ({
    setSegmentState: (segmentState: SegementState) => {
      return segmentState === SegementState.COMPLETE
        ? set(() => ({
            ...initialState,
            segmentState,
          }))
        : set(() => ({
            segmentState,
          }));
    },
    addSegmentActions: (group: ActionGroup) =>
      set((state) => {
        if (state.segmentActions.find((g) => g.id === group.id)) return;
        return {
          segmentActions: [...state.segmentActions, group],
        };
      }),
    removeSegmentActions: (id: string) =>
      set((state) => ({
        segmentActions: state.segmentActions.filter((group) => group.id !== id),
      })),
    reset: () =>
      set(() => ({
        ...initialState,
      })),
  }))
);

const useSegmentStore = createSelectors(objectsStore);

export default useSegmentStore;
