import useScenesStore from "@/stores/useScenesStore";
import useSegmentStore, {
  GlbActions,
  SegementState,
} from "@/stores/useSegmentStore";
import { useCallback, useEffect, useState } from "react";
import { LoopOnce } from "three";

/**
 * A hook that manages animation segments with their associated actions.
 *
 * @param id - Unique identifier for the segment
 * @param actions - Object containing GLB animation actions
 * @param onComplete - Optional callback function to be executed when all animations complete
 *
 * @returns An object containing the playSegment method
 *
 * @remarks
 * This hook handles the lifecycle of animation segments by:
 * - Registering segment actions on mount
 * - Cleaning up actions on unmount
 * - Managing animation playback with proper loop and clamp settings
 * - Coordinating sequential animation execution
 *
 * @example
 * ```typescript
 * const { playSegment } = useSegment('scene1', actions, () => console.log('Complete'));
 *
 * // Play all animations in the segment
 * playSegment();
 * ```
 */
export default function useSegment(
  id?: string,
  actions?: GlbActions,
  onComplete?: () => void
) {
  const setSegmentState = useSegmentStore.use.setSegmentState();
  const nextSegment = useScenesStore.use.nextSegment();
  const reset = useSegmentStore.use.reset();
  const segmentState = useSegmentStore.use.segmentState(); // ✅ Get all stored segment actions
  const segmentActions = useSegmentStore.use.segmentActions(); // ✅ Get all stored segment actions
  const addSegmentActions = useSegmentStore.use.addSegmentActions();
  const removeSegmentActions = useSegmentStore.use.removeSegmentActions();

  const [actionId, setActionId] = useState<string | null>(id);
  const [registeredActions, setRegisteredActions] = useState<GlbActions | null>(
    actions
  );

  useEffect(() => {
    if (!actionId || !registeredActions) return;
    addSegmentActions({ id: actionId, actions: registeredActions });
    const usedId = actionId;
    return () => removeSegmentActions(usedId); // Cleanup on unmount
  }, [
    actionId,
    registeredActions,
    id,
    removeSegmentActions,
    addSegmentActions,
  ]);

  const addActions = useCallback((id: string, actions: GlbActions) => {
    setActionId(id);
    setRegisteredActions(actions);
  }, []);

  /**
   * Plays all animation segments in the stored segment actions.
   * Each animation is set to play once and clamp when finished.
   *
   * This function:
   * 1. Flattens all animation actions from segment groups
   * 2. Creates a promise for each animation that resolves when finished
   * 3. Waits for all animations to complete before calling onComplete callback
   *
   * @returns {void}
   * @throws {void}
   *
   * @example
   * playSegment(); // Plays all animations in sequence
   */
  const playSegment = useCallback(() => {
    if (!segmentActions || segmentState === SegementState.PLAYING) {
      console.log("///// segmentActions or segmentState is PLAYING /////");
    }

    setSegmentState(SegementState.PLAYING);
    const animationPromises = segmentActions.flatMap((group) =>
      Object.values(group.actions).map(
        (action) =>
          new Promise<void>((resolve) => {
            action.setLoop(LoopOnce, 1);
            action.clampWhenFinished = true;
            action.play();

            action.getMixer().addEventListener("finished", () => {
              resolve(); // Resolve when animation is finished
            });
          })
      )
    );

    console.log(`playing ${segmentActions.length} actions`);

    // ✅ Wait for all animations in all groups to finish
    void Promise.all(animationPromises).then(() => {
      nextSegment(); // ✅ Advance to the next segment
      reset();
      if (onComplete) onComplete();
    });
  }, [segmentActions, segmentState, setSegmentState, nextSegment, onComplete]);

  return { playSegment, addActions };
}
