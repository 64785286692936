/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_VanInside.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Archive_Carton_Box004: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_VanInsideF(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_VanInside.glb"
  ) as GLTFResult;

  const VanInsideD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_VanInsideD.avif"
  );
  VanInsideD.flipY = false;
  VanInsideD.colorSpace = THREE.SRGBColorSpace;
  VanInsideD.channel = 1;
  const VanInsideMat = new THREE.MeshBasicMaterial({
    map: VanInsideD,
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Archive_Carton_Box004.geometry}
        material={VanInsideMat}
        position={[-18.609, 1.613, 4.002]}
        rotation={[-0.011, 0.181, 0.054]}
      />
    </group>
  );
}
export const WTFN25_SPIN5_VanInside = () => {
  return <WTFN25_SPIN5_VanInsideF></WTFN25_SPIN5_VanInsideF>;
};

useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_VanInside.glb");
