/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_GarageJunkPlanes.glb -t -r public 
*/

import { useGLTF, useTexture } from "@react-three/drei";
import { useRef } from "react";
import * as THREE from "three";
import { GLTF } from "three-stdlib";
// import useSegment from "@/components/hooks/useSegment";

type GLTFResult = GLTF & {
  nodes: {
    Plane107: THREE.Mesh;
    Plane108: THREE.Mesh;
    Plane109: THREE.Mesh;
  };
  materials: object;
};

// type ActionName =
//   | "Plane.107Action.001"
//   | "Plane.108Action.001"
//   | "Plane.109Action.001";
// type GLTFActions = Record<ActionName, THREE.AnimationAction>;

// type ContextType = Record<
//   string,
//   React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
// >;

export function WTFN25_SPIN5_GarageJunkPlanesF(
  props: JSX.IntrinsicElements["group"]
) {
  const group = useRef<THREE.Group>();
  // const { nodes, materials, animations } = useGLTF(
  const { nodes } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_GarageJunkPlanes.glb"
  ) as GLTFResult;
  // const { actions } = useAnimations<GLTFActions>(animations, group);

  const GarageClutterPile = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_GarageClutterPile.avif"
  );
  GarageClutterPile.flipY = false;
  GarageClutterPile.colorSpace = THREE.SRGBColorSpace;

  const GarageClutterBack = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_GarageClutterBack.avif"
  );
  GarageClutterBack.flipY = false;
  GarageClutterBack.colorSpace = THREE.SRGBColorSpace;

  const GarageClutterSide = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_GarageClutterSide.avif"
  );
  GarageClutterSide.flipY = false;
  GarageClutterSide.colorSpace = THREE.SRGBColorSpace;

  const GarageClutterPileMat = new THREE.MeshStandardMaterial({
    map: GarageClutterPile,
    transparent: true,
    // depthWrite: false,
  });

  const GarageClutterBackMat = new THREE.MeshStandardMaterial({
    map: GarageClutterBack,
    transparent: true,
  });

  const GarageClutterSideMat = new THREE.MeshStandardMaterial({
    map: GarageClutterSide,
    transparent: true,
  });

  // useSegment("garage-junk-planes", null);

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="Plane108"
          geometry={nodes.Plane108.geometry}
          material={GarageClutterBackMat}
          position={[-8.289, 1.148, 3.489]}
          renderOrder={0}
        />
        <mesh
          name="Plane109"
          geometry={nodes.Plane109.geometry}
          material={GarageClutterPileMat}
          position={[-9.383, 0.874, 4.46]}
          renderOrder={1}
        />
        <mesh
          name="Plane107"
          geometry={nodes.Plane107.geometry}
          material={GarageClutterSideMat}
          position={[-10.859, 1.378, 5.643]}
          renderOrder={0}
        />
      </group>
    </group>
  );
}
export const WTFN25_SPIN5_GarageJunkPlanes = () => {
  return <WTFN25_SPIN5_GarageJunkPlanesF></WTFN25_SPIN5_GarageJunkPlanesF>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_GarageJunkPlanes.glb");
