/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_SRPlanesBefore.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useAnimations, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Plane014: THREE.Mesh;
    Plane016: THREE.Mesh;
    Plane017: THREE.Mesh;
  };
  materials: {};
};

type ActionName = "Plane.014Action" | "Plane.016Action" | "Plane.017Action";
type GLTFActions = Record<ActionName, THREE.AnimationAction>;

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_SRPlanesBeforeF(
  props: JSX.IntrinsicElements["group"]
) {
  const group = useRef<THREE.Group>();
  const { nodes, materials, animations } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_SRPlanesBefore.glb"
  ) as GLTFResult;
  const { actions } = useAnimations(animations, group);

  const SRClutterBed = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_SRBeforePlaneBed.avif"
  );
  SRClutterBed.flipY = false;
  SRClutterBed.colorSpace = THREE.SRGBColorSpace;

  const SRClutterBack = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_SRBeforePlaneBack.avif"
  );
  SRClutterBack.flipY = false;
  SRClutterBack.colorSpace = THREE.SRGBColorSpace;

  const SRClutterFloor = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_SRBeforePlaneFloor.avif"
  );
  SRClutterFloor.flipY = false;
  SRClutterFloor.colorSpace = THREE.SRGBColorSpace;

  const SRClutterBedMat = new THREE.MeshStandardMaterial({
    map: SRClutterBed,
    transparent: true,
  });

  const SRClutterBackMat = new THREE.MeshStandardMaterial({
    map: SRClutterBack,
    transparent: true,
  });

  const SRClutterFloorMat = new THREE.MeshStandardMaterial({
    map: SRClutterFloor,
    transparent: true,
  });

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="Plane014"
          geometry={nodes.Plane014.geometry}
          material={SRClutterBedMat}
          position={[-2.889, 0.998, 4.246]}
          scale={0.877}
        />
        <mesh
          name="Plane016"
          geometry={nodes.Plane016.geometry}
          material={SRClutterFloorMat}
          position={[-3.863, 0.001, 5.39]}
        />
        <mesh
          name="Plane017"
          geometry={nodes.Plane017.geometry}
          material={SRClutterBackMat}
          position={[-2.536, 1.001, 5.547]}
          rotation={[Math.PI / 2, 0, 2.394]}
        />
      </group>
    </group>
  );
}
export const WTFN25_SPIN5_SRPlanesBefore = () => {
  return <WTFN25_SPIN5_SRPlanesBeforeF></WTFN25_SPIN5_SRPlanesBeforeF>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_SRPlanesBefore.glb");
