/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_BDVac3.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    VacuumStick: THREE.Mesh;
    VacuumMotor: THREE.Mesh;
    VacuumHead001: THREE.Mesh;
    VacuumHead: THREE.Mesh;
    VacuumHandle: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_BDVac3F(props: JSX.IntrinsicElements["group"]) {
  const { nodes } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_BDVac3.glb"
  ) as GLTFResult;

  const BDVac3D = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_BDVac3D.avif"
  );
  BDVac3D.flipY = false;
  BDVac3D.colorSpace = THREE.SRGBColorSpace;
  BDVac3D.channel = 1;
  const BDVac3Mat = new THREE.MeshBasicMaterial({
    map: BDVac3D,
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.VacuumStick.geometry}
        material={BDVac3Mat}
        position={[-1.822, 0, 2.483]}
        rotation={[0, -1.456, 0]}
        scale={1.702}
      />
      <mesh
        geometry={nodes.VacuumMotor.geometry}
        material={BDVac3Mat}
        position={[-1.822, 0, 2.483]}
        rotation={[0, -1.456, 0]}
        scale={1.702}
      />
      <mesh
        geometry={nodes.VacuumHead001.geometry}
        material={BDVac3Mat}
        position={[-1.822, 0, 2.483]}
        rotation={[0, -1.456, 0]}
        scale={1.702}
      />
      <mesh
        geometry={nodes.VacuumHead.geometry}
        material={BDVac3Mat}
        position={[-1.822, 0, 2.483]}
        rotation={[0, -1.456, 0]}
        scale={1.702}
      />
      <mesh
        geometry={nodes.VacuumHandle.geometry}
        material={BDVac3Mat}
        position={[-1.822, 0, 2.483]}
        rotation={[0, -1.456, 0]}
        scale={1.702}
      />
    </group>
  );
}
export const WTFN25_SPIN5_BDVac3 = () => {
  return <WTFN25_SPIN5_BDVac3F></WTFN25_SPIN5_BDVac3F>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_BDVac3.glb");
