/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_KmartTub.glb -t -r public 
*/

import * as THREE from "three";
import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Pick_Bin002: THREE.Mesh;
  };
  materials: {};
};

export function WTFN25_SPIN5_KmartTubF(props: JSX.IntrinsicElements["group"]) {
  const { nodes } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_KmartTub.glb"
  ) as GLTFResult;

  const KmartTubD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_KmartTubD.avif"
  );
  KmartTubD.flipY = false;
  KmartTubD.colorSpace = THREE.SRGBColorSpace;
  KmartTubD.channel = 1;
  const KmartTubMat = new THREE.MeshBasicMaterial({
    map: KmartTubD,
    side: THREE.DoubleSide,
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Pick_Bin002.geometry}
        material={KmartTubMat}
        position={[-8.264, 1.043, 4.886]}
      />
    </group>
  );
}

export const WTFN25_SPIN5_KmartTub = () => {
  return <WTFN25_SPIN5_KmartTubF></WTFN25_SPIN5_KmartTubF>;
};

useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_KmartTub.glb");
