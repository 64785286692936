/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_HallWalls.glb -t -r public 
*/

import * as THREE from "three";

import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Cube004: THREE.Mesh;
  };
  materials: {};
};

export function WTFN25_SPIN5_HallWallsF(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_HallWalls.glb"
  ) as GLTFResult;

  const HallWallsD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_HallWallsD.avif"
  );
  HallWallsD.flipY = false;
  HallWallsD.colorSpace = THREE.SRGBColorSpace;

  const HallWallsMat = new THREE.MeshBasicMaterial({
    map: HallWallsD,
  });

  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Cube004.geometry} material={HallWallsMat} />
    </group>
  );
}

export const WTFN25_SPIN5_HallWalls = () => {
  return <WTFN25_SPIN5_HallWallsF></WTFN25_SPIN5_HallWallsF>;
};

useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_HallWalls.glb");
