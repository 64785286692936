import useObjectsStore from "@/stores/useObjectsStore";
import { Environment } from "@react-three/drei";
import { Canvas, useThree } from "@react-three/fiber";
import {
  EffectComposer,
  Outline,
  Selection,
} from "@react-three/postprocessing";
import { useControls } from "leva";
import { KernelSize } from "postprocessing";

interface TestCanvas {
  children: React.ReactNode;
}

const PProcessing = () => {
  const { size } = useThree();

  const virtualObjects = useObjectsStore.use.virtualObjects();
  const selections = virtualObjects
    .filter((obj) => obj.outline)
    .map((obj) => obj.ref);

  return (
    <EffectComposer autoClear={false} enabled={selections.length > 0}>
      <Outline
        edgeStrength={10}
        visibleEdgeColor={0xffffff}
        width={size.width}
        height={size.height}
        blur={true}
        kernelSize={KernelSize.MEDIUM} // blur kernel size
      />
    </EffectComposer>
  );
};

export default function TestCanvas({ children }: TestCanvas) {
  const controls = useControls({
    backgroundColor: {
      value: "#000000",
    },
  });

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        position: "fixed",
        left: 0,
        top: 0,
      }}
    >
      <Canvas
        // dpr={[1, 1]}
        // frameloop="demand"
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: controls.backgroundColor,
        }}
        gl={{
          // powerPreference: "high-performance",
          antialias: true,
        }}
      >
        {/* Environment components */}
        <Environment files="/textures/Env.hdr" blur={0.07} />
        <directionalLight intensity={2} />
        <ambientLight position={[10, 10, 10]} />
        <Selection>
          <PProcessing />
          {children}
        </Selection>
      </Canvas>
    </div>
  );
}
