import VirtualObject from "@/components/3d/VirtualObject";
import * as Models from "@/components/sets/spin5/models";
// const Models = await import("@/components/sets/spin5/models");
import { SceneSegment } from "@/models/scene";
import { useRef } from "react";
import * as THREE from "three";

type ModelKeys = keyof typeof Models;
interface SegementProps extends SceneSegment {
  index?: number;
  active?: boolean; // if current scene and current segment
}

export default function Segment({
  objects,
}: // active = false,
// ...segment
SegementProps) {
  const groupRef = useRef<THREE.Group>();

  return (
    <group ref={groupRef}>
      {objects?.map((object, index) => {
        const Component = Models[object.name as ModelKeys];
        const name = `${object?.componentName || object?.name}`;
        const key = `${name}-${index}`;

        return (
          <VirtualObject key={key} {...object}>
            <Component />
          </VirtualObject>
        );
      })}
    </group>
  );
}
