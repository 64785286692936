import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

/**
 * Configure Firebase Environment based on domain
 */
var firebaseConfig: any = {};
var functionsDomain: string;
var tenantIdValue: string;

if (import.meta.env.REACT_APP_FIREBASE_KEY) {
  //Allow Environment File (.env) Override. See https://vitejs.dev/guide/env-and-mode.html
  firebaseConfig = {
    apiKey: import.meta.env.REACT_APP_FIREBASE_KEY,
    authDomain: import.meta.env.REACT_APP_FIREBASE_DOMAIN,
    projectId: import.meta.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: import.meta.env.REACT_APP_MESSAGING_APP_ID,
    measurementId: import.meta.env.REACT_APP_MEASUREMENT_ID,
  };

  functionsDomain = String(import.meta.env.REACT_APP_FUNCTIONS_DOMAIN);
  tenantIdValue = String(import.meta.env.REACT_APP_TENANT_ID);
} else {
  const hostname = document.location.hostname;

  /**
   * JY: added this so I can test locally (and others) without
   * having to change the code. This this should probably be
   * wrapped in a conditional that checks for the environment
   */
  if (hostname.includes("ngrok")) {
    console.log("/// use local ///");
    firebaseConfig = {
      apiKey: "AIzaSyDFFr-hFbtLvaRpj-qxYEDODzJHFidxoxQ",
      authDomain: "drawbridge-staging-us.firebaseapp.com",
      projectId: "drawbridge-staging-us",
      storageBucket: "drawbridge-staging-us.appspot.com",
      messagingSenderId: "865040534037",
      appId: "1:865040534037:web:ab78b3600c3d1ff6791c1c",
      measurementId: "G-XTTY50HB4L",
    };
    functionsDomain =
      "https://us-central1-drawbridge-staging-us.cloudfunctions.net";
    tenantIdValue = "Bridge-m3lzs";
  } else {
    // set the correct environment configuration
    switch (hostname) {
      // drawbridge-staging-us (US Central 1)
      case "staging.drawbridgelabs.app":
        firebaseConfig = {
          apiKey: "AIzaSyDFFr-hFbtLvaRpj-qxYEDODzJHFidxoxQ",
          authDomain: "drawbridge-staging-us.firebaseapp.com",
          projectId: "drawbridge-staging-us",
          storageBucket: "drawbridge-staging-us.appspot.com",
          messagingSenderId: "865040534037",
          appId: "1:865040534037:web:ab78b3600c3d1ff6791c1c",
          measurementId: "G-XTTY50HB4L",
        };
        functionsDomain =
          "https://us-central1-drawbridge-staging-us.cloudfunctions.net";
        tenantIdValue = "Bridge-m3lzs";
        break;

      // LOCAL Environment for development using local functions API and drawbridge-staging-us firebase environment
      case "localhost":
      case "promoted-spider-moving.ngrok-free.app": // Kevin's ngrok tunnel
        firebaseConfig = {
          apiKey: "AIzaSyDFFr-hFbtLvaRpj-qxYEDODzJHFidxoxQ",
          authDomain: "drawbridge-staging-us.firebaseapp.com",
          projectId: "drawbridge-staging-us",
          storageBucket: "drawbridge-staging-us.appspot.com",
          messagingSenderId: "865040534037",
          appId: "1:865040534037:web:ab78b3600c3d1ff6791c1c",
          measurementId: "G-XTTY50HB4L",
        };

        // Staging functions API if not running functions locally
        functionsDomain =
          "https://us-central1-drawbridge-staging-us.cloudfunctions.net";

        // Local Functions API if running functions server locally
        // functionsDomain =
        //   "http://127.0.0.1:5001/drawbridge-staging-us/us-central1";

        tenantIdValue = "Bridge-m3lzs";
        break;

      // drawbridge-production-aus (Australia Southeast 1)
      case "aus.drawbridgelabs.app":
        firebaseConfig = {
          apiKey: "AIzaSyAqloulEKoKb33CaDI1hGqOjerQxxk_pHk",
          authDomain: "drawbridge-production-aus.firebaseapp.com",
          projectId: "drawbridge-production-aus",
          storageBucket: "drawbridge-production-aus.appspot.com",
          messagingSenderId: "823184730809",
          appId: "1:823184730809:web:f345329b964ae8fa988968",
          measurementId: "G-V9TH4G3TNF",
        };
        functionsDomain =
          "https://australia-southeast1-drawbridge-production-aus.cloudfunctions.net";
        tenantIdValue = "Bridge-7nma1";
        break;

      // drawbridge-production-us (US Central 1)
      case "drawbridgelabs.app":
      case "us.drawbridgelabs.app":
      default: // SUPPORT ANY DOMAIN POINTING TO THE PRODUCTION ENVIRONMENT
        firebaseConfig = {
          apiKey: "AIzaSyBpk0GTdSimsJXrN2h8gXofQ-IQHYQjSNM",
          authDomain: "drawbridge-production-us.firebaseapp.com",
          projectId: "drawbridge-production-us",
          storageBucket: "drawbridge-production-us.appspot.com",
          messagingSenderId: "591343184756",
          appId: "1:591343184756:web:f9cd2336317e453408da21",
          measurementId: "G-D2V3GS0K8W",
        };
        functionsDomain =
          "https://us-central1-drawbridge-production-us.cloudfunctions.net";
        tenantIdValue = "Bridge-cmsbj";
        break;
    }
  }
}

const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);

const authWithTenant = getAuth(app);
authWithTenant.tenantId = tenantIdValue;
export const auth = authWithTenant;

export const db = getFirestore(app);
export const storage = getStorage(app);
export const functionsUrl: string = `${functionsDomain}/v1`;
export const tenantId: string = tenantIdValue;
