/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_CarpetFECorinSwatch.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { CARPET_KEY } from "@/components/sets/spin5/models/scenes/master-bed/setObjects/WTFN25_SPIN5_Carpet";
import useInteractionsStore from "@/stores/useInteractionsStore";

type GLTFResult = GLTF & {
  nodes: {
    Plane020: THREE.Mesh;
  };
  materials: object;
};

export function WTFN25_SPIN5_CarpetFECorinSwatchF(
  props: JSX.IntrinsicElements["group"]
) {
  const { nodes, materials } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_CarpetFECorinSwatch.glb"
  ) as GLTFResult;

  const addEvent = useInteractionsStore.use.addEvent();

  const CarpetFECorinSwatchD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_CarpetFECorinSwatchD.avif"
  );
  CarpetFECorinSwatchD.flipY = false;
  CarpetFECorinSwatchD.colorSpace = THREE.SRGBColorSpace;
  CarpetFECorinSwatchD.channel = 1;
  const CarpetFECorinSwatchMat = new THREE.MeshBasicMaterial({
    map: CarpetFECorinSwatchD,
  });

  return (
    <group
      {...props}
      onClick={() => {
        addEvent(CARPET_KEY, { value: "corin" });
      }}
      dispose={null}
    >
      <mesh
        geometry={nodes.Plane020.geometry}
        material={CarpetFECorinSwatchMat}
        position={[-0.661, 1.245, 3.673]}
        rotation={[3.109, -1.011, -2.707]}
        scale={0.669}
      />
    </group>
  );
}
export const WTFN25_SPIN5_CarpetFECorinSwatch = () => {
  return (
    <WTFN25_SPIN5_CarpetFECorinSwatchF></WTFN25_SPIN5_CarpetFECorinSwatchF>
  );
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_CarpetFECorinSwatch.glb");
