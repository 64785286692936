/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_KitchenLights.glb -t -r public 
*/

import * as THREE from "three";

import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Sphere: THREE.Mesh;
  };
  materials: {};
};

export function WTFN25_SPIN5_KitchenLightsF(
  props: JSX.IntrinsicElements["group"]
) {
  const { nodes } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_KitchenLights.glb"
  ) as GLTFResult;

  const KitchenLightsD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_KitchenLightsD.avif"
  );
  KitchenLightsD.flipY = false;
  KitchenLightsD.colorSpace = THREE.SRGBColorSpace;

  const KitchenLightsMat = new THREE.MeshBasicMaterial({
    map: KitchenLightsD,
    side: THREE.DoubleSide,
  });

  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Sphere.geometry} material={KitchenLightsMat} />
    </group>
  );
}

export const WTFN25_SPIN5_KitchenLights = () => {
  return <WTFN25_SPIN5_KitchenLightsF></WTFN25_SPIN5_KitchenLightsF>;
};

useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_KitchenLights.glb");
