import { Plane, useVideoTexture } from "@react-three/drei";
import { useEffect, useMemo, useRef, useState } from "react";
import { ShaderMaterial, Vector3 } from "three";
import fragmentShader from "./glsl/fragment.glsl";
import vertexShader from "./glsl/vertex.glsl";

interface AlphaVideoPlane {
  autoStart?: boolean;
  loop?: boolean;
  src?: string;
  demo?: boolean;
  muted?: boolean;
  flipY?: boolean;
  onComplete?: () => void | null;
}

export default function AlphaVideoPlane({
  loop = false,
  autoStart = true,
  src = "/videos/host-green.mp4",
  demo = false,
  muted = false,
  flipY = false,
  onComplete = null,
}: AlphaVideoPlane) {
  // Load the video texture
  const videoTexture = useVideoTexture(src, {
    start: autoStart,
    loop,
    muted,
    onended: () => {
      onComplete && onComplete();
    },
  });

  videoTexture.flipY = flipY;
  const planeRef = useRef(null);

  // State to hold the video's natural dimensions
  const [dimensions, setDimensions] = useState({ width: 1, height: 1 });

  useEffect(() => {
    // Ensure the video element exists
    const video = videoTexture.image as HTMLVideoElement | undefined;
    if (!video) return;

    const handleLoadedMetadata = () => {
      setDimensions({ width: video.videoWidth, height: video.videoHeight });
    };

    // Listen for metadata loading
    video.addEventListener("loadedmetadata", handleLoadedMetadata);

    // If the metadata is already loaded, update immediately
    if (video.readyState >= 1) {
      handleLoadedMetadata();
    }

    return () =>
      video.removeEventListener("loadedmetadata", handleLoadedMetadata);
  }, [videoTexture]);

  const rotoMaterial = useMemo(() => {
    return new ShaderMaterial({
      uniforms: {
        uAlphaColor: { value: new Vector3(0.0, 1.0, 0.0) },
        uTexture: { value: videoTexture },
      },
      vertexShader,
      fragmentShader,
      transparent: true,
      opacity: 1,
    });
  }, [videoTexture]);

  // Calculate the aspect ratio so you can size the plane appropriately.
  const aspectRatio = dimensions.width / dimensions.height;
  const planeWidth = 2; // Choose a desired width
  const planeHeight = planeWidth / aspectRatio;

  const bgWidth = 5821 / 1000;
  const bgHeight = 7825 / 1000;

  if (demo)
    return (
      <group>
        <Plane
          position={[0, 0, 0]}
          scale={[1, 1, 1]}
          args={[bgWidth, bgHeight, 10, 10]}
          material-color="red"
          material-wireframe
        />
        <Plane
          ref={planeRef}
          position={[0, 0, 0]}
          scale={[planeWidth, planeHeight, 1]}
          args={[1, 1, 1, 1]}
        >
          <primitive object={rotoMaterial} />
        </Plane>
      </group>
    );

  return <primitive object={rotoMaterial} />;
}
