/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_GarageStatic.glb -t -r public 
*/

import { useGLTF, useTexture } from "@react-three/drei";
import * as THREE from "three";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Object_7: THREE.Mesh;
    Object_2002: THREE.Mesh;
    Object019_Guitar_Case_0: THREE.Mesh;
    Medium_Heavy_Duty_Box002: THREE.Mesh;
  };
  // materials: {}; // not valid TS
  materials: object;
};

// not being used?
// type ContextType = Record<
//   string,
//   React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
// >;

export function WTFN25_SPIN5_GarageBeforeStaticF(
  props: JSX.IntrinsicElements["group"]
) {
  const { nodes } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_GarageBeforeStatic.glb"
  ) as GLTFResult;

  const GarageStaticD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_GarageBeforeStaticD.avif"
  );
  GarageStaticD.flipY = false;
  GarageStaticD.colorSpace = THREE.SRGBColorSpace;
  GarageStaticD.channel = 1;
  const GarageStaticMat = new THREE.MeshBasicMaterial({
    map: GarageStaticD,
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Object_7.geometry}
        material={GarageStaticMat}
        position={[-9.407, 0.236, 3.194]}
      />
      <mesh
        geometry={nodes.Object_2002.geometry}
        material={GarageStaticMat}
        position={[-10.77, 0.011, 4.962]}
      />
      <mesh
        geometry={nodes.Object019_Guitar_Case_0.geometry}
        material={GarageStaticMat}
        position={[-9.066, 0.011, 3.147]}
      />
      <mesh
        geometry={nodes.Medium_Heavy_Duty_Box002.geometry}
        material={GarageStaticMat}
        position={[-11.639, 0, 4.426]}
        rotation={[0, 0.891, 0]}
      />
    </group>
  );
}
export const WTFN25_SPIN5_GarageBeforeStatic = () => {
  return <WTFN25_SPIN5_GarageBeforeStaticF></WTFN25_SPIN5_GarageBeforeStaticF>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_GarageStatic.glb");
