import useCameraFunctions from "@/components/hooks/useCameraFunctions";
import useObjectsStore, { VirtualSceneObject } from "@/stores/useObjectsStore";
import { Plane, useFBO } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { useControls } from "leva";
import { useCallback, useEffect, useMemo, useRef } from "react";
import * as THREE from "three";
import expandedFragment from "./glsl/expanded/fragment.glsl";
import expandedVertex from "./glsl/expanded/vertex.glsl";
import hudFragment from "./glsl/hud/fragment.glsl";
import hudVertex from "./glsl/hud/vertex.glsl";

/**
 * A component that renders a HUD (Heads-Up Display) for virtual objects in a 3D scene.
 * It creates a glow effect around virtual objects by rendering them to a separate texture
 * and applying a blur shader.
 *
 * @component
 * @param {Object} props - Component props
 * @param {number} [props.captureLayer=10] - The layer number to capture virtual objects from.
 *                                          Objects in this layer will be rendered with the glow effect.
 *
 * @remarks
 * The component uses:
 * - A render target (FBO) to capture the virtual objects
 * - A custom shader material for the glow effect
 * - Leva controls for real-time adjustment of glow parameters
 * - Two planes: one for the original render target and one for the blur effect
 *
 * @example
 * ```tsx
 * <VirtualObjectHud captureLayer={5} />
 * ```
 *
 * @returns A HUD component that renders the virtual objects with a glow effect
 */
export default function VirtualObjectHud({
  captureLayer = 10, // Default layer to capture
}: {
  captureLayer?: number;
}) {
  const { gl, scene, camera, size } = useThree();
  const virtualObjects = useObjectsStore.use.virtualObjects();
  const setRenderTarget = useObjectsStore.use.setRenderTarget();
  const setOutlinesTarget = useObjectsStore.use.setOutlinesTarget();
  const setObjectsTarget = useObjectsStore.use.setObjectsTarget();
  const cameraParentRef = useRef<THREE.Group>(null);
  const planeRef = useRef<THREE.Mesh>(null);
  const outlinesPlaneRef = useRef<THREE.Mesh>(null);
  const testPlaneRef = useRef<THREE.Mesh>(null);
  // const mainCamera = camera as THREE.PerspectiveCamera;
  const { scaleFromDistance } = useCameraFunctions();

  useEffect(() => {
    camera.near = 0.005;
    camera.far = 10;
    camera.updateProjectionMatrix();
    // const glContext = gl.getContext();
    // if (glContext) {
    //   glContext.enable(glContext.STENCIL_TEST);
    // }
  }, [gl, camera]);

  useControls(() => ({
    frameDuration: {
      value: "",
    },
    expandAmount: {
      value: 1,
      min: 0.9,
      max: 1.1,
      step: 0.001,
      onChange: (value) => {
        expandShaderMaterial.uniforms.uExpandAmount.value = value * 1;
      },
    },
    glowAmount: {
      value: 1,
      min: 0.01,
      max: 10,
      step: 0.001,
      onChange: (value) => {
        blurShaderMaterial.uniforms.uGlowAmount.value = value * 0.001;
      },
    },
    glowSoftness: {
      value: 0.24,
      min: 0.1,
      max: 1,
      step: 0.01,
      onChange: (value) => {
        blurShaderMaterial.uniforms.uGlowSoftness.value = value as number;
      },
    },
    glowColor: {
      value: "#ffffff",
      onChange: (value) => {
        blurShaderMaterial.uniforms.uGlowColor.value = new THREE.Color(
          value as string
        );
      },
    },
  }));

  const renderTarget = useFBO(size.width, size.height, {
    minFilter: THREE.NearestFilter,
    magFilter: THREE.NearestFilter,
    format: THREE.RGBAFormat,
    colorSpace: THREE.LinearSRGBColorSpace,
    stencilBuffer: true,
  });

  const outlinesTarget = useFBO(size.width, size.height, {
    minFilter: THREE.NearestFilter,
    magFilter: THREE.NearestFilter,
    format: THREE.RGBAFormat,
    colorSpace: THREE.LinearSRGBColorSpace,
    depth: true,
    stencilBuffer: true,
  });

  const objectsTarget = useFBO(size.width, size.height, {
    minFilter: THREE.NearestFilter,
    magFilter: THREE.NearestFilter,
    format: THREE.RGBAFormat,
    colorSpace: THREE.LinearSRGBColorSpace,
    depth: true,
  });

  useEffect(() => {
    renderTarget.setSize(size.width, size.height);
    outlinesTarget.setSize(size.width, size.height);
    objectsTarget.setSize(size.width, size.height);
    setRenderTarget(renderTarget);
    setOutlinesTarget(outlinesTarget);
    setObjectsTarget(objectsTarget);
  }, [
    renderTarget,
    objectsTarget,
    outlinesTarget,
    setRenderTarget,
    setObjectsTarget,
    setOutlinesTarget,
    size.height,
    size.width,
  ]);

  const expandShaderMaterial = useMemo(() => {
    return new THREE.ShaderMaterial({
      uniforms: {
        uExpandAmount: { value: 0.01 },
      },
      vertexShader: expandedVertex,
      fragmentShader: expandedFragment,
      transparent: true,
      opacity: 1,
    });
  }, []);

  const blurShaderMaterial = useMemo(() => {
    return new THREE.ShaderMaterial({
      uniforms: {
        uExpandAmount: { value: 10.5 },
        uOutlinesTexture: { value: outlinesTarget.texture },
        uObjectsTexture: { value: objectsTarget.texture },
        uGlowAmount: { value: 1 }, // Control the intensity of the blur
        uGlowColor: { value: new THREE.Color("#0000ff") },
        uGlowSoftness: { value: 0.24 },
      },
      vertexShader: hudVertex,
      fragmentShader: hudFragment,
      transparent: true,
    });
  }, [objectsTarget.texture, outlinesTarget.texture]);
  // const blurShaderMaterial = useMemo(() => {
  //   return new THREE.MeshBasicMaterial({
  //     map: outlinesTarget.texture,
  //     transparent: true,
  //   });
  // }, [objectsTarget.texture, outlinesTarget.texture]);

  // TODO: toggle set layer for outlines
  const setLayers = useCallback(
    ({
      objects,
      layer,
    }: // stencil = false,
    // expand = 0,
    {
      objects: VirtualSceneObject[];
      layer: number;
      stencil?: boolean;
      expand?: number;
    }) => {
      // Recursively set the layer on all children
      const setLayerRecursively = (obj: THREE.Object3D) => {
        if (obj instanceof THREE.Mesh) {
          obj.layers.set(layer);

          // if (obj.material) {
          //   const mesh = obj as THREE.Mesh;

          //   // Ensure it's a material that supports stencil properties
          //   if (Array.isArray(mesh.material)) {
          //     mesh.material.forEach((mat) => applyStencilSettings(mat));
          //   } else {
          //     applyStencilSettings(mesh.material);
          //   }
          // }
        }

        obj.children.forEach((child) => setLayerRecursively(child));
      };

      // Helper function to apply stencil settings
      // const applyStencilSettings = (material: THREE.Material) => {
      //   material.stencilWrite = stencil;
      //   material.stencilRef = stencil ? 1 : 0;
      //   material.stencilFunc = THREE.AlwaysStencilFunc;
      //   // material.stencilZPass = THREE.ReplaceStencilOp;

      //   // material.polygonOffset = expand !== 0;
      //   material.polygonOffset = true;
      //   material.polygonOffsetFactor = expand; // Push outward slightly
      // };

      objects.forEach((obj) => {
        setLayerRecursively(obj.object);
      });
    },
    []
  );

  const normalMaterial = useMemo(
    () => new THREE.MeshBasicMaterial({ color: 0xff0000 }),
    []
  );

  const outlinedMaterial = useMemo(
    () => new THREE.MeshBasicMaterial({ color: 0xffffff }),
    []
  );

  const setColors = useCallback(
    (
      objects: VirtualSceneObject[],
      originalMaterials: Map<THREE.Mesh, THREE.Material | THREE.Material[]>,
      reset = false
    ) => {
      objects.forEach((sceneObject) => {
        sceneObject.object.traverse((child) => {
          if ((child as THREE.Mesh).isMesh) {
            const mesh = child as THREE.Mesh;
            const material = mesh.material;

            if (reset) {
              // if resetting, restore original material
              const originalMaterial = originalMaterials.get(mesh);
              if (originalMaterial) {
                mesh.material = originalMaterial;
              }
            } else {
              // if setting to fbo store material for reset in map
              mesh.material = sceneObject.outline
                ? outlinedMaterial
                : normalMaterial;
              originalMaterials.set(mesh, material); // Store original material
            }
          }
        });
      });
    },
    [normalMaterial, outlinedMaterial]
  );

  // Memoized shadow material for performance
  // const shadowMaterial = useMemo(
  //   () =>
  //     new THREE.MeshBasicMaterial({
  //       color: "black",
  //       // transparent: true,
  //     }),
  //   []
  // );

  // const outlineObjects = useMemo(() => {
  //   return virtualObjects.filter((obj) => obj.outline);
  // }, [virtualObjects]);

  // const normalObjects = useMemo(() => {
  //   return virtualObjects.filter((obj) => !obj.outline);
  // }, [virtualObjects]);

  const { scale: planeScale } = scaleFromDistance(1);

  useFrame(() => {
    if (!planeRef?.current || !cameraParentRef?.current) return;

    // position cameraParentRef to act like HUD
    cameraParentRef.current.position.copy(camera.position);
    cameraParentRef.current.quaternion.copy(camera.quaternion);

    // store original materials before setting to white/black
    const originalMaterials = new Map<
      THREE.Mesh,
      THREE.Material | THREE.Material[]
    >();

    setLayers({
      objects: virtualObjects,
      layer: captureLayer,
    });

    camera.layers.disableAll();
    camera.layers.enable(captureLayer);

    gl.clear();
    gl.setRenderTarget(renderTarget);
    setColors(virtualObjects, originalMaterials);
    gl.render(scene, camera);
    gl.setRenderTarget(null);

    setColors(virtualObjects, originalMaterials, true);
    setLayers({ objects: virtualObjects, layer: 0 });
    camera.layers.mask = 0;
    camera.layers.enableAll();

    // camera.layers.disableAll();
    // camera.layers.enable(captureLayer);

    // gl.setRenderTarget(objectsTarget);
    // gl.clear();

    // // setLayers({
    // //   objects: normalObjects,
    // //   layer: 0,
    // //   stencil: true,
    // //   expand: 0,
    // // });

    // // scene.overrideMaterial = normalMaterial; // ✅ Apply stencil writing material
    // gl.render(scene, camera);
    // // scene.overrideMaterial = null; // Restore original materials

    // gl.setRenderTarget(null);

    // // Step 2: Render outlines using stencil mask
    // // setLayers({ objects: virtualObjects, layer: 0, expand: 0, stencil: true });
    // setLayers({
    //   objects: normalObjects,
    //   layer: 0,
    //   stencil: true,
    //   expand: 0,
    // });
    // setLayers({
    //   objects: outlineObjects,
    //   layer: captureLayer,
    //   stencil: true,
    //   expand: 0,
    // });
    // camera.layers.disableAll();
    // camera.layers.enable(captureLayer);

    // gl.setRenderTarget(outlinesTarget);
    // gl.clear();

    // // scene.overrideMaterial = blurShaderMaterial; // ✅ Now apply outline shader
    // gl.render(scene, camera);

    // all done
    // gl.setRenderTarget(null);

    // Restore normal layers
    // setLayers({ objects: virtualObjects, layer: 0, expand: 0, stencil: false });
    // camera.layers.mask = 0;
    // camera.layers.enableAll();
  });

  const resolution = useMemo(() => {
    const aspect = size.width / size.height;
    const width = Math.min(size.width / 10, 60);
    const height = width / aspect;
    return { width, height };
  }, [size]);

  // const planeScale = useMemo(() => {
  //   const { scale } = scaleFromZ(0);
  //   return scale;
  // }, [scaleFromZ]);

  // console.log("viewport.width:", viewport.width);

  return (
    // <Hud renderPriority={1}>
    //   <Environment files="/textures/Env.hdr" blur={0.07} />
    //   <directionalLight intensity={2} />
    //   <ambientLight position={[10, 10, 10]} />
    //   <PerspectiveCamera
    //     makeDefault
    //     fov={mainCamera.fov} // Sync FOV
    //     aspect={mainCamera.aspect} // Sync aspect ratio
    //     position={mainCamera.position.toArray()} // Sync position
    //     near={mainCamera.near} // Match near clipping plane
    //     far={mainCamera.far} // Match far clipping plane
    //   />
    <group ref={cameraParentRef}>
      <Plane
        ref={testPlaneRef}
        position={[0, 0, 1]}
        // scale={[planeScale, planeScale, 1]}
        args={[size.width, size.height, 4, 4]}
        material-color="red"
        material-wireframe
      />
      <Plane
        ref={outlinesPlaneRef}
        position={[0, 0, -1]}
        scale={[planeScale, planeScale, 1]}
        args={[size.width, size.height, resolution.width, resolution.height]}
        // renderOrder={1}
      >
        <meshBasicMaterial
          map={renderTarget.texture}
          transparent
          opacity={1}
          // stencilRef={1}
          // depthWrite={false}
          // depthTest={false}
        />
      </Plane>
      <Plane
        ref={planeRef}
        position={[0, 0, -1]}
        scale={[planeScale, planeScale, 1]}
        args={[size.width, size.height, resolution.width, resolution.height]}
        // renderOrder={2}
      >
        <primitive object={blurShaderMaterial} stencilRef={1} />
      </Plane>
    </group>
    // </Hud>
  );
}
