/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_Carpet.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef, useMemo } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import useInteractionsStore from "@/stores/useInteractionsStore";

type GLTFResult = GLTF & {
  nodes: {
    Plane002: THREE.Mesh;
  };
  materials: unknown;
};

export const CARPET_KEY = "carpet";

// type ContextType = Record<
//   string,
//   React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
// >;
export function WTFN25_SPIN5_CarpetF({
  // materialIndex = 0,
  ...props
}: THREE.Group) {
  const events = useInteractionsStore.use.events();

  const { nodes } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_Carpet.glb"
  ) as GLTFResult;

  // Load Textures
  const CarpetFECorinD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_CarpetFECorinD.avif"
  );
  const CarpetFEOberonD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_CarpetFEOberonD.avif"
  );

  [CarpetFECorinD, CarpetFEOberonD].forEach((texture) => {
    texture.flipY = false;
    texture.colorSpace = THREE.SRGBColorSpace;
    texture.channel = 1;
  });

  // const CarpetFEMat = new THREE.MeshBasicMaterial({
  //   map: CarpetFEOberonD,
  // });
  const carpetMaterials = useMemo(() => {
    return new Map([
      ["corin", CarpetFECorinD],
      ["oberon", CarpetFEOberonD],
    ]);
  }, [CarpetFECorinD, CarpetFEOberonD]);

  // Dynamically select material based on `materialIndex`
  const selectedTexture = useMemo(() => {
    const event = events.get(CARPET_KEY);
    if (event?.value) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return carpetMaterials.get(event.value) ?? carpetMaterials.get("corin");
    }

    return carpetMaterials.get("corin"); // Fallback texture
  }, [carpetMaterials, events]);

  const CarpetFEMat = useMemo(
    () => new THREE.MeshBasicMaterial({ map: selectedTexture }),
    [selectedTexture]
  );

  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Plane002.geometry} material={CarpetFEMat} />
    </group>
  );
}
export const WTFN25_SPIN5_Carpet = () => {
  return <WTFN25_SPIN5_CarpetF></WTFN25_SPIN5_CarpetF>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_Carpet.glb");
