import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useAnimations, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    SoftBagShapekey: THREE.Mesh;
    SoftBagShapekey001: THREE.Mesh;
    SoftBagShapekey002: THREE.Mesh;
  };
  materials: {};
};

type ActionName =
  | "SoftBag.ShapekeyAction"
  | "SoftBag.ShapekeyAction.001"
  | "SoftBag.ShapekeyAction.002";
type GLTFActions = Record<ActionName, THREE.AnimationAction>;

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_StanleyBag2F(
  props: JSX.IntrinsicElements["group"]
) {
  const group = useRef<THREE.Group>();
  const { nodes, materials, animations } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_StanleyBag.glb"
  ) as GLTFResult;
  const { actions } = useAnimations(animations, group);

  const StanleyBagD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_StanleyBagD.avif"
  );
  StanleyBagD.flipY = false;
  StanleyBagD.colorSpace = THREE.SRGBColorSpace;
  StanleyBagD.channel = 1;
  const StanleyBagMat = new THREE.MeshBasicMaterial({
    map: StanleyBagD,
    side: THREE.DoubleSide,
  });

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="SoftBagShapekey"
          geometry={nodes.SoftBagShapekey.geometry}
          material={StanleyBagMat}
          position={[-8.331, 0.975, 3.401]}
          rotation={[0, -0.67, 0]}
        />
        <mesh
          name="SoftBagShapekey001"
          geometry={nodes.SoftBagShapekey001.geometry}
          material={StanleyBagMat}
          position={[-8.331, 0.975, 3.401]}
          rotation={[0, -0.67, 0]}
        />
        <mesh
          name="SoftBagShapekey002"
          geometry={nodes.SoftBagShapekey002.geometry}
          material={StanleyBagMat}
          position={[-8.331, 0.975, 3.401]}
          rotation={[0, -0.67, 0]}
        />
      </group>
    </group>
  );
}
export const WTFN25_SPIN5_StanleyBag = () => {
  return <WTFN25_SPIN5_StanleyBag2F></WTFN25_SPIN5_StanleyBag2F>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_StanleyBag.glb");
