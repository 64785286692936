/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_KmartMushroom.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { Environment, useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Cube087: THREE.Mesh;
    Cube087_1: THREE.Mesh;
    Cube087_2: THREE.Mesh;
    Cube087_3: THREE.Mesh;
    Cube019: THREE.Mesh;
    Cube019_1: THREE.Mesh;
    Cube019_2: THREE.Mesh;
    Cube019_3: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_KmartMushroomF(
  props: JSX.IntrinsicElements["group"]
) {
  const { nodes } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_KmartMushroom.glb"
  ) as GLTFResult;

  const KmartMushroomBlueMat = new THREE.MeshBasicMaterial({
    color: "#9AC3BA",
  });

  const KmartMushroomEmissionMat = new THREE.MeshBasicMaterial({
    color: "white",
  });

  const KmartMushroomYellowMat = new THREE.MeshStandardMaterial({
    color: "#C3AD2A",
  });

  const KmartMushroomSteel = new THREE.MeshStandardMaterial({
    metalness: 1,
    roughness: 0.2,
  });

  return (
    <group {...props} dispose={null}>
      <group position={[3.247, 0.55, 2.321]} rotation={[0, -Math.PI / 2, 0]}>
        {/* <pointLight intensity={5} color={"orange"} position={[0, 0.1, 0]} /> */}
        <mesh
          geometry={nodes.Cube087.geometry}
          material={KmartMushroomBlueMat}
        />
        <mesh
          geometry={nodes.Cube087_1.geometry}
          material={KmartMushroomEmissionMat}
        />
        <mesh
          geometry={nodes.Cube087_2.geometry}
          material={KmartMushroomYellowMat}
        />
        <mesh
          geometry={nodes.Cube087_3.geometry}
          material={KmartMushroomSteel}
        />
      </group>
      <group position={[3.189, 0.55, 4.665]} rotation={[0, -Math.PI / 2, 0]}>
        {/* <pointLight intensity={5} color={"orange"} position={[0, 0.1, 0]} /> */}
        <mesh
          geometry={nodes.Cube019.geometry}
          material={KmartMushroomBlueMat}
        />
        <mesh
          geometry={nodes.Cube019_1.geometry}
          material={KmartMushroomEmissionMat}
        />
        <mesh
          geometry={nodes.Cube019_2.geometry}
          material={KmartMushroomYellowMat}
        />
        <mesh
          geometry={nodes.Cube019_3.geometry}
          material={KmartMushroomSteel}
        />
      </group>
    </group>
  );
}

export const WTFN25_SPIN5_KmartMushroom = () => {
  return <WTFN25_SPIN5_KmartMushroomF></WTFN25_SPIN5_KmartMushroomF>;
};

useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_KmartMushroom.glb");
