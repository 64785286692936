import {
  DocumentData,
  Query,
  QueryConstraint,
  Timestamp,
  collection,
  doc,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { auth, db, functionsUrl } from "../firebase/firebase";
import { collectionData, docData } from "rxfire/firestore";
import { Observable, map, of, sample } from "rxjs";
import DataTransformationService from "./data-transformation.service";
import { ChatMessage } from "@/models/chat-message";
import axios from "axios";

function getOne(id: string): Observable<ChatMessage> {
  if (!id) return of(undefined);
  const docRef = doc(db, `chatMessages/${id}`);
  return docData(docRef).pipe(
    map((firestoreDocument) =>
      DataTransformationService.convertTimestampsToIsoString(firestoreDocument)
    )
  );
}

type SaveOneResponse = {
  textResponse: string[];
  audioResponse: string;
  aiSessionId: string;
};

async function saveOneWithAudio(
  brandId: string,
  experienceId: string,
  aiSessionId: string | null,
  audioMessage: { base64: string; mimeType: string; sampleRateHertz: number }
): Promise<SaveOneResponse> {
  try {
    if (!brandId || !experienceId || !audioMessage)
      throw new Error("Invalid chat message");

    //configure axios client with bearer token
    const token = await auth.currentUser.getIdToken();
    const client = axios.create({
      baseURL: `${functionsUrl}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    //get signed url from firebase function
    const response = await client.post(
      `brand/${brandId}/chats/experience/${experienceId}/audio-message`,
      {
        aiSessionId,
        base64Audio: audioMessage.base64,
        mimeType: audioMessage.mimeType,
        sampleRateHertz: audioMessage.sampleRateHertz,
      }
    );

    return response.data;
  } catch (err) {
    throw err;
  }
}

async function saveOneWithText(
  brandId: string,
  experienceId: string,
  aiSessionId: string | null,
  textMessage: string
): Promise<SaveOneResponse> {
  try {
    if (!brandId || !experienceId || !textMessage)
      throw new Error("Invalid chat message");

    //configure axios client with bearer token
    const token = await auth.currentUser.getIdToken();
    const client = axios.create({
      baseURL: `${functionsUrl}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    //get signed url from firebase function
    const response = await client.post(
      `brand/${brandId}/chats/experience/${experienceId}/text-message`,
      {
        aiSessionId,
        message: textMessage,
      }
    );

    return response.data;
  } catch (err) {
    throw err;
  }
}

async function getIntroduction(
  brandId: string,
  experienceId: string
): Promise<{ textResponse: string[]; audioResponse: string }> {
  try {
    if (!brandId || !experienceId)
      throw new Error("Invalid brand or experience");

    //configure axios client with bearer token
    const token = await auth.currentUser.getIdToken();
    const client = axios.create({
      baseURL: `${functionsUrl}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    //get signed url from firebase function
    const response = await client.get(
      `brand/${brandId}/chats/experience/${experienceId}/introduction`
    );

    return response.data;
  } catch (err) {
    throw err;
  }
}

function getAllByAiSession(
  tenantId: string,
  experienceId: string,
  userAccountId: string,
  aiSessionId: string
): Observable<ChatMessage[]> {
  if (tenantId && userAccountId && experienceId && aiSessionId) {
    const collectionRef = collection(db, "chatMessages");

    const queryConstraint: QueryConstraint[] = [
      where("tenantId", "==", tenantId),
      where("userAccountId", "==", userAccountId),
      where("experienceId", "==", experienceId),
      where("aiSessionId", "==", aiSessionId),
      where("deleted", "==", false),
    ];

    const collectionQuery: Query<DocumentData> = query(
      collectionRef,
      ...queryConstraint
    );

    return collectionData(collectionQuery).pipe(
      map((collection) => {
        //sort by createdDate
        collection.sort(
          (a, b) =>
            (a.createdDate && a.createdDate instanceof Timestamp
              ? a.createdDate.seconds
              : 99999999999) -
            (b.createdDate && b.createdDate instanceof Timestamp
              ? b.createdDate.seconds
              : 99999999999)
        );

        //convert timestamps to Date
        collection = collection.map((firestoreDocument) =>
          DataTransformationService.convertTimestampsToIsoString(
            firestoreDocument
          )
        );
        return collection;
      })
    );
  } else {
    return of([]);
  }
}

const ChatMessageService = {
  getOne,
  saveOneWithAudio,
  saveOneWithText,
  getAllByAiSession,
  getIntroduction,
};

export default ChatMessageService;
