/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_DaikinPhone.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef, useState } from "react";
import { useGLTF, useAnimations, useTexture, Float } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Cube027: THREE.Mesh;
  };
  materials: object;
};

type ActionName = "Cube.027Action.001";
type GLTFActions = Record<ActionName, THREE.AnimationAction>;

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

type DaikinPhoneProps = JSX.IntrinsicElements["group"] & {
  floating?: boolean; // optional prop to control the floating effect
  center?: boolean; // optional prop to center the model
  supressClicks?: boolean; // optional prop to supress the click event
};

export function WTFN25_SPIN5_DaikinPhoneF({
  floating = true,
  // props: JSX.IntrinsicElements["group"]
  ...props
}: DaikinPhoneProps) {
  const group = useRef<THREE.Group>(null);

  const { nodes, animations } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_DaikinPhone.glb"
  ) as unknown as GLTFResult;
  // const { actions } = useAnimations<GLTFActions>(animations, group); TODO: TEMP TYPESCRIP FIX
  const { actions } = useAnimations(animations, group);
  const [isFloating, setIsFloating] = useState(floating);
  const [hasPlayed, setHasPlayed] = useState(false);

  const DaikinPhoneD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_DaikinPhoneD.avif"
  );
  DaikinPhoneD.flipY = false;
  DaikinPhoneD.colorSpace = THREE.SRGBColorSpace;
  DaikinPhoneD.channel = 1;

  const DaikinPhoneMat = new THREE.MeshBasicMaterial({
    map: DaikinPhoneD,
  });

  const handleClick = () => {
    if (props.supressClicks || hasPlayed) return;
    setIsFloating(false);
    setHasPlayed(true);

    setTimeout(() => {
      if (actions) {
        const moveAction = actions["Cube.027Action.001"];

        if (moveAction) {
          moveAction.reset();
          moveAction.setLoop(THREE.LoopOnce, 1);
          moveAction.clampWhenFinished = true;
          moveAction.play();
        }
      }
    }, 100);
  };

  return (
    <group ref={group} {...props} dispose={null} onClick={handleClick}>
      <group name="Scene">
        {isFloating ? (
          <Float
            rotationIntensity={0.2}
            speed={4}
            floatingRange={[-0.005, 0.05]}
          >
            <mesh
              name="Cube027"
              geometry={nodes.Cube027.geometry}
              material={DaikinPhoneMat}
              position={[0.904, 1.264, 2.853]}
              rotation={[1.704, -0.29, 2.399]}
            />
          </Float>
        ) : (
          <mesh
            name="Cube027"
            geometry={nodes.Cube027.geometry}
            material={DaikinPhoneMat}
            position={[0.904, 1.264, 2.853]}
            rotation={[1.704, -0.29, 2.399]}
          />
        )}
      </group>
    </group>
  );
}
export const WTFN25_SPIN5_DaikinPhone = () => {
  return <WTFN25_SPIN5_DaikinPhoneF></WTFN25_SPIN5_DaikinPhoneF>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_DaikinPhone.glb");
