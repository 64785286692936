/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_SteamMop.glb -t -r public 
*/

import { Float, useAnimations, useGLTF } from "@react-three/drei";
import React, { useRef, useState } from "react";
import * as THREE from "three";

interface GLTFResult {
  nodes: {
    Cube098: THREE.Mesh;
    Cube098_1: THREE.Mesh;
    Cube098_2: THREE.Mesh;
    Cube098_3: THREE.Mesh;
    RotatingAboveHead001: THREE.Mesh;
    Cube100: THREE.Mesh;
    Cube100_1: THREE.Mesh;
    Cube100_2: THREE.Mesh;
    Cube111: THREE.Mesh;
    Cube111_1: THREE.Mesh;
    Cube111_2: THREE.Mesh;
    Cube111_3: THREE.Mesh;
  };
  materials: {};
  animations: THREE.AnimationClip[];
}

type SteamMopProps = JSX.IntrinsicElements["group"] & {
  floating?: boolean; // optional prop to control the floating effect
  center?: boolean; // optional prop to center the model
  supressClicks?: boolean; // optional prop to supress the click event
};

export function WTFN25_SPIN5_SteamMopF({
  floating = true,
  ...props
}: SteamMopProps) {
  const group = useRef<THREE.Group>(null);

  const { nodes, animations } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_SteamMop.glb"
  ) as unknown as GLTFResult;
  const { actions } = useAnimations(animations, group);
  const [isFloating, setIsFloating] = useState(floating);
  const [hasPlayed, setHasPlayed] = useState(false);

  const handleClick = () => {
    if (props.supressClicks || hasPlayed) return;
    setIsFloating(false);
    setHasPlayed(true);

    setTimeout(() => {
      if (actions) {
        const rotatingAction = actions["RotatingAboveHeadAction.001"];
        const headAction = actions["HeadAction.001"];

        if (rotatingAction) {
          rotatingAction.reset();
          rotatingAction.setLoop(THREE.LoopOnce, 1);
          rotatingAction.clampWhenFinished = true;
          rotatingAction.play();
        }

        if (headAction) {
          headAction.reset();
          headAction.setLoop(THREE.LoopOnce, 1);
          headAction.clampWhenFinished = true;
          headAction.play();
        }
      }
    }, 100);
  };

  return (
    <group ref={group} {...props} dispose={null} onClick={handleClick}>
      <group name="Scene">
        {isFloating ? (
          <Float
            rotationIntensity={0.3}
            speed={4.5}
            floatingRange={[-0.05, 0.1]}
          >
            <ModelContent nodes={nodes} />
          </Float>
        ) : (
          <ModelContent nodes={nodes} />
        )}
      </group>
    </group>
  );
}

const ModelContent: React.FC<{ nodes: GLTFResult["nodes"] }> = ({ nodes }) => (
  <group
    name="SteamMopHead001"
    position={[1.997, 0.763, -3.084]}
    rotation={[-3.119, -0.561, -3.099]}
    scale={1.652}
  >
    <mesh
      name="Cube098"
      geometry={nodes.Cube098.geometry}
      material={new THREE.MeshStandardMaterial({ color: "white" })}
    />
    <mesh
      name="Cube098_1"
      geometry={nodes.Cube098_1.geometry}
      material={new THREE.MeshStandardMaterial({ color: "#5c989e" })}
    />
    <mesh
      name="Cube098_2"
      geometry={nodes.Cube098_2.geometry}
      material={nodes.Cube098_2.material}
    />
    <mesh
      name="Cube098_3"
      geometry={nodes.Cube098_3.geometry}
      material={nodes.Cube098_3.material}
    />
    <mesh
      name="RotatingAboveHead001"
      geometry={nodes.RotatingAboveHead001.geometry}
      material={new THREE.MeshStandardMaterial({ color: "#5c989e" })}
      rotation={[-0.232, 0, 0]}
    >
      <group name="MiddleBase001" position={[0, 0.046, 0]}>
        <mesh
          name="Cube100"
          geometry={nodes.Cube100.geometry}
          material={new THREE.MeshStandardMaterial({ color: "#5c989e" })}
        />
        <mesh
          name="Cube100_1"
          geometry={nodes.Cube100_1.geometry}
          material={new THREE.MeshStandardMaterial({ color: "white" })}
        />
        <mesh
          name="Cube100_2"
          geometry={nodes.Cube100_2.geometry}
          material={
            new THREE.MeshStandardMaterial({
              metalness: 1,
              roughness: 0.5,
            })
          }
        />
        <group name="MiddlePart001" position={[0, -0.081, 0.006]}>
          <mesh
            name="Cube111"
            geometry={nodes.Cube111.geometry}
            material={
              new THREE.MeshStandardMaterial({
                color: "#a8a8a8",
                roughness: 0.2,
              })
            }
          />
          <mesh
            name="Cube111_1"
            geometry={nodes.Cube111_1.geometry}
            material={
              new THREE.MeshStandardMaterial({
                color: "black",
                transparent: true,
                opacity: 0.65,
                roughness: 0.1,
              })
            }
          />
          <mesh
            name="Cube111_2"
            geometry={nodes.Cube111_2.geometry}
            material={nodes.Cube111_2.material}
          />
          <mesh
            name="Cube111_3"
            geometry={nodes.Cube111_3.geometry}
            material={nodes.Cube111_3.material}
          />
        </group>
      </group>
    </mesh>
  </group>
);

export const WTFN25_SPIN5_BDSteamMop = () => {
  return <WTFN25_SPIN5_SteamMopF />;
};

useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_SteamMop.glb");
