import { Scene } from "@/models/scene";

const Spin5ScenesManifest: Scene[] = [
  {
    id: "host-intro",
    sceneName: "Host Intro",
    objectPositions: [],
    videoPositions: [],
    xTargetPosition: -13,
    yTargetPosition: 1.1,
    zTargetPosition: 1.3,
    xCameraPosition: -9.5,
    yCameraPosition: 1.35,
    zCameraPosition: 0.4,
    objects: [
      {
        name: "WTFN25_SPIN5_VideoPlane",
      },
      {
        name: "WTFN25_SPIN5_GarageBefore",
      },
      {
        name: "WTFN25_SPIN5_SKBoxesGaragePersist",
      },
      // {
      //   name: "WTFN25_SPIN5_GarageBeforeStatic",
      // },

      // {
      //   name: "WTFN25_SPIN5_GarageClutterObj",
      // },
      // {
      //   name: "WTFN25_SPIN5_GarageJunkPlanes",
      // },
      // {
      //   name: "WTFN25_SPIN5_GarageSKBoxes1",
      // },
    ],
    segments: [
      {
        id: "before",
        name: "Before",
        objects: [{ name: "WTFN25_SPIN5_GarageBeforeStatic" }],
      },
    ],
    setObjects: [],
    virtualObjects: [],
    camera: {
      position: {
        x: -8,
        y: 2,
        z: -0.2,
      },
      target: {
        x: -19,
        y: 1.1,
        z: 4.1,
      },
    },
  },
  {
    id: "garage-before",
    sceneName: "Garage Before",
    objectPositions: [],

    xTargetPosition: -9,
    yTargetPosition: 1.3,
    zTargetPosition: 4.5,

    xCameraPosition: -12,
    yCameraPosition: 1.35,
    zCameraPosition: 2.5,

    objects: [],
    setObjects: [],
    virtualObjects: [],
    segments: [
      {
        id: "before",
        name: "Before",
        objects: [
          {
            name: "WTFN25_SPIN5_GarageBeforeStatic",
          },
          {
            name: "WTFN25_SPIN5_GarageBefore",
          },
          {
            name: "WTFN25_SPIN5_GarageClutterObj",
            //animate triggered by WTFN25_SPIN5_GarageSKBoxes1
          },
          {
            name: "WTFN25_SPIN5_GarageJunkPlanes",
            //animate triggered by WTFN25_SPIN5_GarageSKBoxes1
          },
          {
            name: "WTFN25_SPIN5_GarageSKBoxes1",
            highlight: true,
            experienceVirtualObjectId: "zR5PPBxMymIlpmeX3JNy",
            //animation trigger
          },
        ],
      },
      {
        id: "after",
        name: "After",
        objects: [
          {
            name: "WTFN25_SPIN5_SKBoxArchiveAfter",
          },
          {
            name: "WTFN25_SPIN5_SKBoxHeavyAfter",
          },
          {
            name: "WTFN25_SPIN5_SKBoxLargeHeavyAfter",
            highlight: true,
            experienceVirtualObjectId: "SBDUbYGxlIYhB70GPsKN",
          },
          {
            name: "WTFN25_SPIN5_SKBoxLiteAfter",
          },
          {
            name: "WTFN25_SPIN5_GarageAfter",
          },
          {
            name: "WTFN25_SPIN5_GarageAfterStatic",
          },
        ],
      },
    ],
    camera: {
      position: {
        x: -14,
        y: 1.35,
        z: 1,
      },
      target: {
        x: 19,
        y: 1.1,
        z: 19.1,
      },
    },
  },
  {
    id: "garage-workbench",
    sceneName: "Garage Workbench",
    objectPositions: [],

    xTargetPosition: -7.4,
    yTargetPosition: 1.1,
    zTargetPosition: 3.55,

    xCameraPosition: -9.5,
    yCameraPosition: 1.6,
    zCameraPosition: 3.5,

    objects: [],
    setObjects: [],
    virtualObjects: [],

    segments: [
      {
        id: "tools",
        name: "Tools",
        objects: [
          {
            name: "WTFN25_SPIN5_GarageAfter",
          },
          {
            name: "WTFN25_SPIN5_GarageAfterStatic",
          },
          {
            name: "WTFN25_SPIN5_KmartShelf",
            highlight: true,
            experienceVirtualObjectId: "7NYpEeTJJtkyaycN7bGN",
            //should bring up modal
          },
          {
            name: "WTFN25_SPIN5_KmartShelf",
          },
          {
            name: "WTFN25_SPIN5_KmartTub",
            highlight: true,
            experienceVirtualObjectId: "jH6v4MS7aChsQ1hbCHKF",
            //should bring up modal
          },
          {
            name: "WTFN25_SPIN5_SKBoxArchiveAfter",
          },
          {
            name: "WTFN25_SPIN5_SKBoxHeavyAfter",
          },
          {
            name: "WTFN25_SPIN5_SKBoxLargeHeavyAfter",
          },
          {
            name: "WTFN25_SPIN5_SKBoxLiteAfter",
          },
          {
            name: "WTFN25_SPIN5_StanleyBag",
            highlight: true,
            experienceVirtualObjectId: "4tkM5llWtRfhUCJp96Yt",
            //should bring up modal
            //animate on click
          },
          {
            name: "WTFN25_SPIN5_StanleyBagPlanes",
          },
          {
            name: "WTFN25_SPIN5_StanleyBlower",
            highlight: true,
            experienceVirtualObjectId: "xPvamVztMlIfvZ6sjcJf",
            //should bring up modal
          },
          {
            name: "WTFN25_SPIN5_StanleyLift",
            highlight: true,
            experienceVirtualObjectId: "vtmcT2ReIlxIqrJnkPpW",
            //should bring up modal
          },
          {
            name: "WTFN25_SPIN5_StanleySander",
            highlight: true,
            experienceVirtualObjectId: "AMN3x8ihkREKlEMkF023",
            //should bring up modal
          },
          {
            name: "WTFN25_SPIN5_StanleySaw",
            highlight: true,
            experienceVirtualObjectId: "SYvu9hS4fHkUPSVFgytb",
            //should bring up modal
          },
        ],
      },
    ],

    camera: {
      position: {
        x: -11,
        y: 1.6,
        z: 1,
      },
      target: {
        x: 25,
        y: -0.8,
        z: 12,
      },
    },
  },
  {
    id: "spare-room-before",
    objectPositions: [],
    sceneName: "Spare Room Before",
    // videoPositions: [
    //   {
    //     id: "36",
    //     positionName: "WTFN25_SPIN5_SpareBedroomTV",
    //     selected: null,
    //   },
    // ],
    xTargetPosition: -2.5,
    yTargetPosition: 1.1,
    zTargetPosition: 6,
    xCameraPosition: -4.4,
    yCameraPosition: 1.35,
    zCameraPosition: 3,
    objects: [
      {
        name: "WTFN25_SPIN5_SpareBedroomTV",
      },
      {
        name: "WTFN25_SPIN5_SpareDoors",
      },
      {
        name: "WTFN25_SPIN5_SpareWalls",
      },
    ],
    setObjects: [],
    segments: [
      {
        id: "before",
        name: "Before",
        /**
         * @Buzz & @Andy I've moved bedroom before and after
         * into segments, but haven't sifted through to determine
         * which of these objects are for each segment, and which
         * should be across both segments. The objects that should
         * span both segments should be moved into the objects array
         * of the scene (above). Then the trigger object here in
         * thesegment needs to be set to 'highlight: true' to trigger
         * the animations - and the animations need to be set up in
         * each modeul using the useSegment hook.
         */
        objects: [
          {
            name: "WTFN25_SPIN5_SRBedBefore",
            //animate triggered by WTFN25_SPIN5_SRSKBoxBeforeBack
          },
          {
            name: "WTFN25_SPIN5_SRPlanesBefore",
            //animate triggered by WTFN25_SPIN5_SRSKBoxBeforeBack
          },
          {
            name: "WTFN25_SPIN5_SRSKBoxBeforeBack",
            highlight: true,
            experienceVirtualObjectId: "jEvLLolcnUiwnPvyB2LO",
            //animate trigger
          },
          {
            name: "WTFN25_SPIN5_SRBeforeObj",
          },
          {
            name: "WTFN25_SPIN5_SKBoxEzi",
          },
          {
            name: "WTFN25_SPIN5_SRSKBoxBeforeFront",
            highlight: true,
            experienceVirtualObjectId: "chQnZy8LcmGOwuclhGPb",
          },
        ],
      },
      {
        id: "after",
        name: "After",
        objects: [
          {
            name: "WTFN25_SPIN5_SpareBedroomDecor",
          },

          {
            name: "WTFN25_SPIN5_BedShedOslo",
            highlight: true,
            experienceVirtualObjectId: "0k5vQD6d1YqoiIm5sMFn",
            //should bring up modal
          },
          {
            name: "WTFN25_SPIN5_BDVac3",
            highlight: true,
            experienceVirtualObjectId: "AkBcNHBSEC2mCdb5BhUf",
            //should bring up modal
          },
          {
            name: "WTFN25_SPIN5_KmartCanvas",
            highlight: true,
            experienceVirtualObjectId: "j87CGW5CIWPi4GaQ3Bl1",
            //should bring up modal
          },
          {
            name: "WTFN25_SPIN5_KmartDahliaSB",
          },
          {
            name: "WTFN25_SPIN5_KmartMirror",
            highlight: true,
            experienceVirtualObjectId: "poLrPLP9pB9vpO2M5Har",
            //should bring up modal
          },
          {
            name: "WTFN25_SPIN5_KmartShell",
            highlight: true,
            experienceVirtualObjectId: "FUU8utallBJiBy6rlofs",
            //should bring up modal
          },
          {
            name: "WTFN25_SPIN5_SKBoxEzi",
            highlight: true,
            experienceVirtualObjectId: "Qnn35lMQWucH0iMGnnKx",
            //should bring up modal
          },
          {
            name: "WTFN25_SPIN5_SRSKBoxBeforeFront",
            highlight: true,
            experienceVirtualObjectId: "chQnZy8LcmGOwuclhGPb",
          },
        ],
      },
    ],
    camera: {
      position: {
        x: -3,
        y: 1.35,
        z: 2,
      },
      target: {
        x: -190,
        y: 1.1,
        z: 520,
      },
    },
  },

  {
    id: "lounge",
    objectPositions: [],
    sceneName: "Lounge",
    xTargetPosition: -1.1,
    yTargetPosition: 1.1,
    zTargetPosition: -1,
    xCameraPosition: -5.5,
    yCameraPosition: 1.35,
    zCameraPosition: -1.5,
    objects: [],
    setObjects: [
      {
        name: "WTFN25_SPIN5_Dining",
      },
      {
        name: "WTFN25_SPIN5_FloorWood",
        //animate triggered by WTFN25_SPIN5_FESamples
      },
      {
        name: "WTFN25_SPIN5_DownLights",
      },
      {
        name: "WTFN25_SPIN5_Lounge",
      },
      {
        name: "WTFN25_SPIN5_LoungeTrim",
      },
      {
        name: "WTFN25_SPIN5_LoungeWallFeature",
      },
      {
        name: "WTFN25_SPIN5_LoungeWallsChange",
      },
      {
        name: "WTFN25_SPIN5_Paint",
      },
      {
        name: "WTFN25_SPIN5_PaintRoller",
        //animate triggered by WTFN25_SPIN5_Paints
      },
    ],
    virtualObjects: [
      {
        name: "WTFN25_SPIN5_FESampleDorian",
        highlight: true,
        experienceVirtualObjectId: "zF3a8K5WmC3zaCFosKtB",
        //should open modal on products
        //animation trigger
      },
      {
        name: "WTFN25_SPIN5_FESampleGum",
        highlight: true,
        experienceVirtualObjectId: "LaWoDck6zOeaOoMDnTDT",
        //should open modal on products
        //animation trigger
      },
      {
        name: "WTFN25_SPIN5_FESampleOak",
        highlight: true,
        experienceVirtualObjectId: "qkQ2KPcl60YXDsC6IBNe",
        //should open modal on products
        //animation trigger
      },
      {
        name: "WTFN25_SPIN5_TaubmansAria",
        highlight: true,
        experienceVirtualObjectId: "FTUtx5qO8bSyRWcy7poS",
        //should open modal on products
        //animation trigger
      },
      {
        name: "WTFN25_SPIN5_TaubmansClover",
        highlight: true,
        experienceVirtualObjectId: "qkYsMmz8XKgkW5rmTfcQ",
        //should open modal on products
        //animation trigger
      },
      {
        name: "WTFN25_SPIN5_TaubmansCrisp",
        highlight: true,
        experienceVirtualObjectId: "IcwRjg9zs7GqJ0eyUrtk",
        //should open modal on products
        //animation trigger
      },
    ],
    camera: {
      position: {
        x: -6.7,
        y: 1.35,
        z: -2.5,
      },
      target: {
        x: 6,
        y: 1.1,
        z: 9,
      },
    },
  },
  {
    id: "kitchen",
    objectPositions: [],
    sceneName: "Kitchen",
    xTargetPosition: 2.3,
    yTargetPosition: 0.7,
    zTargetPosition: 1,
    xCameraPosition: 2.4,
    yCameraPosition: 1.35,
    zCameraPosition: -4.65,
    setObjects: [
      {
        name: "WTFN25_SPIN5_KitchenCupboards",
      },
      {
        name: "WTFN25_SPIN5_KitchenIsland",
      },
      {
        name: "WTFN25_SPIN5_KitchenLights",
      },
    ],
    virtualObjects: [
      {
        name: "WTFN25_SPIN5_KitchenSpill2",
        //animate triggered by WTFN25_SPIN5_SteamMop
      },
      {
        name: "WTFN25_SPIN5_BDVac2",
        highlight: true,
        experienceVirtualObjectId: "eXMNHhlPmxjXOvcCQ978",
        //should open modal on products
      },
      {
        name: "WTFN25_SPIN5_SKBoxKitchen",
        highlight: true,
        experienceVirtualObjectId: "7xHctPd8j75zOeOOemy3",
        //should open modal on products
      },
      {
        name: "WTFN25_SPIN5_BDSteamMop",
        highlight: true,
        experienceVirtualObjectId: "INpd6FVUT7cjG8RQVJrl",
        //should open modal on products
        //animation trigger self
      },
      {
        name: "WTFN25_SPIN5_KmartCarvedBowl",
        highlight: true,
        experienceVirtualObjectId: "SBDUbYGxlIYhB70GPsKN",
        //should open modal on products
      },
    ],
    camera: {
      position: {
        x: 2.1,
        y: 1.75,
        z: -5.9,
      },
      target: {
        x: 1,
        y: 0.7,
        z: 9,
      },
    },
  },
  {
    id: "dining",
    objectPositions: [],
    sceneName: "Dining",
    xTargetPosition: -7,
    yTargetPosition: 0.3,
    zTargetPosition: -1.3,
    xCameraPosition: -1.1,
    yCameraPosition: 1.35,
    zCameraPosition: -4.4,
    objects: [
      {
        name: "WTFN25_SPIN5_SKBoxesDining",
      },
    ],
    setObjects: [],
    virtualObjects: [
      {
        name: "WTFN25_SPIN5_BDVac1",
        highlight: true,
        experienceVirtualObjectId: "8ZPImw6ml6nJJLuslBO8",
        //should open modal on products
      },
      {
        name: "WTFN25_SPIN5_DaikinPurifier",
        highlight: true,
        experienceVirtualObjectId: "JWqOyTvLlUTFix3DSNI4",
        //should open modal on products
      },
      {
        name: "WTFN25_SPIN5_KmartDahliaL",
        highlight: true,
        experienceVirtualObjectId: "MXbK8eOqhdPA1LqkTG6u",
        //should open modal on products
      },
      {
        name: "WTFN25_SPIN5_KmartMarleTassel",
        highlight: true,
        experienceVirtualObjectId: "pcXtTTRWsFlweGFSu2Zw",
        //should open modal on products
      },
      {
        name: "WTFN25_SPIN5_HamperWorld",
        highlight: true,
        experienceVirtualObjectId: "xFx4zUnq87N9eUPmvm5U",
        //should open modal on products
      },
      {
        name: "WTFN25_SPIN5_KmartLinearVase",
        highlight: true,
        experienceVirtualObjectId: "k5DJ1StqYuHWeG8QpFkt",
        //should open modal on products
      },
    ],
    camera: {
      position: {
        x: -0.3,
        y: 1.35,
        z: -4.6,
      },
      target: {
        x: -69,
        y: 0.3,
        z: 10,
      },
    },
  },
  {
    id: "master-bed",
    objectPositions: [],
    sceneName: "Master Bed",
    xTargetPosition: 1.8,
    yTargetPosition: 1.1,
    zTargetPosition: 6.1,
    xCameraPosition: 0.6,
    yCameraPosition: 1.35,
    zCameraPosition: 2.15,
    setObjects: [
      {
        name: "WTFN25_SPIN5_Carpet",
        //animate triggered by WTFN25_SPIN5_CarpetFESwatches
      },
      {
        name: "WTFN25_SPIN5_MasterDecor",
      },
      {
        name: "WTFN25_SPIN5_MasterDesk",
      },
      {
        name: "WTFN25_SPIN5_MasterDoors",
      },
      {
        name: "WTFN25_SPIN5_MasterWalls",
      },
    ],
    virtualObjects: [
      {
        name: "WTFN25_SPIN5_DaikinAlira",
        highlight: true,
        experienceVirtualObjectId: "hFGaxcY1bo94MV7WrHIW",
        //should open modal on products
        //animate triggered by WTFN25_SPIN5_DaikinPhone
      },
      {
        name: "WTFN25_SPIN5_BedShed1",
        highlight: true,
        experienceVirtualObjectId: "nouZgK30uPZJ3vyn5z0A",
        //should open modal on products
      },
      {
        name: "WTFN25_SPIN5_CarpetFECorinSwatch",
        highlight: true,
        experienceVirtualObjectId: "Gm14pQaz23pE2lPtRNV6",
        //animation trigger
      },
      {
        name: "WTFN25_SPIN5_CarpetFEOberonSwatch",
        highlight: true,
        experienceVirtualObjectId: "Gm14pQaz23pE2lPtRNV6",
        //animation trigger
      },
      {
        name: "WTFN25_SPIN5_DaikinPhone",
        //animation trigger
      },
      {
        name: "WTFN25_SPIN5_KmartBoston",
        highlight: true,
        experienceVirtualObjectId: "HSVvzkFsQ7dSQQFDmzDd",
        //should open modal on products
      },
      {
        name: "WTFN25_SPIN5_KmartMushroom",
        highlight: true,
        experienceVirtualObjectId: "4sujBwuRemvkmQJdHcuj",
        //should open modal on products
      },
      {
        name: "WTFN25_SPIN5_BDVac4",
        highlight: true,
        experienceVirtualObjectId: "rPyeZsHQhiERA9uSYKDv",
        //should open modal on products
      },
    ],

    camera: {
      position: {
        x: -0.5,
        y: 1.35,
        z: 1.5,
      },
      target: {
        x: 25,
        y: 1.1,
        z: 42,
      },
    },
  },
  {
    id: "garage-end",
    sceneName: "Garage End",
    objectPositions: [],
    videoPositions: [
      {
        id: "1",
        positionName: "Host",
        selected: null,
      },
    ],
    xTargetPosition: -16,
    yTargetPosition: 1.1,
    zTargetPosition: 4.9,
    xCameraPosition: -13.4,
    yCameraPosition: 1.35,
    zCameraPosition: 0.85,
    objects: [
      {
        name: "WTFN25_SPIN5_VanDoor",
      },
      {
        name: "WTFN25_SPIN5_SKBoxesGarageEnd",
        highlight: true,
        //animation trigger self
      },
    ],
    setObjects: [],
    virtualObjects: [],
    camera: {
      position: {
        x: -8,
        y: 1.35,
        z: -0.2,
      },
      target: {
        x: -19,
        y: 1.1,
        z: 4.1,
      },
    },
  },
];

export default Spin5ScenesManifest;
