/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_SRBedBefore.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    victorian_bed_mattres_bed_mattres_material_0: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_SRBedBeforeF(
  props: JSX.IntrinsicElements["group"]
) {
  const { nodes, materials } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_SRBedBefore.glb"
  ) as GLTFResult;

  const SRBedBeforeD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_SRBedBeforeD.avif"
  );
  SRBedBeforeD.flipY = false;
  SRBedBeforeD.colorSpace = THREE.SRGBColorSpace;
  SRBedBeforeD.channel = 1;
  const SRBedBeforeMat = new THREE.MeshBasicMaterial({
    map: SRBedBeforeD,
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.victorian_bed_mattres_bed_mattres_material_0.geometry}
        material={SRBedBeforeMat}
        position={[-2.769, 0.001, 4.263]}
        rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
        scale={0.738}
      />
    </group>
  );
}
export const WTFN25_SPIN5_SRBedBefore = () => {
  return <WTFN25_SPIN5_SRBedBeforeF></WTFN25_SPIN5_SRBedBeforeF>;
};

useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_SRBedBefore.glb");
