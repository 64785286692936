/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_HamperWorld.glb -t -r public 
*/

import * as THREE from "three";
import { useGLTF, useTexture } from "@react-three/drei";
// import { useFrame, useThree } from "@react-three/fiber";
// import { useRef } from "react";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Plane012: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_HamperWorldF(
  props: JSX.IntrinsicElements["group"]
) {
  const { nodes } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_HamperWorld.glb"
  ) as GLTFResult;

  const HamperWorldD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_HamperWorld.avif"
  );
  HamperWorldD.flipY = false;
  HamperWorldD.colorSpace = THREE.SRGBColorSpace;

  const HamperWorldMat = new THREE.MeshStandardMaterial({
    map: HamperWorldD,
    transparent: true,
  });

  // const meshRef = useRef<THREE.Mesh>(null);
  // const { camera } = useThree();

  // useFrame(() => {
  //   if (meshRef.current) {
  //     const cameraPosition = new THREE.Vector3();
  //     camera.getWorldPosition(cameraPosition);

  //     const objectPosition = new THREE.Vector3();
  //     meshRef.current.getWorldPosition(objectPosition);

  //     meshRef.current.lookAt(cameraPosition);
  //     meshRef.current.rotation.x = Math.PI / 2;
  //     meshRef.current.rotation.y = Math.PI / 9;
  //     meshRef.current.rotation.z = Math.PI / 2;
  //   }
  // });

  return (
    <group {...props} dispose={null}>
      <mesh
        // ref={meshRef}
        geometry={nodes.Plane012.geometry}
        material={HamperWorldMat}
        position={[-2.219, 0.767, -3.906]}
        rotation={[Math.PI / 2, 0, 1.422]}
        scale={[0.267, 0.334, 0.268]}
      />
    </group>
  );
}

export const WTFN25_SPIN5_HamperWorld = () => {
  return <WTFN25_SPIN5_HamperWorldF />;
};

useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_HamperWorld.glb");
