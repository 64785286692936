/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/skysphere.glb -t -r public 
*/

import { useGLTF, useTexture } from "@react-three/drei";
import React from "react";
import * as THREE from "three";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Sphere: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function SkySphereF(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF("/glb/skysphere.glb") as GLTFResult;

  const skysphered = useTexture("/textures/WTFN25/SPIN5/skysphered.avif");
  skysphered.flipY = false;
  skysphered.colorSpace = THREE.SRGBColorSpace;
  const skySphereMat = new THREE.MeshBasicMaterial({
    map: skysphered,
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Sphere.geometry}
        material={skySphereMat}
        rotation={[0, 2, 0]}
      />
    </group>
  );
}

export const WTFN25_SPIN5_Skysphere = () => {
  return <SkySphereF></SkySphereF>;
};

useGLTF.preload("/glb/skysphere.glb");
