/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WTFN25/SPIN5/WTFN25_SPIN5_KmartMirror.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    ArchOakLookFloorMirror: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function WTFN25_SPIN5_KmartMirrorF(
  props: JSX.IntrinsicElements["group"]
) {
  const { nodes, materials } = useGLTF(
    "/glb/WTFN25/SPIN5/WTFN25_SPIN5_KmartMirror.glb"
  ) as GLTFResult;

  const KmartMirrorD = useTexture(
    "/textures/WTFN25/SPIN5/WTFN25_SPIN5_KmartMirrorD.avif"
  );
  KmartMirrorD.flipY = false;
  KmartMirrorD.colorSpace = THREE.SRGBColorSpace;
  KmartMirrorD.channel = 1;
  const KmartMirrorMat = new THREE.MeshBasicMaterial({
    map: KmartMirrorD,
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.ArchOakLookFloorMirror.geometry}
        material={KmartMirrorMat}
        position={[-1.884, 0, 6.195]}
        rotation={[-2.835, -1.292, -2.846]}
      />
    </group>
  );
}

export const WTFN25_SPIN5_KmartMirror = () => {
  return <WTFN25_SPIN5_KmartMirrorF></WTFN25_SPIN5_KmartMirrorF>;
};
useGLTF.preload("/glb/WTFN25/SPIN5/WTFN25_SPIN5_KmartMirror.glb");
